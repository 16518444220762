import {
  Button,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PreviewIcon from 'assets/img/quote/QuotePreview.png';
import InputField from 'components/fields/InputField';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import { IContract, IEvent, IQoute, UrlItem } from 'services/@types';
import EventCard from 'components/eventsDialog/eventCard';
import InputDateField from 'components/fields/InputDateField';
import DropdownField from 'components/fields/DropdownField';
import { currency } from 'variables/currency';
import Dropzone from 'components/dropzone';
import { languages } from 'variables/languages';
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import { FiX } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
interface ICreateContractHeaderProps {
  event?: IEvent | null;
  handleOpenEventDialog: () => void;
  contract: IContract | null;
  selectedCurrency: { label: string; value: string; symbol: string }[];
  selectedLanguage: { label: string; value: string }[];
  handleChange: (path: string, value: any) => void;
  showedCurrency: string;
  showedLanguage: string;
  radioValue: 'assign' | 'attach';
  setRadioValue: (value: 'assign' | 'attach') => void;
  qoutes: { value: string; label: string }[];
  handleSelectedQuote: (value: any) => void;
  handleChangeCurrency: (value: string) => void;
  handleAddQuoteFile: (urlItem: UrlItem) => void;
  clearFile: (type: 'document' | 'quote') => void;
  userId: string;
}
export default function CreateContractHeader(
  props: ICreateContractHeaderProps,
) {
  const {
    event = null,
    handleOpenEventDialog,
    contract,
    selectedCurrency,
    selectedLanguage,
    handleChange,
    showedCurrency,
    showedLanguage,
    radioValue,
    setRadioValue,
    qoutes,
    handleSelectedQuote,
    handleChangeCurrency,
    handleAddQuoteFile,
    clearFile,
    userId,
  } = props;
  const params = useParams();
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap="36px">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="24px" fontWeight="600">
          {params?.contractId
            ? t('create_contract.edit_contract')
            : t('create_contract.title')}
        </Text>
        <Flex
          cursor="pointer"
          bg="#0000004D"
          borderRadius="10px"
          w="32px"
          h="32px"
          onClick={() => console.log('preview')}
          alignItems="center"
          justifyContent="center">
          <Image src={PreviewIcon} alt="preview" />
        </Flex>
      </Flex>
      <Flex wrap="wrap" gap="44.5px" alignItems="end">
        {event && (
          <EventCard
            event={event}
            isQuoteEvent
            onClick={handleOpenEventDialog}
            maxW={{ base: '100%', md: '320px' }}
            flex="1"
          />
        )}
        {!event && (
          <Button
            w={{ base: '100%', md: '320px' }}
            variant="h1cta"
            onClick={handleOpenEventDialog}>
            {t('create_contract.assign_event')}
          </Button>
        )}
        <InputDateField
          label={t(`create_contract.contract_date`)}
          value={contract?.contractDate || new Date()}
          justifyContent="end"
          containerStyles={{
            flex: '1',
            w: { base: '100%', md: '320px' },
          }}
          buttonStyles={{
            w: { base: '100%', md: '320px' },
            maxW: { base: '100%', md: '320px' },
            borderColor: '#0000001A',
            justifyContent: 'space-between',
          }}
          imgSrc={CalendarIcon}
          onChange={(e: Date) => {
            handleChange('contractDate', e);
          }}
          imgStyles={{
            bg: 'transparent',
          }}
        />
        <InputField
          label={t('create_contract.description')}
          w={{ base: '100%', md: '320px' }}
          value={contract?.description}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange('description', e.target.value);
          }}
        />
        <DropdownField
          closeOnSelect
          showedOptions={showedCurrency}
          label={t('create_contract.currency')}
          options={currency}
          placeholder={t('create_contract.currency')}
          selectedOptions={selectedCurrency}
          minW={{ base: '100%', md: '320px' }}
          onSelected={(e: any) => {
            handleChangeCurrency(e.value);
          }}
          formLabelStyles={{
            mb: '4px',
          }}
          w={{ base: '100%', md: '320px' }}
          menuButton={{
            width: {
              base: '100%',
              md: '320px',
            },
            bg: '#FFFFFF',
          }}
        />
        <DropdownField
          closeOnSelect
          showedOptions={showedLanguage}
          label={t('create_contract.language')}
          options={languages}
          minW={{ base: '100%', md: '320px' }}
          placeholder={t('create_contract.language')}
          selectedOptions={selectedLanguage}
          onSelected={(e: any) => {
            handleChange('language', e.value);
          }}
          formLabelStyles={{
            mb: '4px',
          }}
          menuButton={{
            w: {
              base: '100%',
              md: '320px',
            },
            bg: '#FFFFFF',
          }}
        />
        <Checkbox
          variant="roundBrand"
          isChecked={contract?.isVatIncluded}
          onChange={(e) => {
            handleChange('isVatIncluded', e.target.checked);
          }}
          w={{ base: '100%', md: 'auto' }}
          h="50px"
          alignItems="center">
          <Text variant="createQuoteCheckboxText">
            {t('create_contract.vat_17')}
          </Text>
        </Checkbox>
      </Flex>
      <RadioGroup
        value={radioValue}
        onChange={(e: string) => setRadioValue(e as 'assign' | 'attach')}>
        <Flex gap="44px" flexDirection={{ base: 'column', md: 'row' }}>
          <Flex flexDirection="column" gap="12px">
            <Radio
              variant="brandOutline"
              borderColor="brand.900"
              value="assign">
              <Text variant="createQuoteTitle">
                {t('create_contract.assign_to_existing_quote')}
              </Text>
            </Radio>
            <InputAutocompleteField
              label={t('create_contract.assign_to_quote')}
              placeholder={t('create_contract.search_quote')}
              minW={{ base: '100%', md: '320px' }}
              options={qoutes}
              w={{ base: '100%', md: '320px' }}
              labelProps={{
                ms: '12px',
                mb: '8px',
              }}
              disabled={radioValue !== 'assign'}
              cursor={radioValue !== 'assign' ? 'not-allowed' : 'test'}
              value={''}
              onSelected={handleSelectedQuote}
            />
          </Flex>
          <Flex flexDirection="column" gap="16px">
            <Radio
              variant="brandOutline"
              borderColor="brand.900"
              color="brand.900"
              value="attach">
              <Text variant="createQuoteTitle">
                {t('create_contract.attach_existing_quote')}
              </Text>
            </Radio>
            {!contract?.media?.find((i) => i.type === 'quote') && (
              <Dropzone
                disabled={radioValue !== 'attach'}
                uploadKey="quote"
                target="user"
                uploadLabel={t('create_contract.add_file_or_drag_and_drop')}
                labelSize="xs"
                targetId={userId}
                access="private"
                currentImage={''}
                dropzoneOptions={{
                  multiple: false,
                }}
                onUploadCompleted={handleAddQuoteFile}
                initialProgress={
                  !!contract?.media?.find((i) => i.type === 'quote') ? 100 : 0
                }
                h="74px"
                w="206px"
                minH="74px"
                minW="206px"
                transition="all 0.3s ease"
              />
            )}
            {contract?.media?.find((i) => i.type === 'quote') && (
              <Flex alignItems="center" gap="8px">
                <Text variant="contractFileText">
                  {
                    contract?.media?.find((m) => m.type === 'quote')
                      ?.displayName
                  }
                </Text>
                <IconButton
                  icon={<Icon as={FiX} color="#939393" />}
                  aria-label="delete"
                  onClick={() => {
                    clearFile('quote');
                  }}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </RadioGroup>
    </Flex>
  );
}
