import { Flex, Image, Text } from '@chakra-ui/react';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import { useTranslation } from 'react-i18next';

export default function NoQuotes() {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h="100%"
      flexDirection="column"
      gap="36px">
      <Image w="300px" h="300px" src={NoEventsImg} alt="" />
      <Flex gap="16px" flexDirection="column" textAlign="center">
        <Text variant="noEventsTitle">{t('quote_list.no_quotes_found')}</Text>
        <Text variant="noEventsSubTitle">
          {t('quote_list.no_quotes_found_description')}
        </Text>
      </Flex>
    </Flex>
  );
}
