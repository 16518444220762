import { HStack, Text, VStack } from '@chakra-ui/react';
import { Invite } from 'services/@types';
import { SeatInviteImage } from './ParticipantSearchItemImages';

const SeatInviteSearchItem = ({ invite }: { invite: Invite }) => {
  return (
    <>
      <SeatInviteImage invite={invite} />
      <VStack spacing={0} alignItems="flex-start">
        <HStack
          spacing={0}
          fontSize="16px"
          fontWeight={700}
          color="black"
          letterSpacing={'-2%'}>
          <Text variant="assingSuggestions" fontWeight={700}>
            {invite.fullName}
          </Text>
        </HStack>
        <Text fontSize="14px" color="00000099" textTransform={'capitalize'}>
          {invite.jobTitle || invite.role}
        </Text>
      </VStack>
    </>
  );
};

export default SeatInviteSearchItem;
