import {
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IContract } from 'services/@types';

type IContractProduct = IContract['products'][0];

const InputFieldNames: { label: string; value: keyof IContractProduct }[] = [
  { label: 'amount', value: 'amount' },
  { label: 'price', value: 'price' },
  { label: 'total_price', value: 'totalPrice' },
];

interface IProductProps {
  product: IContractProduct;
  index: number;
  products: { value: string; label: string }[];
  handleRemoveProduct: (index: number) => void;
  handleProductDescriptionSelected: (id: string, index: number) => void;
  handleProductChange: ({
    name,
    value,
    index,
  }: {
    name: string;
    value: number;
    index: number;
  }) => void;
  handleProductVatChange: (index: number) => void;
  currencySymbol: string;
}
export default function Product(props: IProductProps) {
  const {
    product,
    index,
    products,
    handleRemoveProduct,
    handleProductDescriptionSelected,
    handleProductChange,
    handleProductVatChange,
    currencySymbol,
  } = props;
  const { t } = useTranslation();
  return (
    <Flex wrap="wrap" gap="12px" alignItems="end">
      <InputAutocompleteField
        label={t('create_contract.description')}
        placeholder={t('create_contract.description')}
        w={{ base: '100%', md: '256px' }}
        options={products}
        value={product.description}
        onSelected={(id: string) => handleProductDescriptionSelected(id, index)}
      />
      {InputFieldNames.map(({ label, value }) => (
        <InputField
          key={value}
          type="number"
          min={1}
          label={t(`create_contract.${label}`)}
          placeholder={t(`create_contract.${label}`)}
          w={{ base: '100%', md: '201px' }}
          bg={label === 'total_price' ? '#F6F6F6' : 'white'}
          borderColor={label === 'total_price' ? '#F6F6F6' : '#E0E0E0'}
          value={product?.[value]?.toString()}
          name={value}
          onChange={(e) => {
            handleProductChange({
              name: e.target.name,
              value: parseInt(e.target.value),
              index,
            });
          }}
          iconLeft={
            (value === 'totalPrice' || value === 'price') && (
              <Text variant="currency">{currencySymbol}</Text>
            )
          }
        />
      ))}
      <Checkbox
        variant="roundBrand"
        isChecked={product?.isVatIncluded}
        onChange={() => handleProductVatChange(index)}
        h="50px"
        alignItems="center">
        <Text variant="createQuoteCheckboxText">
          {t('create_quote.vat_17')}
        </Text>
      </Checkbox>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label=""
          icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
          variant="ghost"
          h="50px"
        />
        <MenuList>
          <MenuItem onClick={() => handleRemoveProduct(index)}>
            {t('create_contract.remove_product')}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
