import { Checkbox, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { TbMailOpened, TbMapPin } from 'react-icons/tb';

import { IClient } from 'services/@types';
interface ClientCardProps {
  client: IClient;
  isSelected?: boolean;
  onSelect?: (clientId: string) => void;
  [key: string]: any;
}
export default function ClientCard(props: ClientCardProps) {
  const { client, isSelected, onSelect } = props;

  return (
    <HStack
      p={'12px'}
      overflow="hidden"
      borderRadius={'16px'}
      w="full"
      color={'#939393'}
      alignItems={'center'}
      gap={'10px'}
      spacing={0}
      fontWeight={400}
      fontSize={'12px'}
      border={'1px solid #5FBF96'}
      lineHeight="16px">
      <VStack
        spacing={0}
        alignItems={'flex-start'}
        flex={1}
        w="full"
        gap={2}
        overflow="hidden">
        <HStack
          spacing={0}
          gap={2}
          justifyContent={'space-between'}
          w="full"
          alignItems={'center'}>
          <Text
            color="#141718"
            fontSize={'14px'}
            fontWeight={700}
            lineHeight="19px">
            {client.firstName} {client.lastName}
          </Text>
          <Checkbox
            size="lg"
            borderRadius={'4px'}
            isChecked={!!isSelected}
            value={client.id}
            variant="gray"
            onChange={(e) => {
              onSelect(client.id);
            }}
          />
        </HStack>
        <HStack spacing={0} gap={3} flex={1} w="full">
          <HStack
            overflow="hidden"
            isTruncated
            spacing={0}
            gap={1}
            alignItems="center">
            <Icon as={TbMailOpened} fontSize={'16px'} />
            <Text title={client.email}>{client.email}</Text>
          </HStack>
          <HStack
            w="full"
            overflow="hidden"
            isTruncated
            spacing={0}
            gap={1}
            flex={1}
            alignItems="center">
            <Icon as={TbMapPin} fontSize={'16px'} />
            <Text title={client.address}>{client.address}</Text>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  );
}
