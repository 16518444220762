import {
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import SwitchField from 'components/fields/SwitchField';

import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

import useAddParticipant from '../hooks/useAddParticipant';
import AutoCompleteSupplierAndSeatInvite from './AutoCompleteSupplierAndSeatInvite';

export default function AddParticipantModal() {
  const { t } = useTranslation();
  const {
    isOpen,
    handleClose,
    handleCloseCompleted,
    displayingAssigned,
    assigned,
    currentAssigned,
    handleSelectAssigned,
    handleRemoveAssigned,
    isTeamMember,
    setIsTeamMember,
    disabledTeamMemberField,
    loading,
    adding,
    handleSubmit,
    setCurrentAssigned,
  } = useAddParticipant();

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      onCloseComplete={handleCloseCompleted}>
      <ModalOverlay />
      <ModalContent mx="16px" borderRadius="16px" p="24px" maxW={540}>
        <ModalBody p={0}>
          <Flex flexDirection="column" w="100%" gap="24px">
            <HStack justifyContent="space-between">
              <Text fontSize="2xl" fontWeight={'bold'}>
                {t('event.add_participants_modal.title')}
              </Text>
              <IconButton
                variant="action"
                size="sm"
                borderRadius={'xl'}
                bg="white"
                fontSize={20}
                icon={<FiX color="#939393" />}
                onClick={handleClose}
                aria-label={''}
              />
            </HStack>
            <VStack gap="36px">
              <AutoCompleteSupplierAndSeatInvite
                labelProps={{ ms: '15px', mb: '8px' }}
                options={displayingAssigned}
                value={currentAssigned}
                onSelected={handleSelectAssigned}
                selectedOptions={assigned}
                handleRemove={handleRemoveAssigned}
                label={t('event.add_participants_modal.label')}
                onChange={(e) => setCurrentAssigned(e.target.value)}
                placeholder={t('event.add_participants_modal.placeholder')}
                w="100%"
              />
              <VStack
                spacing={0}
                alignItems="flex-start"
                gap={1}
                w="100%"
                display={disabledTeamMemberField ? 'none' : 'flex'}>
                <SwitchField
                  w="100%"
                  wrapperProps={{
                    w: '100%',
                    p: '0px',
                  }}
                  labelProps={{
                    marginLeft: '12px',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                  colorScheme="yellow"
                  color="#AA915D"
                  display="flex"
                  labelColor="#AA915D"
                  reversed
                  label={t('event.add_participants_modal.team_member')}
                  isChecked={isTeamMember}
                  onChange={() => setIsTeamMember(!isTeamMember)}
                  id="isTeamMember"
                  isDisabled={disabledTeamMemberField}
                />
                <Text fontSize="12px" color="gray.500">
                  {t('event.add_participants_modal.warning')}
                </Text>
              </VStack>
              <Button
                alignSelf="end"
                variant="h1cta"
                isLoading={loading || adding}
                onClick={handleSubmit}
                p="15px 72px"
                isDisabled={assigned?.length === 0}>
                {t('event.add_participants_modal.add')}
              </Button>
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
