import {
  Button,
  Divider,
  Flex,
  IconButton,
  Switch,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Card from 'components/card/Card';
import {
  closeSplashDialog,
  setQuoteSharingDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { shareQuote, updateQuote } from 'contexts/redux/quote/quoteSlice';
import { format } from 'date-fns';
import html2canvas from 'html2canvas-pro';
import jsPDF from 'jspdf';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import QuoteService from 'services/quote.api';
import ContactDetails from './components/ContactDetails';
import Header from './components/Header';
import NoteAndTerms from './components/NoteAndTerms';
import ProductsList from './components/ProductsList';
import Summary from './components/Summary';

const blobToFile = (blob: Blob, fileName: string) => {
  return new File([blob], fileName, { type: blob.type });
};

export default function PreviewQuote() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const componentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const toast = useToast();
  const queryClient = useQueryClient();

  // Desktop view handling when generating PDFs
  const [forcedDesktopView, setForcedDesktopView] = useState<{
    state: boolean;
    executor: null | any;
  }>({
    state: false,
    executor: null,
  });

  const quoteResult = useQuery({
    queryKey: ['quote', params?.id],
    queryFn: async () => await QuoteService.getQuote(params?.id),
    enabled: !!params?.id,
  });

  const memoizedQuote = useMemo(() => quoteResult.data, [quoteResult.data]);

  const isSendedAsContract = useMemo(
    () => quoteResult.data?.isSendedAsContract,
    [quoteResult.data?.isSendedAsContract],
  );

  const memoizedIsDownloading = useMemo(
    () =>
      !!searchParams.get('share') ||
      !!searchParams.get('download') ||
      isDownloading,
    [searchParams, isDownloading],
  );

  const memoDiscount = useMemo(() => {
    if (memoizedQuote?.generalPaymentDetails?.discountType === 'fixed') {
      return memoizedQuote?.generalPaymentDetails?.discount;
    }
    return (
      memoizedQuote?.generalPaymentDetails?.total -
      memoizedQuote?.generalPaymentDetails?.totalIncludingDiscount
    );
  }, [
    memoizedQuote?.generalPaymentDetails?.discount,
    memoizedQuote?.generalPaymentDetails?.total,
    memoizedQuote?.generalPaymentDetails?.totalIncludingDiscount,
    memoizedQuote?.generalPaymentDetails?.discountType,
  ]);

  const quoteFileName = useMemo(() => {
    return `quote_${memoizedQuote?.quoteNumber}_${format(
      new Date(),
      'dd-MM-yyyy',
    )}.pdf`;
  }, [memoizedQuote?.quoteNumber]);

  const handleSharePdf = useCallback(async () => {
    const canvas = await html2canvas(componentRef.current, {
      scale: 2,
    });
    const imgData = canvas.toDataURL('image/jpeg');

    // Create a new PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);

    // Calculate image dimensions to fit the PDF page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Add the image to the PDF
    pdf.addImage(imgData, 'jpeg', 0, 0, pdfWidth, pdfHeight);

    // return blob pdf to share
    return pdf.output('blob');
  }, []);

  const handleDownloadPdf = useCallback(async () => {
    // Capture the component as an image
    const canvas = await html2canvas(componentRef.current, {
      scale: 2,
    });

    const imgData = canvas.toDataURL('image/jpeg');

    // Create a new PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);

    // Calculate image dimensions to fit the PDF page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Add the image to the PDF
    pdf.addImage(imgData, 'jpeg', 0, 0, pdfWidth, pdfHeight);

    // Save the PDF
    pdf.save(quoteFileName);

    return true;
  }, [componentRef, quoteFileName]);

  const handleUpdateQuote = useCallback(
    async (e) => {
      const response = await dispatch(
        updateQuote({
          id: params?.id,
          updates: { isSendedAsContract: e.target.checked },
        }),
      );

      if (response.error) {
        toast({
          title: t('create_quote.error'),
          status: 'error',
        });
        return;
      }

      toast({
        title: t('create_quote.quote_updated'),
        status: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['quote', params?.id],
      });
    },
    [params?.id, dispatch, queryClient, toast, t],
  );

  useEffect(() => {
    // check that the page is loaded
    if (!memoizedQuote) return;

    if (searchParams.get('download')) {
      const downloadAndRedirect = async () => {
        const isDownloaded = await handleDownloadPdf();

        if (isDownloaded) {
          setTimeout(() => {
            navigate(-1);
            dispatch(closeSplashDialog());
            toast({
              title: t('quote_preview.downloaded'),
              variant: 'main',
              position: 'top-right',
              status: 'success',
            });
          }, 1000);
        }
      };

      setForcedDesktopView({ state: true, executor: downloadAndRedirect });
    }
  }, [
    searchParams,
    handleDownloadPdf,
    navigate,
    dispatch,
    memoizedQuote,
    toast,
    t,
  ]);

  useEffect(() => {
    if (!memoizedQuote) return;
    if (searchParams.get('share')) {
      const sharePdf = async () => {
        const blob = await handleSharePdf();
        const file = blobToFile(blob, quoteFileName);

        if (file) {
          const res = await dispatch(
            shareQuote({ file, eventId: memoizedQuote?.eventId }),
          );
          if (res) {
            setTimeout(() => {
              navigate(-1);
              dispatch(closeSplashDialog());
              toast({
                title: t('quote_sharing_dialog.sent'),
                variant: 'main',
                position: 'top-right',
                status: 'success',
              });
            }, 1000);
          }
        }
      };
      sharePdf();
    }
  }, [
    searchParams,
    handleSharePdf,
    navigate,
    dispatch,
    toast,
    t,
    quoteFileName,
    memoizedQuote,
    memoizedQuote?.eventId,
  ]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (forcedDesktopView.state) {
      timeout = setTimeout(() => {
        forcedDesktopView?.executor();
        setForcedDesktopView({ state: false, executor: null });
      }, 300);
    }
    return () => timeout && clearTimeout(timeout);
  }, [forcedDesktopView]);

  return (
    <Flex
      w={'100%'}
      pt={{
        base: '190px',
        md: '80px',
        xl: '80px',
      }}
      pb={{
        base: '100px',
        md: '0',
      }}
      flexDirection="column"
      gap="8px">
      <Card
        w={forcedDesktopView.state ? '1016px' : '100%'}
        ref={componentRef}
        gap={{
          base: forcedDesktopView.state ? 5 : '24px',
          md: 5,
        }}
        p="24px"
        borderRadius={{
          base: forcedDesktopView.state ? '30px' : 0,
          md: '30px',
        }}>
        <Header />
        <Divider borderColor="#00000033" />
        <ContactDetails
          setIsDownloading={setIsDownloading}
          isDownloading={memoizedIsDownloading}
          handleDownload={() => {
            setForcedDesktopView({ state: true, executor: handleDownloadPdf });
          }}
          createdAt={memoizedQuote?.createdAt}
          quoteNumber={memoizedQuote?.quoteNumber}
          clientName={memoizedQuote?.clients
            .map(
              (client) =>
                `${client?.firstName || ''} ${client?.lastName || ''}`,
            )
            .join(' ')}
          numberOfGuests={memoizedQuote?.numberOfGuests}
          location={memoizedQuote?.location}
          totalAmount={memoizedQuote?.generalPaymentDetails?.totalPayment}
          currency={memoizedQuote?.currency}
          eventId={memoizedQuote?.eventId}
        />
        <ProductsList
          products={memoizedQuote?.products}
          currency={memoizedQuote?.currency}
        />
        <Divider borderColor="#00000033" />
        <Flex
          justify="space-between"
          gap="24px"
          flexDirection={{
            base: forcedDesktopView.state ? 'row' : 'column',
            md: 'row',
          }}>
          <NoteAndTerms
            comment={memoizedQuote?.comment}
            paymentTerms={memoizedQuote?.paymentTerms}
            legalText={memoizedQuote?.legalText}
          />
          <Summary
            currency={memoizedQuote?.currency}
            total={memoizedQuote?.generalPaymentDetails?.total}
            discount={memoDiscount}
            totalPayment={
              memoizedQuote?.generalPaymentDetails?.totalIncludingDiscount
            }
            vat={memoizedQuote?.generalPaymentDetails?.vat}
            amountToPay={memoizedQuote?.generalPaymentDetails?.totalPayment}
            paymentInInstalments={memoizedQuote?.paymentInInstalments}
          />
        </Flex>
        {!memoizedIsDownloading && (
          <Flex
            justify={{
              base: forcedDesktopView.state ? 'flex-end' : 'center',
              md: 'flex-end',
            }}>
            <Flex flexDirection="column" gap="12px">
              <Flex alignItems="center" gap="8px" justifyContent="end">
                <Switch
                  dir={i18n.dir()}
                  variant="brand"
                  isChecked={isSendedAsContract}
                  onChange={handleUpdateQuote}
                />
                <Text variant="eventSubTitle" fontSize="14px">
                  {t('quote_preview.send_quote_as_contract')}
                </Text>
                <Tooltip
                  hasArrow
                  variant="business"
                  arrowShadowColor="#00000010"
                  label={t('quote_preview.tooltip_label')}
                  aria-label="Add or remove members to your account. manage account permissions">
                  <IconButton
                    aria-label="info"
                    bg="transparent"
                    icon={<MdInfoOutline />}
                    color="#7BA395"
                  />
                </Tooltip>
              </Flex>

              <Button
                w={{
                  base: forcedDesktopView.state ? '280px' : '171px',
                  md: '280px',
                }}
                variant="h1cta"
                onClick={() =>
                  dispatch(
                    setQuoteSharingDialog({
                      item: {
                        quoteId: params?.id,
                      },
                    }),
                  )
                }>
                {t('quote_preview.send_to')}
              </Button>
            </Flex>
          </Flex>
        )}
      </Card>
    </Flex>
  );
}
