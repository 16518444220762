import { Flex, Image, Text } from '@chakra-ui/react';
import NoSearchResultImg from 'assets/img/noSuppliers/noSuppliers.png';
import { useTranslation } from 'react-i18next';

export default function NoSearchResult({ search }: { search: string }) {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="24px"
      h="100%"
      w="100%">
      <Image w="240px" src={NoSearchResultImg} alt="no-search-result" />
      <Text variant="noClientsDescription" fontSize="16px" textAlign="center">
        {t('clients.no_search_result')}{' '}
        <Text as="span" color="#000000" fontWeight="700">
          {`"${search}"`}
        </Text>
      </Text>
      <Text variant="noClientsDescription" maxW="321px" textAlign="center">
        {t('clients.no_search_result_description')}
      </Text>
    </Flex>
  );
}
