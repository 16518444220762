import { Flex } from '@chakra-ui/react';
import useGetEvent from 'queries/event';
import { useParams } from 'react-router-dom';
import EventPageBottomCards from './components/EventPageBottomCards';
import { EventPageTopNavigationBar } from './components/EventPageTopNavigationBar';
export const EventPage: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetEvent(id);
  console.log(`event data ->`, data);
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      minHeight="100%"
      height="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}>
      <Flex direction="column" minHeight="100%" w="100%" gap={5} h="100%">
        <Flex
          gap={5}
          wrap={{ base: 'wrap', md: 'nowrap' }}
          w="100%"
          h="fit-content">
          <EventPageTopNavigationBar event={data} />
        </Flex>
        <EventPageBottomCards />
      </Flex>
    </Flex>
  );
};
