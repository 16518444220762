import { Box, Collapse, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { setAddTrackerNoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { updateTrackedEvent } from 'contexts/redux/trackedEvent/trackedEventSlice';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { INote, ITrackedEvent } from 'services/@types';
import UserService from 'services/user.api';
import TrackItemNote from './TrackItemNote';
import EventService from 'services/event.api';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { useNavigate } from 'react-router-dom';
export default function TrackItem(props: {
  trackedEvent: ITrackedEvent;
  isLast?: boolean;
  isNoteVisible?: boolean;
  showEventName?: boolean;
}) {
  const { trackedEvent, isLast = false, isNoteVisible, showEventName } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const myBusiness = useMyBusiness();
  const { i18n, t } = useTranslation();
  const queryClient = useQueryClient();
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);

  const handleCreateTrackerNote = useCallback(() => {
    dispatch(
      setAddTrackerNoteDialog({ item: { trackedEventId: trackedEvent.id } }),
    );
  }, [dispatch, trackedEvent]);

  const handleDeleteNote = useCallback(
    async (id: string) => {
      const newNotesArr = trackedEvent.notes?.filter((note) => {
        const comparison = note._id !== id;
        return comparison;
      });
      const res = await dispatch(
        updateTrackedEvent({
          id: trackedEvent.id,
          notes: newNotesArr,
        } as ITrackedEvent),
      );
      if (res) {
        queryClient.invalidateQueries({
          queryKey: ['dashboardTrackedEvents', myBusiness.id],
        });
      }
    },
    [
      trackedEvent.id,
      dispatch,
      trackedEvent.notes,
      myBusiness?.id,
      queryClient,
    ],
  );

  const handleEditNote = useCallback(
    (note: INote) => {
      dispatch(
        setAddTrackerNoteDialog({
          item: { ...note, trackedEventId: trackedEvent.id },
        }),
      );
    },
    [dispatch, trackedEvent.id],
  );

  const handleNavigateToEvent = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      navigate(`/main/event/${trackedEvent?.eventId}`);
    },
    [navigate, trackedEvent?.eventId],
  );

  const suggestedUsers = useQuery({
    queryKey: ['suggestedUsers'],
    queryFn: () => UserService.getUsers({}),
  });

  const eventResult = useQuery({
    queryKey: [`getEvent-${trackedEvent?.eventId}`],
    queryFn: () => EventService.getEvent(trackedEvent?.eventId),
    enabled: !!trackedEvent?.eventId,
  });

  const eventName = useMemo(
    () => eventResult?.data?.name,
    [eventResult?.data?.name],
  );

  return (
    <Flex
      justifyContent="space-between"
      onMouseEnter={() => setIsBtnVisible(true)}
      onMouseLeave={() => setIsBtnVisible(false)}>
      <Flex gap="17px">
        <Flex flexDirection="column" gap="5px">
          <Box
            w="15px"
            minH="15px"
            border="2px solid #AA915D"
            bg="#AA915D"
            borderRadius="50%"
          />
          {!isLast && (
            <Box
              w="calc(16px / 2)"
              h="100%"
              minH="50px"
              borderRight={i18n.dir() === 'ltr' ? '2px dashed #AA915D' : 'none'}
              borderLeft={i18n.dir() === 'rtl' ? '2px dashed #AA915D' : 'none'}
            />
          )}
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Flex flexDirection="column">
            <Flex w="100%" gap="5px">
              <Text
                display="inline"
                variant="cardSectionInfo"
                lineHeight="14px"
                ps="0px">
                {t(trackedEvent.title, trackedEvent?.variables)}
              </Text>
              {showEventName && eventName && (
                <Text
                  display="inline"
                  cursor="pointer"
                  onClick={handleNavigateToEvent}
                  variant="trackerEventName">{`"${eventName}"`}</Text>
              )}
            </Flex>
            <Text variant="taskCardSubTitle">
              {format(new Date(trackedEvent.createdAt), 'dd MMM yyyy')}
            </Text>
          </Flex>
          <Collapse in={isNoteVisible} animateOpacity>
            <Flex flexDirection="column" gap="16px">
              {trackedEvent.notes?.map((note, index) => (
                <TrackItemNote
                  note={note}
                  key={index}
                  onDeleteNote={() => handleDeleteNote(note._id)}
                  onEditNote={() => handleEditNote(note)}
                  suggestedUsers={suggestedUsers.data?.results || []}
                />
              ))}
            </Flex>
          </Collapse>
        </Flex>
      </Flex>

      <IconButton
        aria-label="Add"
        variant="actionIconBlueSmall"
        icon={<Icon as={FaPlus} color="warmWhite.100" />}
        onClick={handleCreateTrackerNote}
        display="flex"
        justifySelf="flex-end"
        transition="all 0.4s linear"
        visibility={{
          base: 'visible',
          md: isBtnVisible ? 'visible' : 'hidden',
        }}
      />
    </Flex>
  );
}
