import { Flex, Image, Text } from '@chakra-ui/react';
import NoSelectedClient from 'assets/svg/client/noSelectedClient.svg';

import { useTranslation } from 'react-i18next';

export default function NoSelectedClientDetails() {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" alignItems="center" gap="24px">
      <Image w="240px" src={NoSelectedClient} alt="no-clients" />
      <Text variant="noClientsDescription" maxW="321px" textAlign="center">
        {t('clients.no_clients_description')}
      </Text>
    </Flex>
  );
}
