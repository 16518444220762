import { useQuery } from '@tanstack/react-query';
import AuthImage from 'components/authImage/AuthImage';
import { fetchUser } from 'contexts/redux/user/userSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Business, BusinessCategory, Invite, User } from 'services/@types';

const BusinessImage = ({
  business,
  ...rest
}: {
  business:
    | Business
    | (Omit<Business, 'category'> & {
        category: Pick<BusinessCategory, 'id' | 'name' | 'description'>[];
      });
  [x: string]: any;
}) => {
  const { w, h, mr } = rest;
  const path = useMemo(() => {
    return business?.media?.find((m) => m.type === 'profileImage')?.url;
  }, [business?.media]);

  return !path ? null : (
    <AuthImage
      w={w || '60px'}
      h={h || '60px'}
      borderRadius="50%"
      path={path}
      isAvatar
      name={business?.businessName}
      bg="gray.200"
      mr={mr || '26px'}
    />
  );
};

const SeatInviteImage = ({ invite }: { invite: Invite }) => {
  const dispatch = useDispatch<any>();

  const { data: user } = useQuery<User | null>({
    queryKey: ['user', invite?.recipientID],
    queryFn: async () =>
      (await dispatch(fetchUser(invite?.recipientID))).payload,
    enabled: !!invite?.recipientID,
  });

  const path = useMemo(() => {
    return user?.media?.find((m) => m.type === 'avatar')?.url;
  }, [user?.media]);

  return (
    <AuthImage
      w="60px"
      h="60px"
      borderRadius="50%"
      path={path}
      isAvatar
      name={invite.fullName}
      bg="gray.200"
      mr="26px"
    />
  );
};

export { BusinessImage, SeatInviteImage };
