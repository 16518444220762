import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import Dropzone from 'components/dropzone';
import InputField from 'components/fields/InputField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { closeAddEventContractDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createMediaItem } from 'contexts/redux/mediaItem/mediaItemSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { MediaItem, UrlItem } from 'services/@types';
import UploadService from 'services/upload.api';

export default function AddEventContractDialog() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const client = useQueryClient();
  const [file, setFile] = useState<UrlItem | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [businessType, setBusinessType] = useState('');
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addEventContractDialog,
  );
  const myBusiness = useMyBusiness();

  const onClose = useCallback(() => {
    dispatch(closeAddEventContractDialog());
    setFile(null);
    setIsUploading(false);
    setIsSigned(false);
    setBusinessType('');
  }, [dispatch]);

  const handleBusinessTypeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setBusinessType(e.target.value);
    },
    [],
  );

  const handleSaveClicked = useCallback(async () => {
    const mediaItem: MediaItem = {
      name: businessType,
      albumID: dialog?.item?.contractsId as string,
      businessID: myBusiness?.id,
      media: [
        { url: file?.url, type: file?.type, displayName: file?.displayName },
      ],
      type: ['image', 'video', 'document', 'audio'].includes(file?.type)
        ? (file?.type as MediaItem['type'])
        : 'other',
      tags: [],
      privacy: 'private',
      signed: isSigned,
    };
    const response = await dispatch(createMediaItem(mediaItem));
    if (response) {
      client.invalidateQueries({
        queryKey: ['contracts-media', dialog?.item?.contractsId],
      });
      toast({
        title: t('event.contracts.contract_upload_success'),
        variant: 'main',
        position: 'top-right',
      });
    }

    onClose();
  }, [
    onClose,
    file,
    dialog?.item?.contractsId,
    myBusiness?.id,
    client,
    dispatch,
    toast,
    t,
    isSigned,
    businessType,
  ]);

  const handleUploadCompleted = useCallback((url: UrlItem) => {
    console.log('upload completed', url);
    setIsUploading(false);
    setFile(url);
  }, []);

  const handleRemoveFile = useCallback(async () => {
    if (!file || !dialog?.item?.contractsId) return;
    const [target, targetId, access, fileId] = file.url.split('/');
    await UploadService.deleteUpload({
      target,
      targetId,
      access,
      fileId,
    });
    setFile(null);
  }, [file, dialog?.item?.contractsId]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent gap="24px" p="18px 15px" borderRadius="16px" w="526px">
        <Flex justifyContent="space-between" gap={2} w="100%">
          <Text variant="eventPageCardTitle">
            {t('event.contracts.upload_contract')}
          </Text>
          <Icon
            as={FiX}
            cursor="pointer"
            onClick={onClose}
            color="#939393"
            transition="all 0.2s ease-in-out"
            _hover={{
              transform: 'scale(1.1)',
            }}
          />
        </Flex>

        <InputField
          label={t('event.contracts.business_type')}
          placeholder={t('event.contracts.business_type_placeholder')}
          value={businessType}
          onChange={handleBusinessTypeChange}
        />

        <Flex flexDir="column" gap={2}>
          <Flex h="70px" gap={2} w="100%" mb={0}>
            {!file && (
              <Dropzone
                labelSize="14px"
                maxH="68px"
                goldenUploadLabel={t('event.contracts.upload_file')}
                flex={1}
                cursor="pointer"
                onUploadCompleted={handleUploadCompleted}
                dropzoneOptions={{
                  accept: [
                    'image/*',
                    'video/*',
                    'audio/*',
                    'application/pdf',
                    'text/plain',
                  ],
                  multiple: false,
                }}
                onStartUpload={() => setIsUploading(true)}
                uploadBarStyle="line"
                uploadKey="attachment"
                access="restricted"
                target="album"
                targetId={dialog?.item?.contractsId || ''}
              />
            )}
            {file && (
              <Flex alignItems="center" gap={2}>
                <Text variant="lineUpFormDialogFileName">
                  {file.displayName}
                </Text>
                <Icon
                  as={FiX}
                  cursor="pointer"
                  onClick={handleRemoveFile}
                  color="#939393"
                  transition="all 0.2s ease-in-out"
                  _hover={{
                    transform: 'scale(1.1)',
                  }}
                />
              </Flex>
            )}
          </Flex>
          {!file && (
            <Text variant="noCompletedTasksSubTitle">
              {t('event.contracts.upload_file_description')}
            </Text>
          )}
        </Flex>

        <Flex alignItems="center" gap={2}>
          <Switch
            isChecked={isSigned}
            onChange={() => setIsSigned(!isSigned)}
            variant="brand"
            dir={i18n.dir()}
          />
          <Text variant="addEventClientDocumentSignedDocument">
            {t('event.contracts.mark_as_signed')}
          </Text>
        </Flex>

        <Flex justify="flex-end">
          <Button
            w="50%"
            variant="h1cta"
            isLoading={isUploading}
            disabled={!file}
            onClick={handleSaveClicked}>
            {t('event.contracts.upload')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
