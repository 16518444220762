// Chakra Imports
import {
  Flex,
  Hide,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
// Custom Components
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
// Assets
import MessengerIcon from 'assets/svg/navbar/messenger-icon.svg';
import AuthImage from 'components/authImage/AuthImage';
import { RootState } from 'contexts/redux/store';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { useAllUnreadMessagesCount } from 'views/main/managment/messenger/hooks/useUnreadMessagesCount';
import WeatherIcon from '../../assets/svg/navbar/weather-icon.svg';

import { syncLocalUnreadNotifications } from 'contexts/redux/notifications/notificationsSlice';
import useGetUnreadNotificationsCount from 'queries/notifications';
import useChangeLanguage from './hooks/useChangeLanguage';
import { useSignout } from './hooks/useSignout';
import Messages from './Messages';
import NotificationsMenu from './NotificationsMenu';

export default function HeaderLinks(props: {
  secondary: boolean;
  [x: string]: any;
}) {
  const { secondary } = props;
  const dispatch = useDispatch();
  const { notifications, unreadNotifications } = useSelector(
    (state: RootState) => ({
      notifications: state.notifications.notifications,
      unreadNotifications: state.notifications.unreadNotifications,
    }),
  );

  const { user, newMessageReceived, notificationReceived } = useSelector(
    (state: RootState) => ({
      user: state.auth.user,
      newMessageReceived: state.messenger.newMessageReceived,
      notificationReceived: state.notifications.notificationReceived,
    }),
  );
  const unreadNotificationsCount = useGetUnreadNotificationsCount(
    notificationReceived?.id,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const {
    isOpen: isOpenNotificationMenu,
    onClose: onCloseNotificationMenu,
    onToggle: onToggleNotificationMenu,
  } = useDisclosure();
  // Chakra Color Mode
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const { signOut, loading } = useSignout();
  const { handleChangeLanguage, selectedLanguage } = useChangeLanguage();

  const HeFontWeight = useMemo(
    () => (selectedLanguage === 'he' ? 'bold' : null),
    [selectedLanguage],
  );
  const EnFontWeight = useMemo(
    () => (selectedLanguage === 'en' ? 'bold' : null),
    [selectedLanguage],
  );

  const unreadMessagesCount = useAllUnreadMessagesCount(newMessageReceived?.id);

  const onHandleOpenNotificationMenu = useCallback(() => {
    dispatch(syncLocalUnreadNotifications());
  }, [dispatch]);

  const onHandleCloseNotificationMenu = useCallback(() => {
    unreadNotificationsCount.refetch();
  }, [unreadNotificationsCount]);

  const handleMessengerToggle = useCallback(() => {
    if (isOpenNotificationMenu) {
      onCloseNotificationMenu();
    }
    onToggle();
  }, [isOpenNotificationMenu, onCloseNotificationMenu, onToggle]);

  const handleNotificationToggle = useCallback(() => {
    if (isOpen) {
      onClose();
    }
    onToggleNotificationMenu();
    onHandleOpenNotificationMenu();
  }, [isOpen, onClose, onToggleNotificationMenu, onHandleOpenNotificationMenu]);

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
      gap={{ base: '15px' }}
      p={{ base: '21px', md: '12px' }}
      zIndex="5"
      borderRadius={{ base: '30px', md: '999px' }}
      boxShadow={shadow}>
      <Show above="md">
        <SidebarResponsive routes={routes} />
        <SearchBar
          placeholder={t('header_links.search')}
          mb={secondary ? { base: '10px', md: 'unset' } : 'unset'}
          me="10px"
          borderRadius="20px"
        />
      </Show>

      <Text fontSize="12px" fontWeight="400" color="primary.100">
        34ºC
      </Text>
      <Image src={WeatherIcon} alt="" w="24px" h="24px" />

      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          position="relative"
          minW="24px"
          display={!location.pathname.includes('messenger') ? 'flex' : 'none'}
          justifyContent="center"
          cursor={'pointer'}
          onClick={handleMessengerToggle}>
          <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            display={
              unreadMessagesCount.data?.unreadMessagesCount > 0
                ? 'flex'
                : 'none'
            }
            w="12px"
            h="12px"
            top="0"
            right="0"
            borderRadius="50%"
            bg="#AA915D">
            <Text variant="missedMessageCounter">
              {unreadMessagesCount.data?.unreadMessagesCount}
            </Text>
          </Flex>
          <Image src={MessengerIcon} alt="" w="24px" h="24px" />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          w={{ base: 'calc(100vw - 4px)', md: '424px' }}
          maxH="85vh"
          me={{ base: '30px', md: 'unset' }}>
          <Messages onClose={onClose} />
        </MenuList>
      </Menu>
      <NotificationsMenu
        isOpen={isOpenNotificationMenu}
        onClose={onCloseNotificationMenu}
        onToggle={handleNotificationToggle}
      />

      <Hide above="md">
        <SidebarResponsive routes={routes} />
      </Hide>

      <Menu>
        <MenuButton p="0px" ml="auto">
          <AuthImage
            _hover={{ cursor: 'pointer' }}
            color={textColor}
            name={user?.firstName}
            borderRadius="50%"
            overflow="hidden"
            size="sm"
            w="40px"
            h="40px"
            path={user?.media?.find((m) => m.type === 'avatar')?.url}
            id={user?.id}
            isAvatar
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none">
          {user?.firstName && (
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
                maxW="90%"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis">
                {`${t('header_links.hi')} ${user?.firstName}`} {user?.lastName}
              </Text>
            </Flex>
          )}
          <Flex flexDirection="column" p="10px" position={'relative'}>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => navigate('/main/settings/business')}>
              <Text fontSize="sm">{t('header_links.account_information')}</Text>
            </MenuItem>{' '}
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => navigate('/main/settings/profile')}>
              <Text fontSize="sm">{t('header_links.personal_settings')}</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => handleChangeLanguage()}>
              <Text fontSize="sm" fontWeight={HeFontWeight}>
                He
              </Text>
              <Text fontSize="sm">/</Text>
              <Text fontSize="sm" fontWeight={EnFontWeight}>
                En
              </Text>
            </MenuItem>
            <MenuItem
              onClick={signOut}
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px">
              <Text fontSize="sm" display={loading ? 'none' : 'block'}>
                {t('header_links.log_out')}
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
      <Hide above="md">
        <SearchBar
          placeholder={t('header_links.search')}
          mb={secondary ? { base: '10px', md: 'unset' } : 'unset'}
          me="10px"
          w="100%"
          flex="1"
          inputProps={{
            w: '100%',
            flex: '1',
          }}
          borderRadius="20px"
        />
      </Hide>
    </Flex>
  );
}
