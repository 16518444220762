import { useCallback, useEffect, useMemo } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Product } from 'services/@types';
import ProductService from 'services/product.api';
import {
  EXCHANGE_RATE_FROM_ILS_TO_USD,
  VAT_PERCENTAGE,
} from 'variables/currency';
import { formatNumber } from 'helpers/formatNumber';

interface UseProductItemProps {
  product: any;
  setNewQuote: (newQuote: any) => void;
  index: number;
  currency: string;
}
export default function useProductItem(props: UseProductItemProps) {
  const { product, setNewQuote, index, currency } = props;
  const [showOptions, setShowOptions] = useState(true);
  const [isPackageInfoVisible, setIsPackageInfoVisible] = useState(false);
  const [currentEditableProduct, setCurrentEditableProduct] =
    useState<Partial<Product>>(null);
  const {
    isOpen: isAddProductOpen,
    onOpen: onOpenAddProduct,
    onClose: onAddProductClosed,
  } = useDisclosure();

  const { data: productData } = useQuery({
    queryKey: ['product', product.productId],
    queryFn: () => ProductService.getProduct(product.productId),
    enabled: !!product.productId,
  });

  const isPackage = useMemo(() => {
    return (
      Array.isArray(productData?.subItems) && productData?.subItems?.length > 0
    );
  }, [productData?.subItems]);

  const onOptionsRendered = useCallback((options) => {
    const showOptions =
      options?.length === 0 && product?.description?.length > 0;
    setShowOptions(!showOptions);
  }, []);

  const handleAddProductClosed = useCallback(() => {
    onAddProductClosed();
    setCurrentEditableProduct(null);
  }, [onAddProductClosed]);

  const handleChangeDescription = useCallback(
    (e: any) => {
      setNewQuote((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any, key: number) =>
          key === index ? { ...p, description: e.target.value } : p,
        ),
      }));
    },
    [index],
  );

  const handlePackageInfoVisibility = useCallback(() => {
    setIsPackageInfoVisible((prev) => !prev);
  }, [isPackageInfoVisible]);

  const handleChangeProductQuantity = useCallback((e: any, index: number) => {
    setNewQuote((prev: any) => ({
      ...prev,
      products: prev.products.map((p: any, key: number) =>
        key === index
          ? {
              ...p,
              quantity: e.target.value,
              net: formatNumber(p.amount * e.target.value),
              vat: p.isVatIncluded ? formatNumber(p.net * VAT_PERCENTAGE) : 0,
              gross: p.isVatIncluded
                ? formatNumber(
                    formatNumber(p.amount * e.target.value) -
                      formatNumber(p.amount * e.target.value) * VAT_PERCENTAGE,
                  )
                : formatNumber(p.amount * e.target.value),
            }
          : p,
      ),
    }));
  }, []);

  const handleChangeProductAmount = useCallback((e: any, index: number) => {
    setNewQuote((prev: any) => ({
      ...prev,
      products: prev.products.map((p: any, key: number) =>
        key === index ? { ...p, amount: e.target.value } : p,
      ),
    }));
  }, []);

  // handle currency change
  useEffect(() => {
    if (currency === 'ILS') {
      setNewQuote((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any) =>
          p?.productId === productData?.id
            ? {
                ...p,
                amount: formatNumber(productData?.price),
                net: formatNumber(productData?.price * p.amount),
              }
            : { ...p },
        ),
      }));
    }
    if (currency === 'USD') {
      setNewQuote((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any) =>
          p?.productId === productData?.id
            ? {
                ...p,
                amount: formatNumber(
                  productData?.price * EXCHANGE_RATE_FROM_ILS_TO_USD,
                ),
                net: formatNumber(
                  productData?.price * EXCHANGE_RATE_FROM_ILS_TO_USD * p.amount,
                ),
              }
            : { ...p },
        ),
      }));
    }
  }, [currency, productData?.price, productData?.id]);
  return {
    showOptions,
    isPackage,
    handleChangeDescription,
    onOptionsRendered,
    isAddProductOpen,
    onOpenAddProduct,
    currentEditableProduct,
    handleAddProductClosed,
    isPackageInfoVisible,
    handlePackageInfoVisibility,
    productData,
    handleChangeProductQuantity,
    handleChangeProductAmount,
  };
}
