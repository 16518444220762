// Chakra imports
import {
  CircularProgress,
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Custom components

export interface InputFieldProps extends InputProps {
  id?: string;
  isDisabled?: boolean;
  label?: string;
  extra?: JSX.Element | string;
  errorLabel?: string;
  placeholder?: string;
  type?: string;
  onSelected?: (value: string) => void;
  onOptionsRendered?: (options: { value: string; label: string }[]) => void;
  selectedOptions?: { value: string; label: string }[];
  options?: { value: string; label: string }[];
  iconPointerEvents?: 'none' | 'auto' | 'all' | 'inherit' | 'initial' | 'unset';
  isIconWidthAuto?: boolean;
  [x: string]: any;
}

export default function Default(props: InputFieldProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    id,
    label,
    errorLabel,
    maxW,
    extra,
    value,
    placeholder,
    iconLeft,
    iconRight,
    _placeholder,
    options,
    onSelected,
    selectedOptions,
    onOptionsRendered,
    isLoading,
    type,
    w,
    mb,
    labelProps,
    iconPointerEvents,
    isIconWidthAuto = false,
    ...rest
  } = props;
  // Chakra Color Mode

  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const bgInput = useColorModeValue('white', 'brand.300');
  const { t, i18n } = useTranslation();
  const [val, setVal] = useState((value as string) || '');
  const [showOptions, setShowOptions] = useState(false);
  const _iconLeft = i18n.dir() === 'ltr' ? iconLeft : iconRight;
  const _iconRight = i18n.dir() === 'ltr' ? iconRight : iconLeft;
  const [displayedOptions, setDisplayedOptions] = useState(options);

  const [iconLeftWidth, setIconLeftWidth] = useState(0);
  const [iconRightWidth, setIconRightWidth] = useState(0);

  const memoLeftP = useMemo(() => {
    if (isIconWidthAuto && iconLeftWidth) {
      return _iconLeft ? `${iconLeftWidth}px` : '20px';
    }
    return _iconLeft ? '34px' : '20px';
  }, [iconLeftWidth, _iconLeft, isIconWidthAuto]);

  const memoRightP = useMemo(() => {
    if (isIconWidthAuto && iconRightWidth) {
      return _iconRight ? `${iconRightWidth}px` : '20px';
    }
    return _iconRight ? '34px' : '20px';
  }, [iconRightWidth, _iconRight, isIconWidthAuto]);

  const handleOptionClicked = useCallback(
    (value: { value: string; label: string }) => {
      console.log('value...', value);
      setVal(value.label);
      onSelected && onSelected(value.value);
      setShowOptions(false);
    },
    [onSelected],
  );

  useEffect(() => {
    console.log('options...', options);
    if (!showOptions) {
      onClose();
      return;
    }
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(val.toLowerCase()),
    );

    if (filteredOptions.length > 0 && val.length > 0) {
      onOpen();
    } else {
      onClose();
    }
    setDisplayedOptions(filteredOptions);
    onOptionsRendered && onOptionsRendered(filteredOptions);
  }, [
    onClose,
    onOpen,
    onOptionsRendered,
    options,
    selectedOptions,
    val,
    showOptions,
  ]);

  useEffect(() => {
    console.log('setVal...', value);
    setVal(value as string);
  }, [value]);

  return (
    <Flex direction="column" position={'relative'} mb={mb} w={w} maxW={maxW}>
      <HStack align="center">
        {isLoading && (
          <CircularProgress isIndeterminate color="brand.900" size="14px" />
        )}
        <FormLabel
          display={label || extra ? 'flex' : 'none'}
          htmlFor={id}
          mb={1}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="medium"
          _hover={{ cursor: 'pointer' }}
          {...labelProps}>
          {label}
          <Text fontSize="sm" fontWeight="400" ms="2px">
            {extra}
          </Text>
        </FormLabel>
      </HStack>
      <InputGroup>
        {_iconLeft && (
          <InputLeftElement
            h={'48px'}
            pointerEvents={iconPointerEvents ? iconPointerEvents : 'none'}
            ref={(el) => {
              setIconLeftWidth(el?.offsetWidth || 0);
            }}
            width="auto"
            color="gray.300">
            {_iconLeft}
          </InputLeftElement>
        )}
        <Input
          type={type}
          id={id}
          value={val}
          bg={props.disabled ? 'gray.150' : bgInput}
          //   in order to make it work for both LTR and RTL languages you should add the following line:
          color={textColorPrimary}
          borderColor={rest?.borderColor}
          fontWeight="500"
          pl={memoLeftP}
          pr={memoRightP}
          variant="main"
          onFocus={() => {
            setShowOptions(true);
            console.log('onFocus...');
          }}
          onBlur={() => {
            setTimeout(() => {
              console.log('onBlur...');
              setShowOptions(false);
            }, 50); // Small delay to ensure onClick fires
          }}
          placeholder={placeholder}
          fontSize="md"
          _active={{
            borderColor: rest?.borderColor || 'brand.600',
          }}
          _focus={{
            borderColor: rest?.borderColor || 'brand.600',
          }}
          autoComplete="off"
          _placeholder={{
            fontWeight: '400',
            color: 'secondaryGray.600',
            ..._placeholder,
          }}
          h="50px"
          maxH="50px"
          {...rest}
          onChange={(e) => {
            setVal(e.target.value);
            rest.onChange && rest.onChange(e);
          }}
        />
        {_iconRight && (
          <InputRightElement
            pointerEvents={iconPointerEvents ? iconPointerEvents : 'none'}
            color="gray.300"
            ref={(el) => {
              setIconRightWidth(el?.offsetWidth || 0);
            }}
            width="auto"
            h="48px">
            {_iconRight}
          </InputRightElement>
        )}
      </InputGroup>
      <Flex
        position="absolute"
        flexDirection="column"
        borderWidth={isOpen ? 1 : 0}
        borderColor="brand.100"
        borderRadius={'2xl'}
        bg="white"
        top={76}
        transition="all 0.2s"
        height={isOpen ? `${Math.min(displayedOptions.length * 54, 160)}px` : 0}
        zIndex={10}
        sx={{ scrollbarWidth: 'none' }}
        overflow="scroll"
        maxH={160}
        w="100%">
        {displayedOptions?.map((option) => (
          <HStack
            cursor="pointer"
            p={3}
            autoFocus={false}
            pointerEvents="auto"
            width={'100%'}
            key={option.value}
            _hover={{ bg: 'gray.200' }}
            onClick={() => handleOptionClicked(option)}>
            <Text fontSize="lg" fontWeight="400">
              {option.label}
            </Text>
          </HStack>
        ))}
      </Flex>
      <Text fontSize="xs" fontWeight={'semibold'} color="red.500" mt="2px">
        {t(errorLabel)}
      </Text>
    </Flex>
  );
}
