import { AxiosInstance, AxiosResponse } from 'axios';
import {
  BriefNote,
  CreateBriefNoteDto,
  UpdateBriefNoteDto,
  GetBriefNotesParams,
  PaginatedResults,
} from './@types';
import { createAxiosInstance } from './axios.util';

class BriefNoteService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createBriefNote(
    briefNoteRequest: CreateBriefNoteDto,
  ): Promise<BriefNote> {
    const response: AxiosResponse<BriefNote> = await this.api.post(
      '/brief-notes',
      briefNoteRequest,
    );
    return response.data;
  }

  public async getBriefNotes(
    params?: GetBriefNotesParams,
  ): Promise<PaginatedResults<BriefNote>> {
    const response: AxiosResponse<PaginatedResults<BriefNote>> =
      await this.api.get('/brief-notes', {
        params,
      });
    return response.data;
  }

  public async getBriefNote(briefNoteId: string): Promise<BriefNote> {
    const response: AxiosResponse<BriefNote> = await this.api.get(
      `/brief-notes/${briefNoteId}`,
    );
    return response.data;
  }

  public async updateBriefNote(
    briefNoteId: string,
    updates: UpdateBriefNoteDto,
  ): Promise<BriefNote> {
    const response: AxiosResponse<BriefNote> = await this.api.patch(
      `/brief-notes/${briefNoteId}`,
      updates,
    );
    return response.data;
  }

  public async deleteBriefNote(briefNoteId: string): Promise<void> {
    await this.api.delete(`/brief-notes/${briefNoteId}`);
  }
}

const _briefNoteService = new BriefNoteService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _briefNoteService;
