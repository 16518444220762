import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { fetchCalendars } from 'contexts/redux/calendar/calendarsSlice';
import { updateViewedCalendars } from 'contexts/redux/calendarEvent/calendarEventSlice';
import {
  setCalendarEventDetailsDialog,
  setCalendarsDialog,
  setNewCalenderMenuDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import {
  fetchMyUserPreferences,
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { BsCircleFill } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarEvent } from 'services/@types';
import CalendarSearch from './components/CalendarSearch';
import './custom.css';

require('moment/locale/he');

// const events = [
//   {
//     start: new Date(new Date().setHours(20)),
//     end: new Date(new Date().getTime() + 60 * 60 * 1000), // 1 hour
//     title: 'EVENT 1 HR!',
//   },
// ];

export default function CalendarFull() {
  const { i18n } = useTranslation();
  const myUser = useMyUser();
  const localizer = momentLocalizer(moment);

  const { activeCalendars } = useMyUserPreferences();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarViewType, setCalendarViewType] = useState<
    'month' | 'week' | 'day'
  >('month');
  const [isHighlightingToday, setIsHighlightingToday] = useState(false);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { calendars } = useSelector((state: RootState) => state.calendars);
  const { calendarEvents, loading: calendarEventsLoading } = useSelector(
    (state: RootState) => state.calendarEvent,
  );

  const getDayProp = useCallback(
    (date) => {
      if (date.getMonth() !== currentDate.getMonth()) {
        return {
          style: {
            backgroundColor: '#d9d9d9',
          },
        };
      }

      const isToday = moment(date).isSame(new Date(), 'day');
      return {
        style: {
          transition: 'background-color 0.5s ease',
          backgroundColor:
            isHighlightingToday && isToday
              ? '#e3f2fd'
              : date.getTime() === selectedDate.getTime()
              ? '#eee'
              : 'white',
        },
      };
    },
    [currentDate, selectedDate, isHighlightingToday],
  );
  const handleNextStep = useCallback(() => {
    console.log('next step');
    if (calendarViewType === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
    } else if (calendarViewType === 'month') {
      setCurrentDate(
        new Date(currentDate.setMonth(currentDate.getMonth() + 1)),
      );
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
    }
  }, [calendarViewType, currentDate]);

  const handlePrevStep = useCallback(() => {
    console.log('prev step');
    if (calendarViewType === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
    } else if (calendarViewType === 'month') {
      setCurrentDate(
        new Date(currentDate.setMonth(currentDate.getMonth() - 1)),
      );
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
    }
  }, [calendarViewType, currentDate]);

  const handleOnCalendarSelected = useCallback(
    (selectedItem: { value: string }) => {
      let newActiveCalendars = [...activeCalendars];

      const index = newActiveCalendars.indexOf(selectedItem.value);
      if (index !== -1) {
        // Calendar is already active, remove it
        newActiveCalendars.splice(index, 1);
      } else {
        // Calendar is not active, add it
        newActiveCalendars.push(selectedItem.value);
      }

      dispatch(
        updateUserPreferences({
          id: myUser?.id,
          updates: {
            activeCalendars: newActiveCalendars,
          },
        }),
      );
    },
    [activeCalendars, dispatch, myUser?.id],
  );

  useEffect(() => {
    dispatch(fetchCalendars({}));
    dispatch(fetchMyUserPreferences());
  }, [dispatch]);

  const handleOnSelectSlot = useCallback(
    (slot: any) => {
      dispatch(setNewCalenderMenuDialog({ item: slot }));
      setSelectedDate(slot.start);
    },
    [dispatch],
  );
  const displayedEvents = useMemo(() => {
    return calendarEvents.map((event) => ({
      ...event,
      title: event?.summary,
    }));
  }, [calendarEvents]);

  useEffect(() => {
    if (activeCalendars && currentDate) {
      dispatch(
        updateViewedCalendars({
          calendars: activeCalendars,
          currentDate,
          calendarViewType,
        }),
      );
    }
  }, [activeCalendars, dispatch, currentDate, calendarViewType]);

  const handleSelectEvent = useCallback(
    (event: CalendarEvent) => {
      console.log(`handle select event ->`, event);
      dispatch(setCalendarEventDetailsDialog({ item: event }));
    },
    [dispatch],
  );

  // scroll to current time when view is week or day
  useEffect(() => {
    if (calendarViewType === 'week' || calendarViewType === 'day') {
      setTimeout(() => {
        const scrollContainer = document.querySelector('.rbc-time-content');
        if (scrollContainer) {
          // Find today's cell
          const todayCell = document.querySelector(
            '.rbc-current-time-indicator',
          );
          if (todayCell) {
            todayCell.scrollIntoView({
              behavior: 'auto',
              block: 'center',
            });
          }
        }
      }, 500);
    }
  }, [calendarViewType, currentDate]);

  return (
    <Flex
      w="100%"
      h="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      flexDirection="column"
      gap={4}>
      <Card
        gap={5}
        h="100%"
        pos={'relative'}
        overflow="hidden"
        borderRadius={{ base: 0, md: 10 }}
        className={calendarViewType === 'week' ? 'week-view' : ''}>
        <HStack w="100%" key={'header'} gap={2} wrap="wrap">
          <HStack spacing={'0px'}>
            <IconButton
              size={'xs'}
              aria-label="left-month"
              as={BiChevronLeft}
              transform={`${i18n.dir() === 'rtl' ? 'rotate(180deg)' : ''}`}
              onClick={handlePrevStep}
            />
            <Flex flex={1} justify="center" align="center">
              <Text
                minW={200}
                textAlign="center"
                fontSize={'2xl'}
                fontWeight="bold">
                {moment(currentDate)
                  .locale(i18n.language)
                  .format(
                    calendarViewType === 'day' ? 'DD MMMM YYYY' : 'MMMM YYYY',
                  )}
              </Text>
            </Flex>
            <IconButton
              size="xs"
              aria-label="right-month"
              as={BiChevronRight}
              transform={`${i18n.dir() === 'rtl' ? 'rotate(180deg)' : ''}`}
              onClick={handleNextStep}
            />
          </HStack>

          <HStack
            flexWrap="nowrap"
            flex={1}
            gap={2}
            justify={{ base: 'flex-end', md: 'space-between' }}
            position="static">
            <IconButton
              margin={'0 !important'}
              size="xs"
              order={{ base: 1, md: 0 }}
              aria-label="add-event"
              variant={'actionIconBlue'}
              as={MdAdd}
              onClick={() => dispatch(setNewCalenderMenuDialog({}))}
            />
            <CalendarSearch />
          </HStack>
          <HStack flex={1}>
            <Button
              variant="h2outlined"
              onClick={() => {
                setCurrentDate(new Date());
                setIsHighlightingToday(true);
                setTimeout(() => setIsHighlightingToday(false), 4000);
              }}>
              {t('calendar.today')}
            </Button>
            <DropdownField
              closeOnSelect
              hideLabel
              minW={{ base: 'auto', md: '100px' }}
              flex={{ base: 1, md: 0 }}
              // placeholder={calendarViewType === 'month' ? 'Month' : 'Week'}
              placeholder={t(`calendar.${calendarViewType}`)}
              onSelected={({ value }: { value: string }) => {
                setCalendarViewType(value as 'month' | 'week' | 'day');
              }}
              options={[
                { value: 'month', label: t('calendar.month') },
                { value: 'week', label: t('calendar.week') },
                { value: 'day', label: t('calendar.day') },
              ]}
            />
            <DropdownField
              w={{ base: 'auto', '2xl': '310px' }}
              hideLabel
              menuItemProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              mb={0}
              minW={{ base: 'auto', '2xl': '310px' }}
              maxW={{ base: '100%', '2xl': '360px' }}
              flex={1}
              options={calendars?.map((calendar: any) => ({
                label: calendar.isSystem
                  ? t(`calendar.general_calendars.${calendar.name}`)
                  : calendar.email || calendar.name,
                value: calendar.label || calendar.id,
                id: calendar.label || calendar.id,
                startElement: (
                  <Icon
                    my={2}
                    me={'7px'}
                    as={BsCircleFill}
                    fontSize={8}
                    color={calendar.color}
                  />
                ),
              }))}
              topItem={
                <VStack w="100%">
                  <HStack
                    onClick={() => {
                      dispatch(setCalendarsDialog({}));
                    }}
                    w="100%"
                    justify={'end'}>
                    <Text color="primary.100" fontSize="sm" fontWeight={400}>
                      {t('calendar.all')}
                    </Text>
                    <Spacer />
                    <Text
                      _hover={{
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        dispatch(setCalendarsDialog({}));
                      }}
                      color="brand.900"
                      fontWeight={500}
                      textDecoration={'underline'}
                      fontSize="sm">
                      {t('calendar.manage_calendars')}
                    </Text>
                  </HStack>
                </VStack>
              }
              placeholder={t('calendar.choose_calendar')}
              onSelected={handleOnCalendarSelected}
              name="calendars"
              selectedOptions={activeCalendars}
            />
          </HStack>
        </HStack>

        <Calendar
          popup
          key={'calendar'}
          localizer={localizer}
          components={{
            dateCellWrapper: ({ children, value }) => {
              const isToday = moment(value).isSame(new Date(), 'day');
              return (
                <Flex
                  className={
                    isHighlightingToday && isToday && !calendarEventsLoading
                      ? 'date-cell-highlight'
                      : ''
                  }
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    background: 'white',
                    borderLeft: '0.5px solid #d9d9d9',
                    borderRight: '0.5px solid #d9d9d9',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    transition: 'background-color 0.3s',
                  }}>
                  {children}
                  <Flex
                    sx={{
                      position: 'absolute',
                      w: '100%',
                      h: '100%',
                      zIndex: 1,
                      cursor: 'pointer',
                      opacity: 1,
                      alignItems: 'center',
                      transition: 'background-color 0.3s',
                      justifyContent: 'center',
                    }}></Flex>
                </Flex>
              );
            },
          }}
          defaultDate={new Date()}
          date={currentDate}
          formats={{
            dateFormat: 'D',
            timeGutterFormat: 'HH:mm',
            dayHeaderFormat: (date, culture, localizer) =>
              localizer.format(date, 'dd', culture),
            dayFormat: (date, culture, localizer) =>
              localizer.format(date, 'D ddd', culture),
            eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
              localizer.format(start, 'HH:mm', culture) +
              ' - ' +
              localizer.format(end, 'HH:mm', culture),
          }}
          step={60}
          culture={i18n.language}
          view={calendarViewType}
          dayLayoutAlgorithm={
            calendarViewType === 'day' ? 'no-overlap' : 'overlap'
          }
          onNavigate={(date) => {
            console.log('onNavigate => ', date);
            // setCurrentDate(date);
          }}
          onView={(view) => console.log('onView => ', view)}
          toolbar={false}
          allDayMaxRows={0}
          rtl={i18n.dir() === 'rtl'}
          onSelectSlot={handleOnSelectSlot}
          onSelectEvent={handleSelectEvent}
          dayPropGetter={getDayProp}
          slotPropGetter={(date) => {
            return {
              style: {
                borderWidth: '0px',
              },
            };
          }}
          selectable={true}
          selected={{ start: currentDate }}
          events={displayedEvents}
          eventPropGetter={(event, start, end, isSelected) => {
            let backgroundColor = '#AA915D';

            if (event?.color) {
              backgroundColor = event.color;
            } else if (event?.category === 'meeting') {
              backgroundColor = '#93C3B3';
            } else if (event?.category === 'event') {
              backgroundColor = '#AA915D';
            }
            if (calendarViewType === 'month') {
              return {
                style: {
                  backgroundColor ,
                  color: 'white',
                  fontSize: '12px',

                  // paddingTop: '0px',
                  // paddingBottom: '0px',
                  // borderRadius: '5px',
                },
              };
            } else {
              return {
                style: {
                  backgroundColor: 'white',
                  border: `1px solid ${event?.color || '#AA915D'}`,
                  color: 'black',
                  fontSize: '12px',
                  marginLeft: '5px',
                  borderRadius: '16px',
                },
              };
            }
          }}
          style={{
            height: '100%',
            width: '100%',
            fontSize: '12px',
          }}
        />
      </Card>
    </Flex>
  );
}
