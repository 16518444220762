import { useToast } from '@chakra-ui/react';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  setBusinessEventPlan,
  useBusinessEventPlanSelector,
} from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import { closeAddParticipantDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Invite, IParticipant, Supplier } from 'services/@types';
import _businessEventPlanService from 'services/businessEventPlan.api';

export interface AssignedItem {
  id: string;
  email: string;
  label: string;
  value: string;
  supplier?: Supplier;
  invite?: Invite;
}

const useAddParticipant = () => {
  const { t } = useTranslation();
  const { businessEventPlan } = useBusinessEventPlanSelector();

  const dispatch = useDispatch<any>();
  const [isTeamMember, setIsTeamMember] = useState<boolean>(false);
  const [disabledTeamMemberField, setDisabledTeamMemberField] =
    useState<boolean>(false);
  const [adding, setAdding] = useState(false);
  const [assigned, setAssigned] = useState<AssignedItem[]>([]);
  const [currentAssigned, setCurrentAssigned] = useState<string>('');
  const toast = useToast();
  const myUser = useMyUser();

  const { addParticipantDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const { suppliers, seats, loading } = useSelector((state: RootState) => ({
    suppliers: state.suppliers.suppliers,
    seats: state.invites.invites,
    loading: state.suppliers.loading || state.invites.loading,
  }));

  const currentParticipants = useMemo(() => {
    return businessEventPlan
      ? [
          ...businessEventPlan?.participantsApproved,
          ...businessEventPlan?.participantsPending,
        ]
      : [];
  }, [businessEventPlan]);

  const suppliersMemo = useMemo(
    () =>
      suppliers.filter(
        (s) => !currentParticipants?.find((p) => p?.supplierId === s.id), // not already a participant
      ),
    [suppliers, currentParticipants],
  );
  const acceptedSeatsInvites = useMemo(
    () =>
      seats.filter(
        (seat) =>
          seat.status === 'accepted' &&
          seat.type === 'seat' &&
          seat.recipientID !== myUser?.id && // not myself
          !currentParticipants?.find((p) => p?.userId === seat.recipientID), // not already a participant
      ),
    [seats, myUser?.id, currentParticipants],
  );

  const handleClose = useCallback(() => {
    dispatch(closeAddParticipantDialog());
  }, [dispatch]);

  const handleCloseCompleted = useCallback(() => {
    setAssigned([]);
  }, []);

  const isOpen = useMemo(() => !!addParticipantDialog, [addParticipantDialog]);

  const [displayingAssigned, setDisplayingAssigned] = useState<AssignedItem[]>(
    [],
  );
  useEffect(() => {
    if (currentAssigned.length < 1 || !currentAssigned) {
      return;
    }
    const fetchSearchResults = async () => {
      if (!currentAssigned) {
        return;
      }
      const searchedInvites = acceptedSeatsInvites.filter(
        (invite) =>
          invite.fullName
            .toLowerCase()
            .includes(currentAssigned.toLowerCase()) ||
          invite.email.toLowerCase().includes(currentAssigned.toLowerCase()),
      );

      const searchedSuppliers = suppliersMemo.filter(
        (supplier) =>
          supplier.name.toLowerCase().includes(currentAssigned.toLowerCase()) ||
          supplier.email.toLowerCase().includes(currentAssigned.toLowerCase()),
      );

      const searchResults = [...searchedInvites, ...searchedSuppliers];
      setDisplayingAssigned(
        searchResults.map((item) => {
          if (item.hasOwnProperty('name')) {
            const supplier = item as Supplier;
            return {
              id: supplier.id,
              value: supplier.id,
              label: supplier.name,
              email: supplier.email,
              supplier,
            };
          } else {
            const seatInvite = item as Invite;
            return {
              id: seatInvite.id,
              value: seatInvite.id,
              label: seatInvite.fullName,
              email: seatInvite.email,
              invite: seatInvite,
            };
          }
        }),
      );
    };
    fetchSearchResults();
  }, [
    currentAssigned,
    dispatch,
    assigned,
    acceptedSeatsInvites,
    suppliersMemo,
  ]);

  const handleSelectAssigned = useCallback(
    async (value: string) => {
      const newParticipant = displayingAssigned.find(
        (item) => item.id === value,
      );

      setAssigned([newParticipant]);
      setCurrentAssigned('');
    },
    [displayingAssigned],
  );

  const validate = useCallback(() => {
    return assigned.length > 0 && businessEventPlan?.id;
  }, [assigned, businessEventPlan?.id]);

  const findParticipantInBusinessEventPlan = useCallback(
    (
      newParticipantPayload,
    ): {
      approved: boolean;
      participant: IParticipant;
    } | null => {
      if (!businessEventPlan) return null;
      // search in pending
      const pendingParticipant = businessEventPlan.participantsPending.find(
        (p) =>
          p.userId === newParticipantPayload.userId ||
          p.supplierId === newParticipantPayload.supplierId,
      );
      if (!pendingParticipant) {
        // search in approved
        const approvedParticipant = businessEventPlan.participantsApproved.find(
          (p) =>
            p.userId === newParticipantPayload.userId ||
            p.supplierId === newParticipantPayload.supplierId,
        );
        if (!approvedParticipant) {
          return null;
        }
        return {
          approved: true,
          participant: approvedParticipant,
        };
      }
      return {
        approved: false,
        participant: pendingParticipant,
      };
    },
    [businessEventPlan],
  );

  const handleSubmit = useCallback(async () => {
    if (!businessEventPlan) return;
    const validation = validate();
    if (!validation) {
      toast({
        title: t('event.add_participants_modal.error.select_participant'),
        status: 'error',
        variant: 'error',
      });
      return;
    }
    const newParticipant = assigned[0];

    const newParticipantPayload: any = {
      isTeamMember,
    };
    if (newParticipant.invite) {
      newParticipantPayload.userId = newParticipant.invite?.recipientID;
    } else {
      newParticipantPayload.supplierId = newParticipant.supplier?.id;
    }

    const generateUpdatePayload = () => {
      const participantInBusinessEventPlan = findParticipantInBusinessEventPlan(
        newParticipantPayload,
      );

      if (participantInBusinessEventPlan) {
        return null;
      }

      return {
        participantsApproved: businessEventPlan.participantsApproved.map(
          (a) => ({
            userId: a.userId,
            supplierId: a.supplierId,
            isTeamMember: a.isTeamMember,
          }),
        ),
        participantsPending: [
          ...businessEventPlan.participantsPending.map((a) => ({
            userId: a.userId,
            supplierId: a.supplierId,
            isTeamMember: a.isTeamMember,
          })),
          newParticipantPayload,
        ],
      };
    };
    try {
      const updatePayload = generateUpdatePayload();
      if (!updatePayload) {
        toast({
          title: t('event.participants.error.participant_already_in_event'),
          status: 'error',
          variant: 'error',
        });
        return;
      } else {
        setAdding(true);
        const result = await _businessEventPlanService.updateBusinessEventPlan(
          businessEventPlan.id,
          updatePayload,
        );
        toast({
          title: t('event.participants.add_participant_success'),
          status: 'success',
          variant: 'main',
        });
        dispatch(setBusinessEventPlan(result));
        dispatch(closeAddParticipantDialog());
      }
    } catch (err) {
      toast({
        title: t('event.participants.error.add_participant'),
        status: 'error',
        variant: 'error',
      });
    } finally {
      setAdding(false);
    }
  }, [
    assigned,
    businessEventPlan,
    dispatch,
    findParticipantInBusinessEventPlan,
    isTeamMember,
    toast,
    validate,
    t,
  ]);
  const handleRemoveAssigned = () => setAssigned([]);

  useEffect(() => {
    // if currentAssignedItem is a supplier, set disabledTeamMember to true

    setDisabledTeamMemberField(!!assigned?.[0]?.supplier);
    setIsTeamMember(!assigned?.[0]?.supplier);
  }, [assigned]);

  return {
    isOpen,
    handleClose,
    handleCloseCompleted,
    displayingAssigned,
    assigned,
    currentAssigned,
    handleSelectAssigned,
    handleRemoveAssigned,
    isTeamMember,
    setIsTeamMember,
    disabledTeamMemberField,
    loading,
    adding,
    handleSubmit,
    setCurrentAssigned,
  };
};

export default useAddParticipant;
