import { setEventFoundDialog } from 'contexts/redux/dialog/dialogsSlice';
import {
  getSimilarEvents,
  updateSimilarEventsList,
} from 'contexts/redux/event/eventSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface EventFoundRes {
  handleOpenEventFoundDialog: () => void;
  foundedEvent: Record<string, any>;
  clearEventFound: () => void;
}
export default function useEventFound(
  event: Record<string, any>,
): EventFoundRes {
  const dispatch = useDispatch<any>();
  const similarEvents = useSelector(
    (state: RootState) => state.event.similarEvents,
  );

  const handleOpenEventFoundDialog = useCallback(() => {
    dispatch(setEventFoundDialog({ event: similarEvents[0] || null }));
  }, [dispatch, similarEvents]);

  const clearEventFound = useCallback(() => {
    dispatch(updateSimilarEventsList([]));
  }, [dispatch]);

  useEffect(() => {
    const fetchSimilarEvents = async (params: any) => {
      try {
        await dispatch(getSimilarEvents(params));
      } catch (error) {
        console.error(error);
      }
    };
    if (event?.clients?.length && event?.dateAndTime) {
      fetchSimilarEvents({
        email: event.clients[0].email,
        phone: event.clients[0].phone,
        dateAndTime: event.dateAndTime,
      });
    }
    if (
      event?.dateAndTime &&
      event?.location &&
      event?.clients.length &&
      event?.clients[0]?.firstName &&
      event?.clients[0]?.lastName
    ) {
      fetchSimilarEvents({
        dateAndTime: event.dateAndTime,
        location: event.location.label,
        firstName: event.clients[0].firstName,
        lastName: event.clients[0].lastName,
      });
    }
  }, [event, dispatch]);
  return {
    handleOpenEventFoundDialog,
    foundedEvent: similarEvents[0] || null,
    clearEventFound,
  };
}
