export const TextStyles = {
  components: {
    Text: {
      baseStyle: {
        letterSpacing: '-0.02rem',
      },
      variants: {
        cardTitle: () => ({
          fontSize: '24px',
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
        }),
        cardSubTitle: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: 'rgba(0, 0, 0, 0.6)',
        }),
        cardSectionTitle: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.6)',
        }),
        cardSectionInfo: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.8)',
          ps: '8px',
        }),
        serviceTitle: () => ({
          fontSize: '13px',
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.4)',
        }),
        eventSubTitle: () => ({
          fontSize: '16px',
          fontWeight: '500',
          color: 'brand.900',
        }),
        eventLink: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: 'brand.400',
          cursor: 'pointer',
          textDecoration: 'underline',
        }),
        errorTitle: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: 'error.100',
        }),
        detailInfoLabel: () => ({
          fontSize: '16px',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.8)',
        }),
        detailInfoValue: () => ({
          fontSize: '16px',
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.6)',
        }),
        eventCardDate: () => ({
          fontSize: '24px',
          fontWeight: '400',
          color: 'brand.900',
          lineHeight: '24px',
        }),
        eventCardTitle: () => ({
          fontSize: '14px',
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          wordBreak: 'break-word',
          noOfLines: 1,
        }),
        eventCardSubTitle: () => ({
          fontSize: '12px',
          fontWeight: '400',
          color: 'rgba(0, 0, 0, 0.6)',
          wordBreak: 'break-word',
          noOfLines: 1,
        }),
        taskCardDate: () => ({
          fontSize: '24px',
          fontWeight: '500',
          color: '#93C3B3',
          lineHeight: '21px',
        }),
        taskCardSubTitle: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: 'rgba(0, 0, 0, 0.6)',
          wordBreak: 'break-word',
          noOfLines: 1,
        }),
        dashboardEventCardTitle: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#000000CC',
          lineHeight: '15px',
          letterSpacing: '-2%',
        }),
        dashboardEventCardTime: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#AA915D',
          lineHeight: '15px',
          letterSpacing: '-2%',
        }),
        assingSuggestions: () => ({
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '18.23px',
          color: '#000000',
          wordBreak: 'break-all',
          noOfLines: 1,
        }),
        underlineRed: (props: any) => ({
          overflow: 'hidden',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-2%',
          fontWeight: '400',
          transition: 'all .25s ease',
          color: '#FF4D4F',
          _hover: {},
        }),
        missedMessageCounter: () => ({
          fontSize: '6.67px',
          fontWeight: '700',
          color: '#FFFFFF',
          lineHeight: '8.68px',
        }),
        settingItemTitle: () => ({
          fontSize: '20px',
          fontWeight: '600',
          color: '#000000',
          lineHeight: '26.04px',
        }),
        chatHeaderTitle: () => ({
          fontSize: '20px',
          fontWeight: '700',
          color: '#1B2037',
          lineHeight: '32px',
          letterSpacing: '-2%',
        }),
        trackItemNoteTitle: () => ({
          fontSize: '14px',
          fontWeight: '700',
          color: '#00000099',
          lineHeight: '14px',
          letterSpacing: '-2%',
        }),
        trackItemNoteTitleDate: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#00000066',
          lineHeight: '14px',
          letterSpacing: '-2%',
        }),
        textAreaLengthCounter: () => ({
          fontSize: '12px',
          fontWeight: '700',
          color: '#00000066',
          lineHeight: '18px',
        }),
        noEventsTitle: () => ({
          fontSize: '24px',
          fontWeight: '500',
          color: '#939393',
          lineHeight: '31.25px',
          letterSpacing: '-2%',
        }),
        noEventsSubTitle: () => ({
          fontSize: '16px',
          fontWeight: '500',
          color: '#939393',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        eventGridCardStatus: () => ({
          fontSize: '10px',
          fontWeight: '700',
          color: '#FFFFFF',
          lineHeight: '13.02px',
        }),
        eventGridCardDate: () => ({
          fontSize: '12px',
          fontWeight: '500',
          color: '#000000CC',
          lineHeight: '15.62px',
          letterSpacing: '-2%',
        }),
        eventGridCardTitle: () => ({
          fontSize: '16px',
          fontWeight: '700',
          color: '#000000CC',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
          noOfLines: 1,
        }),
        eventGridCardSubTitle: () => ({
          fontSize: '14px',
          fontWeight: '700',
          color: '#AA915D',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
          noOfLines: 1,
        }),
        eventGridCardDesc: () => ({
          fontSize: '12px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '15.62px',
          letterSpacing: '-2%',
          noOfLines: 1,
        }),
        eventTabButtonCount: () => ({
          fontSize: '12px',
          fontWeight: '400',
          color: '#AA915D',
          lineHeight: '18px',
        }),
        eventTabButtonTitle: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
        }),
        mobileFilterBurger: () => ({
          fontSize: '16px',
          fontWeight: '500',
          color: '#666666',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        taskHistoryTitle: () => ({
          fontSize: '20px',
          fontWeight: '700',
          color: '#000000CC',
          lineHeight: '26.04px',
          letterSpacing: '-2%',
        }),
        taskHistoryType: () => ({
          color: '#AA915D',
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
        }),
        taskHistoryDate: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: '#00000066',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        noCompletedTasksTitle: () => ({
          fontSize: '20px',
          fontWeight: '500',
          color: '#939393',
          lineHeight: '26.04px',
          letterSpacing: '-2%',
        }),
        noCompletedTasksSubTitle: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#939393',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
        }),
        trackerEventName: () => ({
          fontSize: '14px',
          fontWeight: '600',
          color: '#AA915D',
          lineHeight: '14px',
          letterSpacing: '-2%',
          textDecoration: 'underline',
        }),
        createQuoteTitle: () => ({
          fontSize: '16px',
          fontWeight: '600',
          color: '#000000',
          lineHeight: '28px',
          letterSpacing: '-2%',
        }),
        createQuoteCheckboxText: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#00000099',
          lineHeight: '28px',
          letterSpacing: '-2%',
        }),
        createQuote1Of1Payment: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#AA915D',
          lineHeight: '24px',
          letterSpacing: '-2%',
        }),
        currency: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#000000',
          lineHeight: '14px',
          letterSpacing: '-2%',
        }),
        addNewProduct: () => ({
          fontSize: '14px',
          fontWeight: '700',
          color: '#AA915D',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
          textDecoration: 'underline',
        }),
        previewQuoteHeaderTitle: () => ({
          fontSize: '24px',
          fontWeight: '500',
          color: '#000000',
          lineHeight: '31.25px',
          letterSpacing: '-2%',
        }),
        previewQuoteHeaderSubTitle: () => ({
          fontSize: '16px',
          fontWeight: '300',
          color: '#00000099',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        previewQuoteContactTitle: () => ({
          fontSize: '24px',
          fontWeight: '700',
          color: '#000000CC',
          lineHeight: '31.25px',
          letterSpacing: '-2%',
        }),
        previewQuoteContactNumber: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: '#AA915D',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
          textDecoration: 'underline',
        }),
        previewQuoteEventName: () => ({
          fontSize: '16px',
          fontWeight: '700',
          color: '#AA915D',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        previewQuoteTotalAmount: () => ({
          fontSize: '36px',
          fontWeight: '700',
          color: '#000000CC',
          lineHeight: '36px',
          letterSpacing: '-2%',
        }),
        previewQuoteContactLabel: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        previewQuoteProductHeader: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#00000099',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
          textTransform: 'uppercase',
        }),
        previewQuoteProductItemName: () => ({
          fontSize: '16px',
          fontWeight: '500',
          color: '#000000',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        previewQuoteProductItemDescription: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
        }),
        previewQuoteProductItemVat: () => ({
          fontSize: '12px',
          fontWeight: '400',
          color: '#00000066',
          lineHeight: '15.62px',
          letterSpacing: '-2%',
        }),
        previewQuoteTitle: () => ({
          fontSize: '18px',
          fontWeight: '700',
          color: '#000000',
          lineHeight: '23.4px',
          letterSpacing: '-2%',
        }),
        previewQuoteNote: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        previewQuotePaymentTermsTitle: () => ({
          fontSize: '16px',
          fontWeight: '500',
          color: '#00000099',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        previewQuotePaymentTermsNote: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: '#000000CC',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        previewQuoteCopyright: () => ({
          fontSize: '14px',
          fontWeight: '600',
          color: '#AA915D',
          lineHeight: '14px',
          letterSpacing: '-2%',
        }),
        previewQuoteSummaryLabel: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: '#000000',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        previewQuoteSummaryAmount: () => ({
          fontSize: '20px',
          fontWeight: '600',
          color: '#000000',
          lineHeight: '26.04px',
          letterSpacing: '-2%',
        }),
        previewQuotePaymentItem: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '24px',
          letterSpacing: '-2%',
        }),
        previewQuoteContactLabelSmall: () => ({
          fontSize: '12px',
          fontWeight: '400',
          color: '#00000099',
          // lineHeight: 1,
          // letterSpacing: '-2%',
        }),
        noClientsDescription: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#00000066',
          lineHeight: '20px',
          letterSpacing: '-2%',
        }),
        addClientSubTitle: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '26px',
          letterSpacing: '-2%',
        }),
        inviteToBrilliant: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#AA915D',
          lineHeight: '20px',
          letterSpacing: '-2%',
        }),
        clientName: () => ({
          fontSize: '14px',
          fontWeight: '700',
          color: '#000000CC',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
          noOfLines: 1,
        }),
        clientEmail: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#939393',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
          noOfLines: 1,
        }),
        clientDetailInfo: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#000000CC',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
        }),
        clientDetailUnderline: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#AA915D',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
          textDecoration: 'underline',
        }),
        supplierDetailsNote: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '32px',
          letterSpacing: '-2%',
        }),
        supplierDetailsInfo: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '14px',
          letterSpacing: '-2%',
        }),
        addRowText: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#000000CC',
          lineHeight: '14px',
          letterSpacing: '-2%',
        }),
        contractFileText: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: '#685D60',
          lineHeight: '14px',
          letterSpacing: '-2%',
        }),
        eventPageCardTitle: () => ({
          fontSize: '24px',
          fontWeight: '700',
          color: '#141718',
          lineHeight: '31.25px',
        }),
        lineUpFormDialogFileName: () => ({
          fontSize: '16px',
          fontWeight: '600',
          color: '#000000CC',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        lineUpItemFileName: () => ({
          fontSize: '14px',
          fontWeight: '600',
          color: '#000000CC',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
        }),
        lineUpItemDelete: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: '#999EA9',
          lineHeight: '20.83px',
          letterSpacing: '-2%',
        }),
        addEventClientDocumentSignedDocumentDescription: () => ({
          fontSize: '10px',
          fontWeight: '400',
          color: '#00000099',
          lineHeight: '13.02px',
          letterSpacing: '-2%',
        }),
        addEventClientDocumentSignedDocument: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: '#AA915D',
          lineHeight: '18.23px',
          letterSpacing: '-2%',
        }),
        quotePackageMarker: () => ({
          fontSize: '16px',
          fontWeight: '700',
          color: '#AA915D',
          lineHeight: '15px',
          letterSpacing: '-2%',
        }),
      },
    },
  },
};
