import { Flex, Image, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import packageIcon from 'assets/svg/products/package-icon.svg';

const PackageIcon = ({
  isPackage,
  ...props
}: {
  isPackage: boolean;
  [key: string]: any;
}) => {
  const { t } = useTranslation();
  if (!isPackage) {
    return null;
  }
  return (
    <Flex alignItems="center" gap={1} px="12px" {...props}>
      <Image src={packageIcon} alt="package" />
      <Text variant="quotePackageMarker">{t('create_quote.package')}</Text>
    </Flex>
  );
};

export default memo(PackageIcon);
