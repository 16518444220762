import { HStack, Text, VStack } from '@chakra-ui/react';
import { BusinessImage } from './ParticipantSearchItemImages';
import { Business, BusinessCategory, Supplier } from 'services/@types';
import { useBusinessContact } from 'hooks/useBusinessContact';

const SupplierSearchItem = ({ supplier }: { supplier: Supplier }) => {
  const { business } = useBusinessContact<
    Omit<Business, 'category'> & {
      category: Pick<BusinessCategory, 'id' | 'name' | 'description'>[];
    }
  >(supplier, true);

  return (
    <>
      <BusinessImage business={business} />
      <VStack spacing={0} alignItems="flex-start">
        <HStack
          spacing={0}
          fontSize="16px"
          fontWeight={700}
          color="black"
          gap={1}
          letterSpacing={'-2%'}>
          <Text variant="assingSuggestions" fontWeight={700}>
            {supplier.name}{' '}
          </Text>
          <Text color="#AA915D">
            {business?.category &&
              business.category.map((c) => c.name).join(', ')}
          </Text>
        </HStack>
        {supplier.internalNotes && (
          <Text fontSize="14px" color="00000099">
            {supplier.internalNotes}
          </Text>
        )}
      </VStack>
    </>
  );
};

export default SupplierSearchItem;
