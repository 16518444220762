import { fetchEventFinance } from 'contexts/redux/event/eventSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { formatNumber } from 'helpers/formatNumber';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function useFinance() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { finance } = useSelector((state: RootState) => state.event);

  const expensesStat = useMemo(
    () => [
      {
        label: 'expected',
        value: `₪${formatNumber(finance?.expenses.totalPending)}`,
        bgGradient: 'linear(to-r, brand.900, brand.800)',
      },
      {
        label: 'paid',
        value: `₪${formatNumber(finance?.expenses.totalPaid)}`,
        bgGradient: 'linear(to-r, brand.900, brand.800)',
      },
      {
        label: 'unpaid',
        value: `₪${
          finance?.expenses.totalPending > 0
            ? formatNumber(
                finance?.expenses.totalPaid - finance?.expenses.totalPending,
              )
            : '0'
        }`,
        bgGradient: 'linear(to-r, brand.900, brand.800)',
      },
    ],
    [finance?.expenses.totalPending, finance?.expenses.totalPaid],
  );

  const upcomingExpenses = useMemo(() => {
    return finance?.expenses.pending || [];
  }, [finance?.expenses.pending]);

  const paidExpenses = useMemo(() => {
    return finance?.expenses.paid || [];
  }, [finance?.expenses.paid]);

  useEffect(() => {
    if (params?.id) {
      dispatch(fetchEventFinance(params?.id));
    }
  }, [params?.id, dispatch]);

  return { expensesStat, upcomingExpenses, paidExpenses };
}
