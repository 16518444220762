import { Button, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { setShareMyCalendarDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, ExtendedCalendar, Invite } from 'services/@types';
import { CalendarItem } from './CalendarItem';

export const SharedCalendars = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myUser = useMyUser();
  const { calendars } = useSelector((state: RootState) => state.calendars);
  const { invites } = useSelector((state: RootState) => state.invites);

  // Filter calendars shared with me and my shared calendars
  const sharedCalendars = useMemo<{
    sharedWithMe: ExtendedCalendar[];
    mySharedCalendars: Invite[];
  }>(() => {
    let sharedWithMe = calendars
      .filter((calendar) => calendar.type === 'shared' && calendar.userId)
      .map((calendar) => {
        const invite = invites.find(
          (invite) =>
            invite.type === 'calendar' &&
            invite.status === 'accepted' &&
            invite.recipientID === calendar.userId,
        );
        return { ...calendar, inviteId: invite?.id };
      });

    const mySharedCalendars = invites.filter(
      (invite) =>
        invite.type === 'calendar' &&
        invite.status === 'accepted' &&
        invite.senderID === myUser.id,
    );
    return { sharedWithMe, mySharedCalendars };
  }, [calendars, invites, myUser]);

  const handleShareCalendar = useCallback(() => {
    dispatch(setShareMyCalendarDialog({}));
  }, [dispatch]);

  return (
    <Card
      w="100%"
      variant="outline"
      flex={1}
      alignSelf="stretch"
      borderRadius={{ base: 0, md: '30px' }}
      p={{ base: '24px 16px 44px', md: '24px 24px 64px' }}
      marginStart={{ base: '0 !important', md: '20px !important' }}
      mt={{ base: '20px !important', md: '0 !important' }}>
      <VStack align="flex-start" spacing={0.5} mb={6}>
        <Text variant="cardTitle" lineHeight={'31px'}>
          {t('shared_calendars.title')}
        </Text>
        <Text fontSize={16} color="gray.500">
          {t('shared_calendars.description')}
        </Text>
      </VStack>
      <VStack
        // justifyContent={'space-around'}
        align="stretch"
        flex={1}>
        {/* Calendars shared with me */}
        <VStack flex={1} alignItems="stretch">
          <Text fontSize="sm" fontWeight="bold" mb={3}>
            {t('shared_calendars.shared_with_me')}
          </Text>
          {sharedCalendars.sharedWithMe.length > 0 ? (
            <VStack spacing={6} align="stretch">
              {sharedCalendars.sharedWithMe.map((calendar: Calendar) => (
                <CalendarItem
                  key={calendar.id}
                  itemData={calendar as ExtendedCalendar}
                />
              ))}
            </VStack>
          ) : (
            <VStack
              flex={1}
              alignItems={'center'}
              justifyContent="center"
              my={'60px !important'}>
              <Text fontSize="12px" color="gray.500">
                {t('shared_calendars.no_shared_calendars')}
              </Text>
            </VStack>
          )}
        </VStack>
        {/* My shared calendars */}
        <VStack flex={1} align="stretch" mt={'24px !important'}>
          <Text
            fontSize="sm"
            fontWeight="bold"
            mb={sharedCalendars.mySharedCalendars.length > 0 ? 3 : '60px'}
            alignSelf="flex-start">
            {t('shared_calendars.my_calendar_shared_with')}
          </Text>
          {/* Share Calendar Button */}
          <VStack flex={1} alignItems="stretch" m="0 !important">
            <Button
              onClick={handleShareCalendar}
              variant="h1cta"
              alignSelf="center"
              height={'44px'}>
              {t('shared_calendars.share_my_calendar')}
            </Button>
            {sharedCalendars.mySharedCalendars.length > 0 ? (
              <VStack spacing={6} align="stretch">
                {sharedCalendars.mySharedCalendars.map((invite: Invite) => (
                  <CalendarItem key={invite.id} itemData={invite} />
                ))}
              </VStack>
            ) : (
              <VStack
                flex={1}
                alignItems={'center'}
                mt="60px !important"
                justifyContent="center">
                <Text fontSize="12px" color="gray.500">
                  {t('shared_calendars.not_sharing_calendar')}
                </Text>
              </VStack>
            )}
          </VStack>
        </VStack>
      </VStack>
    </Card>
  );
};
