import { Flex, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IContract } from 'services/@types';
import ContractListItem from './ContractListItem';

export default function ContractList(props: {
  contracts: IContract[];
  headers: string[];
  handleSort: (header: 'customer' | 'date' | 'event' | 'quote_number') => void;
  sortBy: string;
  handleDeleteContract: (
    e: React.MouseEvent<HTMLDivElement>,
    id: string,
  ) => void;
}) {
  const { contracts, headers, handleDeleteContract, handleSort, sortBy } =
    props;
  const { t } = useTranslation();
  return (
    <VStack w="100%" spacing={0} align="stretch" overflowY="hidden">
      <Flex py={4} px={6} bg="white">
        {headers.map((header, index) => (
          <Flex
            key={header}
            flex={index === headers.length - 1 ? '0 0 60px' : 1}
            align="center"
            fontSize="14px"
            color="#00000099"
            onClick={() => {
              if (header && header !== 'draft') {
                handleSort(
                  header as 'customer' | 'date' | 'event' | 'quote_number',
                );
              }
            }}
            fontWeight={sortBy === header ? '600' : '400'}
            cursor={header && 'pointer'}>
            {header ? (
              <Text cursor={header && 'pointer'}>
                {t(`contracts.${header.toLowerCase()}`)}
              </Text>
            ) : (
              ''
            )}
          </Flex>
        ))}
      </Flex>

      <VStack spacing={0} align="stretch" overflowY="auto">
        {contracts.map((contract) => (
          <ContractListItem
            key={contract.id}
            contract={contract}
            handleDeleteContract={handleDeleteContract}
          />
        ))}
      </VStack>
    </VStack>
  );
}
