import { VStack, HStack, Text, Image, Box } from '@chakra-ui/react';
interface SummaryProps {
  sum: string;
  vat: string;
  total: string;
}
export const Summary = ({ sum, vat, total }: SummaryProps) => {
  return (
    <VStack w="full" spacing={0} gap={{ base: 5, lg: '30px' }}>
      <HStack justify="space-between" w="full" pl="24" color="blackAlpha.800">
        <VStack align="flex-start" w="124px" spacing="1">
          <Text>Sum</Text>
          <Text>VAT 17%</Text>
        </VStack>
        <VStack align="flex-start" w="124px" spacing="1" fontWeight="bold">
          <Text>{sum}</Text>
          <Text>{vat}</Text>
        </VStack>
      </HStack>

      <HStack
        w="full"
        px="10"
        py="5"
        bg="#93C3B3"
        rounded="3xl"
        boxShadow="14px 17px 40px 4px #7090B014"
        justify="center"
        spacing="6">
        <HStack spacing="3" fontWeight="bold" color="#00000099">
          <Image
            w="6"
            h="6"
            alt="Total icon"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6291f44bdf29fc6db64ecdf4a72ac530c1ff2d30d1ea9f209565d52ba66da95c"
          />
          <Text>Total</Text>
        </HStack>
        <Box
          px="2"
          py="4"
          bg="whiteAlpha.600"
          rounded="lg"
          minH="42px"
          w="124px"
          textAlign="center"
          fontWeight="medium"
          color="#465D55">
          {total}
        </Box>
      </HStack>
    </VStack>
  );
};
