import {
  Flex,
  Hide,
  HStack,
  IconButton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import piggyBankIcon from 'assets/svg/event/finance/piggy-bank-icon.svg';
import downTrendIcon from 'assets/svg/event/finance/trend-down.svg';
import upTrendIcon from 'assets/svg/event/finance/trend-up.svg';

import Card from 'components/card/Card';
import { AppDispatch } from 'contexts/redux/store';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import DataStatSquare from './DataStatSquare';

const FinanceStatRibbon = () => {
  const { style, ref } = useHighlightAnimation('profit');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { blur, id } = useMyUserPreferences();
  const toggleDataVisibility = useCallback(() => {
    dispatch(
      updateUserPreferences({
        id,
        updates: {
          blur: { ...blur, financeTopRibbon: !blur?.financeTopRibbon },
        },
      }),
    );
  }, [blur, dispatch, id]);

  return (
    <Card p={3} gap={5} w="100%" borderRadius={{ base: 'none', md: '20px' }} ref={ref} {...style}>
      <HStack
        px={5}
        position={{ base: 'relative', xl: 'absolute' }}
        right={{ base: 0, xl: i18n.language === 'he' ? 0 : 6 }}
        left={{ base: 0, xl: i18n.language === 'he' ? 6 : undefined }}
        w={{ base: undefined, xl: '100%' }}
        justifyContent={{ base: 'space-between', xl: 'flex-end' }}>
        <Hide above="xl">
          <Text fontSize="xl" fontWeight="bold" color="black">
            {t('event_finance.gross_profit')}
          </Text>
        </Hide>
        <IconButton
          aria-label={
            blur.financeTopRibbon
              ? 'Hide financial data'
              : 'Show financial data'
          }
          icon={blur.financeTopRibbon ? <FiEye /> : <FiEyeOff />}
          variant="ghost"
          color="black"
          onClick={toggleDataVisibility}
        />
      </HStack>
      <Flex
        direction={isMobile ? 'column' : 'row'}
        gap={isMobile ? 5 : 8}
        justify="space-between"
        width="100%"
        justifyContent={'center'}>
        <HStack
          w="100%"
          gap={5}
          justifyContent="space-around"
          wrap="wrap"
          px={5}
          maxW={{ base: '100%', md: '900px' }}
          spacing={0}>
          <DataStatSquare
            label={t('event_finance.total_income')}
            borderColor="brand.400"
            amount={150000}
            icon={upTrendIcon}
            isBlurred={!blur.financeTopRibbon}
          />

          <DataStatSquare
            label={t('event_finance.total_expenses')}
            borderColor="brand.900"
            amount={75000}
            icon={downTrendIcon}
            isBlurred={!blur.financeTopRibbon}
          />

          <DataStatSquare
            label={t('event_finance.net_profit')}
            amount={25000}
            icon={piggyBankIcon}
            bg="brand.400"
            textColor="white"
            isBlurred={!blur.financeTopRibbon}
          />
        </HStack>
      </Flex>
    </Card>
  );
};

export default FinanceStatRibbon;
