import { Button, Divider, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CreateContractClientInfo from './components/CreateContractClientInfo';
import CreateContractHeader from './components/CreateContractHeader';
import CreateContractProducts from './components/CreateContractProducts';
import CreateContractResipient from './components/CreateContractResipient';
import useContractForm from './hooks/useContractForm';
import Card from 'components/card/Card';

export default function CreateContract() {
  const { t } = useTranslation();
  const {
    event,
    handleOpenEventDialog,
    contract,
    selectedCurrency,
    selectedLanguage,
    handleChange,
    showedCurrency,
    showedLanguage,
    radioValue,
    setRadioValue,
    qoutes,
    clients,
    handleSelectedQuote,
    handleSelectedClient,
    selectedEventType,
    showedEventType,
    handleChangeEventType,
    products,
    handleAddProduct,
    handleRemoveProduct,
    handleProductDescriptionSelected,
    handleProductChange,
    handleProductVatChange,
    currencySymbol,
    displayingSentTo,
    handleSelectSentTo,
    currentSentTo,
    handleChangeCurrentSentTo,
    handleRemoveSentToItem,
    handleChangeCurrency,
    addRecipient,
    handleChangeRecipient,
    handleRemoveRecipient,
    handleAddFile,
    clearFile,
    loading,
    fileInputRef,
    handleCreateContract,
    handleAddQuoteFile,
    userId,
  } = useContractForm();
  return (
    <Flex
      w="100%"
      pt={{ base: '200px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '57px' }}
      flexDirection="column"
      gap={4}>
      <Card gap="36px">
        <CreateContractHeader
          event={event}
          handleOpenEventDialog={handleOpenEventDialog}
          contract={contract}
          selectedCurrency={selectedCurrency}
          selectedLanguage={selectedLanguage}
          handleChange={handleChange}
          showedCurrency={showedCurrency}
          showedLanguage={showedLanguage}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          qoutes={qoutes}
          handleSelectedQuote={handleSelectedQuote}
          handleChangeCurrency={handleChangeCurrency}
          handleAddQuoteFile={handleAddQuoteFile}
          clearFile={clearFile}
          userId={userId}
        />
        <Divider />
        <CreateContractClientInfo
          clients={clients}
          handleSelectedClient={handleSelectedClient}
          contract={contract}
          handleChange={handleChange}
          selectedEventType={selectedEventType}
          showedEventType={showedEventType}
          handleChangeEventType={handleChangeEventType}
        />
        <Divider />
        <CreateContractProducts
          contract={contract}
          products={products}
          handleAddProduct={handleAddProduct}
          handleRemoveProduct={handleRemoveProduct}
          handleProductDescriptionSelected={handleProductDescriptionSelected}
          handleProductChange={handleProductChange}
          handleProductVatChange={handleProductVatChange}
          handleChange={handleChange}
          currencySymbol={currencySymbol}
          displayingSentTo={displayingSentTo}
          handleSelectSentTo={handleSelectSentTo}
          currentSentTo={currentSentTo}
          handleChangeCurrentSentTo={handleChangeCurrentSentTo}
          handleRemoveSentToItem={handleRemoveSentToItem}
          fileInputRef={fileInputRef}
          handleAddFile={handleAddFile}
          clearFile={clearFile}
        />
        <CreateContractResipient
          addRecipient={addRecipient}
          contract={contract}
          handleChangeRecipient={handleChangeRecipient}
          handleRemoveRecipient={handleRemoveRecipient}
        />
        <Flex
          justifyContent="flex-end"
          gap="16px"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Button
            w={{ base: '100%', md: '240px' }}
            variant="h2outlined"
            isLoading={loading}
            onClick={() => handleCreateContract(true)}>
            {t('create_contract.save_as_draft')}
          </Button>
          <Button
            isLoading={loading}
            w={{ base: '100%', md: '240px' }}
            variant="h1cta"
            onClick={() => handleCreateContract(false)}>
            {t('create_contract.send_contract')}
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
}
