import { useMediaQuery, useToast } from '@chakra-ui/react';
import {
  deleteContract,
  fetchContracts,
} from 'contexts/redux/contract/contractSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IParams {
  search?: string;
  sortBy?: string;
  projectBy?: string;
  limit?: number;
}

const headers = ['customer', 'date', 'event', 'quote_number', 'draft', ''];

const headersMobile = headers.filter((_, index) => index !== 3 && index !== 4);

const getSortBy = (header: string) => {
  switch (header) {
    case 'customer':
      return '-clientDetails.name';
    case 'date':
      return '-contractDate';
    case 'event':
      return '-eventName';
    case 'quote_number':
      return '-quoteNumber';
  }
  return '';
};

export default function useContracts() {
  const { contracts } = useSelector((state: RootState) => state.contracts);
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const headerFields = useMemo(() => {
    return isMobile ? headersMobile : headers;
  }, [isMobile]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleDeleteContract = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, id: string) => {
      e.stopPropagation();
      dispatch(deleteContract(id));
      toast({
        title: t('contracts.contract_deleted'),
        status: 'success',
        position: 'top-right',
      });
    },
    [dispatch, toast, t],
  );

  const handleSort = useCallback(
    (header: 'customer' | 'date' | 'event' | 'quote_number') => {
      if (sortBy === header) {
        setSortBy('');
        return;
      }
      setSortBy(header);
    },
    [sortBy],
  );

  useEffect(() => {
    const params: IParams = {
      limit: 20,
      projectBy: '-updatedAt',
    };
    if (search) {
      params.search = search;
    }
    if (sortBy) {
      params.sortBy = getSortBy(sortBy);
    }
    dispatch(fetchContracts(params));
  }, [dispatch, search, sortBy]);

  return {
    contracts,
    search,
    handleDeleteContract,
    handleSearch,
    isMobile,
    headerFields,
    handleSort,
    sortBy,
  };
}
