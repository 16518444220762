import { useCallback, useState } from 'react';
import { IClientContact } from 'services/@types';

const requiredFields = ['firstName', 'lastName', 'phone', 'email'];

type UseNewClientContactProps = {
  handleCreateContact: (contact: IClientContact) => void;
};

const emptyContact: IClientContact = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
};

const useNewClientContact = ({
  handleCreateContact,
}: UseNewClientContactProps) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [contact, setContact] = useState<IClientContact>(emptyContact);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (errors.includes(name)) {
        setErrors(errors.filter((field) => field !== name));
      }
      setContact((prev) => ({ ...prev, [name]: value }));
    },
    [errors],
  );
  const validateNewContact = useCallback(() => {
    const newErrors = requiredFields.filter(
      (field) => !contact?.[field as keyof IClientContact],
    );
    setErrors(newErrors);

    return newErrors.length === 0;
  }, [contact]);

  const handleClearContact = useCallback(() => {
    setContact(emptyContact);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!validateNewContact()) return;

    handleCreateContact(contact);
    setContact(emptyContact);
  }, [validateNewContact, contact, handleCreateContact]);

  return {
    contact,
    errors,
    handleChange,
    handleSubmit,
    handleClearContact,
  };
};

export default useNewClientContact;
