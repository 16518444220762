import {
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import {
  AppDialogProps,
  closeClientFoundDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoWarning } from 'react-icons/io5';
import { MdCheck, MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { IClient } from 'services/@types';
import ClientInfo from './ClientInfo';

export const ClientFoundModal: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const clientFoundDialog = useSelector(
    (state: RootState) => state.dialogs.clientFoundDialog,
  );
  const dialog = clientFoundDialog as AppDialogProps & { item: IClient };

  const clientData: IClient = useMemo(() => {
    return {
      ...dialog?.item,
    };
  }, [dialog?.item]);

  const onClose = useCallback(() => {
    if (dialog?.onClose) {
      dialog.onClose();
    }

    dispatch(closeClientFoundDialog());
  }, [dialog?.onClose, dispatch]);

  const onConfirm = useCallback(() => {
    if (dialog?.onConfirm) {
      dialog.onConfirm();
    }

    dispatch(closeClientFoundDialog());
  }, [dialog?.onConfirm, dispatch]);

  return (
    <Modal isOpen={!!dialog} onClose={onClose} isCentered>
      <ModalOverlay bg={'#000000d3'} />
      <ModalContent
        borderRadius={'2xl'}
        overflow="hidden"
        maxW={'340px'}
        mx={2}
        pt={'20px'}
        borderColor="orange.400"
        borderWidth={1}>
        <Flex
          px={'22px'}
          py="10px"
          borderColor={'orange.400'}
          align="center"
          pb={6}
          gap="24px">
          <AuthImage
            isAvatar
            w="60px"
            h="60px"
            path={clientData?.media?.[0]?.url}
            name={`${clientData?.firstName || ''} ${
              clientData?.lastName || ''
            }`}
          />
          <Flex gap="8px" flexDirection="column">
            <Text variant="clientName" noOfLines={1}>
              {clientData?.firstName || ''} {clientData?.lastName || ''}
            </Text>
            <Text variant="clientDetailUnderline">{clientData?.email}</Text>
          </Flex>
          <Flex align="center" ms="auto" />
        </Flex>

        <Flex w="100%" flex={1} position="relative">
          <Flex overflow="hidden" w="100%">
            <Flex
              pb="20px"
              direction="column"
              w="100%"
              boxSizing="border-box"
              gap={4}
              px={'20px'}>
              <Divider />
              <ClientInfo client={clientData} />
            </Flex>
          </Flex>
        </Flex>
        <VStack
          bg={'orange.400'}
          py={4}
          px={5}
          gap={{ sm: 2, md: 0 }}
          align={'center'}>
          <Icon as={IoWarning} color="white" fontSize={'22px'} />
          <VStack align={'center'} spacing={0}>
            <Text color={'white'}>{t('clients.found_client_title')}</Text>
            <Text color="white" fontWeight="bold">
              {t('clients.found_client_question')}
            </Text>
          </VStack>
          <Spacer />
          <HStack gap={'38px'} alignSelf="center">
            <IconButton
              aria-label="approve-merge"
              color="green.300"
              p={1}
              borderRadius={'xl'}
              size="sm"
              onClick={onConfirm}
              as={MdCheck}
            />
            <IconButton
              size="sm"
              borderRadius={'xl'}
              aria-label="clear"
              color={'red.400'}
              as={MdClear}
              onClick={onClose}
              p={1}
            />
          </HStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
