import { AxiosInstance, AxiosResponse } from 'axios';
import { Business, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class BusinessService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createBusiness(businessRequest: Business): Promise<Business> {
    const response: AxiosResponse<Business> = await this.api.post(
      '/businesses',
      businessRequest,
    );
    return response.data;
  }

  public async getBusinesses<T = Business>(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<T>> {
    const response: AxiosResponse<PaginatedResults<T>> = await this.api.get(
      '/businesses',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getBusiness<T = Business>(
    businessId: string,
    queryParams?: Record<string, any>,
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.api.get(
      `/businesses/${businessId}`,
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async updateBusiness(
    businessId: string,
    updates: Partial<Business>,
  ): Promise<Business> {
    const response: AxiosResponse<Business> = await this.api.patch(
      `/businesses/${businessId}`,
      updates,
    );
    return response.data;
  }

  public async deleteBusiness(businessId: string): Promise<void> {
    await this.api.delete(`/businesses/${businessId}`);
  }
}

const _businessService = new BusinessService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _businessService;
