import { AxiosInstance, AxiosResponse } from 'axios';
import { CalendarEvent, CalendarEventWithoutType, IEvent, IMeeting, SearchTermResult } from './@types';
import { createAxiosInstance } from './axios.util';

class CalendarEventsService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createCalendarEvents(
    calendar: CalendarEvent,
  ): Promise<CalendarEvent> {
    const response: AxiosResponse<CalendarEvent> = await this.api.post(
      '/calendarEvents',
      calendar,
    );
    return response.data;
  }

  public async getCalendarEvents(
    calendarId?: string,
    queryParams?: Record<string, any>,
  ): Promise<{
    filter: Record<string, any>;
    data: CalendarEvent[];
    deletedExternalCalendarEvents?: IEvent[];
    deletedExternalCalendarMeetings?: IMeeting[];
  }> {
    console.log(`#getCalendarEvents ->`, queryParams);
    try {
      const response: AxiosResponse<{
        filter: Record<string, any>;
        data: CalendarEvent[];
      }> = await this.api.get(
        `/calendarEvents${calendarId ? `/${calendarId}` : ''}`,
        {
          params: queryParams,
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching calendar events: ', error);
      return {
        filter: {},
        data: [],
        deletedExternalCalendarEvents: [],
        deletedExternalCalendarMeetings: [],
      };
    }
  }

  public async getCalendarEventsByIntegrationId(
    integrationId: string,
    queryParams?: Record<string, any>,
  ): Promise<{ filter: Record<string, any>; data: CalendarEvent[] }> {
    try {
      const response: AxiosResponse<{
        filter: Record<string, any>;
        data: CalendarEvent[];
      }> = await this.api.get(`/calendarEvents/integration/${integrationId}`, {
        params: queryParams,
      });
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching calendar events by integration id: ',
        error,
      );
      return { filter: {}, data: [] };
    }
  }

  public async getCalendarEvent(calendarId: string): Promise<CalendarEvent> {
    const response: AxiosResponse<CalendarEvent> = await this.api.get(
      `/calendarEvents/${calendarId}`,
    );
    return response.data;
  }

  public async updateCalendarEvent(
    calendarId: string,
    updates: Partial<CalendarEvent>,
  ): Promise<CalendarEvent> {
    const response: AxiosResponse<CalendarEvent> = await this.api.patch(
      `/calendarEvents/${calendarId}`,
      updates,
    );
    return response.data;
  }

  public async searchCalendarEvents(
    searchTerm: string,
  ): Promise<SearchTermResult<CalendarEventWithoutType>> {
    const response: AxiosResponse<SearchTermResult<CalendarEventWithoutType>> =
      await this.api.get(`/calendarEvents/search`, {
        params: { term: searchTerm },
      });
    return response.data;
  }

  public async deleteCalendarEvent(calendarId: string): Promise<void> {
    await this.api.delete(`/calendarEvents/${calendarId}`);
  }
}

const _calendarEventsService = new CalendarEventsService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _calendarEventsService;
