import { HStack, Text, VStack } from '@chakra-ui/react';

import { IQuoteProductListItem, Product } from 'services/@types';

interface QuoteItemProps {
  // number: string;
  // title: string;
  // totalPrice: string;
  // items: { name: string; price: string }[];
  number: string | number;
  product: IQuoteProductListItem & { populatedProduct: Product };
  currency: string;
}

export const QuoteItem = ({
  currency,
  product: {
    productId,
    quantity,
    description,
    isVatIncluded,
    amount,
    net,
    populatedProduct,
  },
  number,
}: QuoteItemProps) => {
  return (
    <HStack
      spacing="0"
      w="full"
      alignItems="flex-start"
      letterSpacing="-0.02em">
      <Text fontSize="16px" fontWeight="bold" color="black" w="40px">
        {number}
      </Text>
      <VStack flex={1} align="flex-start" w="124px" gap={1} spacing={0}>
        <Text fontSize="16px" fontWeight="bold" color="black">
          {populatedProduct?.name}
        </Text>
        {populatedProduct?.subItems.map(
          (subProduct: string | Product, index: number) => (
            <Text
              key={index}
              mt={index === 0 ? 4 : 0}
              color="blackAlpha.600"
              fontSize="14px"
              lineHeight="14px">
              {(subProduct as Product).name}
            </Text>
          ),
        )}
      </VStack>
      <VStack align="flex-start" w="124px" fontSize="14px" gap={1} spacing={0}>
        <Text fontSize="16px" fontWeight="bold" color="black">
          {net} {currency}
        </Text>
        {populatedProduct?.subItems.map(
          (subProduct: string | Product, index: number) => (
            <Text key={index} color="blackAlpha.600">
              {(subProduct as Product).price} {currency}
            </Text>
          ),
        )}
      </VStack>
    </HStack>
  );
};
