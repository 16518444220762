import {
  Box,
  Button,
  HStack,
  Image,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import InviteIcon from 'assets/svg/notification/invite.svg';
import axios from 'axios';
import { updateNotification } from 'contexts/redux/notifications/notificationsSlice';
import { AppDispatch } from 'contexts/redux/store';
import moment from 'moment';
import 'moment/locale/he';
import { createEntityHook } from 'queries/entity';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INotification } from 'services/@types';
import { getNotificationMomentFormat } from './NotificationComponent';

const RequestNotification: React.FC<{
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}> = ({ notification, notificationRef }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { acceptButtonLink, declineButtonLink } = notification;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const entity = createEntityHook(notification?.type);
  const entityQuery: any = entity(notification?.entityId || null, {
    enabled: !!notification?.entityId,
  }).data;

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  const handleOpenQuoteNotice = useCallback(() => {
    toast({
      position: 'top-right',
      duration: 5000,
      render: () => (
        <VStack
          p={2}
          w="100%"
          pe={4}
          maxW="500px"
          spacing={0}
          borderRadius="xl"
          align="flex-start"
          justify="flex-start"
          bg="brand.200"
          color="primary.100">
          <HStack spacing={2} justify="flex-start" align="center" w="100%">
            <VStack spacing={0} align="flex-start" justify="flex-start">
              <Text fontWeight="500" noOfLines={1} color="white">
                {t('notifications_content.quote_request_accepted.title')}
              </Text>
            </VStack>
            <Spacer />

            <Box>
              <Button
                aria-label="undo"
                variant="h3white"
                onClick={() => {
                  navigate(
                    `/main/finance/quotations/${notification?.entityId}`,
                  );
                }}>
                <Text fontWeight="500">
                  {t(
                    'notifications_content.quote_request_accepted.button_text',
                  )}
                </Text>
              </Button>
            </Box>
          </HStack>
        </VStack>
      ),
    });
  }, [navigate, t, toast, notification?.entityId]);

  const handleAccept = useCallback(() => {
    if (!acceptButtonLink) return;
    axios.get(acceptButtonLink).then(() => {
      entity.invalidate(queryClient, notification?.entityId);
    });
    dispatch(
      updateNotification({
        notificationId: notification.id,
        updates: { visible: false },
      }),
    );
    if (notification?.type === 'quote') {
      handleOpenQuoteNotice();
    }
  }, [
    acceptButtonLink,
    handleOpenQuoteNotice,
    notification?.type,
    dispatch,
    notification.id,
    notification?.entityId,
    entity,
    queryClient,
  ]);

  const handleDecline = useCallback(() => {
    if (!declineButtonLink) return;
    axios.get(declineButtonLink).then(() => {
      entity.invalidate(queryClient, notification?.entityId);
    });
    dispatch(
      updateNotification({
        notificationId: notification.id,
        updates: { visible: false },
      }),
    );
  }, [
    declineButtonLink,
    dispatch,
    notification.id,
    notification?.entityId,
    entity,
    queryClient,
  ]);

  const isAnswered = useMemo(() => {
    return entityQuery?.status !== 'pending';
  }, [entityQuery?.status]);

  return (
    <Box
      p={4}
      ref={notificationRef}
      // bg="white"
      border="1px solid #F9FAFB"
      // sx={{ backgroundColor: '#93C3B31A' }}
      borderRadius="12px">
      <VStack align="stretch" spacing={4}>
        <HStack gap="12px">
          <Image w="23px" h="13px" src={InviteIcon} />
          <Text variant="cardSubTitle" color="#1B2037">
            {t(notification.message, {
              name: notification.variables?.name,
              date: moment(notification.variables?.date).format(
                'DD MMM,  HH:mm',
              ),
            })}
            {/* New meeting invite from{' '}
            <span style={{ fontWeight: 700 }}>Emily</span> on */}
          </Text>
        </HStack>
        {/* <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Text>12:00 - 13:00 Team meeting</Text>
          <Flex alignItems="center" gap="2.75px">
            <Icon as={IoLocationOutline} color="general.100" />
            <Text variant="cardSectionTitle">riding 36, tel-aviv</Text>
          </Flex>
        </Flex> */}
        {isAnswered && <Text color="general.100">{entityQuery?.status}</Text>}
        {!isAnswered && (
          <HStack justify="space-between">
            <Button
              variant="h1cta"
              onClick={() => {
                // window.open(acceptButtonLink, '_blank');
                handleAccept();
              }}
              flex={1}>
              {/* Accept */}
              {t(notification.acceptButtonText)}
            </Button>
            <Button
              variant="h2outlined"
              onClick={() => {
                // window.open(declineButtonLink, '_blank');
                handleDecline();
              }}
              flex={1}>
              {/* Decline */}
              {t(notification.declineButtonText)}
            </Button>
          </HStack>
        )}
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {moment(notification.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Box>
  );
};

export default RequestNotification;
