import {
  Icon,
  MenuItem,
  MenuList,
  Text,
  Menu,
  MenuButton,
  Flex,
  Divider,
  VStack,
  Image,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FiUpload } from 'react-icons/fi';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import useClientDocuments from '../hooks/useClientDocuments';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import NoEventsImg from 'assets/svg/event/not-found.svg';

export default function ClientDocuments({
  additionalProps,
}: {
  additionalProps?: any;
}) {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('client_documents');
  const {
    mediaData,
    handleOpenAddEventClientDocumentDialog,
    handleDeleteMedia,
    handleDownloadMedia,
  } = useClientDocuments();

  return (
    <Card
      ref={ref}
      {...style}
      minH={{ base: 'fit-content', lg: '300px' }}
      minW={{ base: '100%', lg: '310px' }}
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px"
      spacing={0}
      {...additionalProps}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">
          {t('event.client_documents.title')}
        </Text>
        <Menu>
          <MenuButton>
            <Icon as={MdOutlineMoreHoriz} color="#93C3B3" w="20px" h="20px" />
          </MenuButton>
          <MenuList
            w="176px"
            h="72px"
            borderRadius="24px"
            p="0"
            overflow="hidden">
            <MenuItem
              gap="13px"
              justifyContent="center"
              alignItems="center"
              onClick={handleOpenAddEventClientDocumentDialog}
              h="100%">
              <Icon as={FiUpload} color="#FF554A" w="25px" h="25px" />
              <Text variant="lineUpItemDelete">
                {t('event.client_documents.upload_document')}
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex
        flexDirection="column"
        display={mediaData?.results?.length > 0 ? 'flex' : 'none'}
        gap="10px"
        overflow="auto">
        {mediaData?.results?.map((media, index) => (
          <Flex flexDirection="column" gap="10px" key={media.id}>
            <Flex
              flex="1"
              h="38px"
              alignItems="center"
              justifyContent="space-between">
              <Flex
                flexDirection="column"
                cursor="pointer"
                onClick={() => handleDownloadMedia(media)}>
                <Text variant="lineUpItemFileName" noOfLines={1} maxW="200px">
                  {media?.media?.[0]?.displayName}
                </Text>
                <Text variant="previewQuoteProductHeader" textTransform="none">
                  {media?.createdAt
                    ? format(new Date(media.createdAt), 'd MMM, yyyy')
                    : ''}
                </Text>
              </Flex>
              <Menu>
                <MenuButton>
                  <Icon
                    as={MdOutlineMoreHoriz}
                    color="#93C3B3"
                    w="20px"
                    h="20px"
                  />
                </MenuButton>
                <MenuList
                  w="95px"
                  h="69px"
                  minH="69px"
                  minW="95px"
                  borderRadius="24px"
                  p="0"
                  overflow="hidden">
                  <MenuItem
                    gap="13px"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => handleDeleteMedia(media.id)}
                    h="100%">
                    <Text variant="lineUpItemDelete">
                      {t('event.client_documents.delete')}
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Divider
              bg="#E1E1E1"
              display={
                index === mediaData?.results?.length - 1 ? 'none' : 'block'
              }
            />
          </Flex>
        ))}
      </Flex>
      {mediaData?.results?.length === 0 && (
        <VStack
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          gap="24px"
          fontWeight={500}
          textAlign="center">
          <Image src={NoEventsImg} w="180px" h="180px" alt="No Client Documents" />
          <Text color="#00000033" fontSize="24px" lineHeight="26px">
            {t('event.no_data_found')}
          </Text>
          <Text color="#00000033" fontSize="14px" lineHeight="18px">
            {t('event.no_data_message')}
          </Text>
        </VStack>
      )}
    </Card>
  );
}
