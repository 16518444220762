import { HStack, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { CalendarEventWithoutType } from 'services/@types';

type CalendarSearchItemProps = {
  calendarEvent: CalendarEventWithoutType;
};

const CalendarSearchItem = ({ calendarEvent }: CalendarSearchItemProps) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const itemColor =
    calendarEvent.category === 'meeting' ? '#7BA395' : '#AA915D';

  const navigateTo = useCallback(() => {
    if (!calendarEvent?.data?.id) return;
    if (calendarEvent.category === 'holiday') return;
    navigate(
      calendarEvent.category === 'event'
        ? `/main/event/${calendarEvent.data.id}`
        : `/main/meetings/update/${calendarEvent.data.id}`,
    );
  }, [navigate, calendarEvent]);
  return (
    <VStack
      gap={1}
      cursor={'pointer'}
      w="100%"
      p={'8px 12px'}
      borderRadius="16px"
      border={`1px solid ${itemColor}`}
      color={itemColor}
      letterSpacing={'-0.02em'}
      fontSize="14px"
      onClick={() => navigateTo()}
      fontWeight="400">
      {/* top */}
      <HStack w="100%" justifyContent={'space-between'} alignItems="center">
        {/* title */}
        <HStack gap={1} w="100%" overflow={'hidden'} flex={1}>
          <Text color={'#000000'} isTruncated title={calendarEvent.summary}>
            {calendarEvent.summary}
          </Text>
        </HStack>
        {/* time */}
        <Text fontWeight={'500'}>
          {moment(calendarEvent.start).format('hh:mm')} -{' '}
          {moment(calendarEvent.end).format('hh:mm')}
        </Text>
      </HStack>
      {/* bottom */}
      <HStack w="100%" justifyContent={'space-between'} alignItems="center">
        {/* location */}
        <HStack gap={1} w="100%" overflow={'hidden'} flex={1}>
          <HiOutlineLocationMarker />
          <Text
            marginLeft={'0 !important'}
            textTransform={'capitalize'}
            fontWeight={'500'}
            isTruncated
            textOverflow={'ellipsis'}
            title={calendarEvent.location}>
            {calendarEvent.location}
          </Text>
        </HStack>
        {/* date */}
        <Text fontWeight={'500'}>
          {moment(calendarEvent.start).locale(i18n.language).calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'DD MMM',
            lastDay: '[Yesterday]',
            lastWeek: 'DD MMM',
            sameElse: 'DD MMM',
          })}
        </Text>
      </HStack>
    </VStack>
  );
};

export default CalendarSearchItem;
