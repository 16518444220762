import { Flex, HStack, Icon, IconButton, Input, Text } from '@chakra-ui/react';
import AssingAutocompleteField from 'components/AssignAutocompleteField/index';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useTranslation } from 'react-i18next';
import { FiPaperclip, FiPlus, FiX } from 'react-icons/fi';
import { IContract } from 'services/@types';
import Product from './Product';

interface ICreateContractProductsProps {
  contract: IContract;
  products: { value: string; label: string }[];
  handleAddProduct: () => void;
  handleRemoveProduct: (index: number) => void;
  handleProductDescriptionSelected: (id: string, index: number) => void;
  handleProductChange: ({
    name,
    value,
    index,
  }: {
    name: string;
    value: number;
    index: number;
  }) => void;
  handleProductVatChange: (index: number) => void;
  handleChange: (path: string, value: any) => void;
  currencySymbol: string;
  displayingSentTo: any[];
  handleSelectSentTo: (value: string) => void;
  currentSentTo: string;
  handleChangeCurrentSentTo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveSentToItem: (value: string) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleAddFile: (event: any) => void;
  clearFile: (type: 'document' | 'quote') => void;
}
export default function CreateContractProducts(
  props: ICreateContractProductsProps,
) {
  const {
    contract,
    products,
    handleAddProduct,
    handleRemoveProduct,
    handleProductDescriptionSelected,
    handleProductChange,
    handleProductVatChange,
    handleChange,
    currencySymbol,
    displayingSentTo,
    handleSelectSentTo,
    currentSentTo,
    handleChangeCurrentSentTo,
    handleRemoveSentToItem,
    fileInputRef,
    handleAddFile,
    clearFile,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <Flex flexDirection="column" gap="24px">
      <Text variant="createQuoteTitle">{t('create_contract.products')}</Text>
      {contract?.products.map((product, index) => (
        <Product
          key={index}
          product={product}
          index={index}
          products={products}
          handleRemoveProduct={handleRemoveProduct}
          handleProductDescriptionSelected={handleProductDescriptionSelected}
          handleProductChange={handleProductChange}
          handleProductVatChange={handleProductVatChange}
          currencySymbol={currencySymbol}
        />
      ))}
      <HStack>
        <IconButton
          borderRadius="10px"
          icon={<Icon as={FiPlus} color="white" w="30px" h="30px" />}
          onClick={handleAddProduct}
          aria-label="add"
          w="33px"
          h="33px"
          minW="33px"
          bg="#93C3B3"
        />
        <Text variant="addRowText">{t('create_contract.add_row')}</Text>
      </HStack>
      <TextField
        label={t('create_contract.comment')}
        placeholder={t('create_contract.comment_placeholder')}
        value={contract?.comment}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange('comment', e.target.value);
        }}
        labelProps={{ mb: '12px' }}
        w="100%"
        minH={203}
        maxLength={2000}
        iconPointerEvents="auto"
        iconLeft={
          <Flex
            marginLeft={i18n.dir() === 'rtl' ? '0px' : '20px'}
            marginRight={i18n.dir() === 'rtl' ? '20px' : '0px'}
            display={
              !contract?.media?.find((m) => m.type === 'document')
                ? 'flex'
                : 'none'
            }
            alignItems="center">
            <>
              <IconButton
                aria-label=""
                icon={<Icon as={FiPaperclip} color="white" />}
                bg="#93C3B3"
                borderRadius="10px"
                w="33px"
                h="33px"
                minW="33px"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              />
              <Input
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                display="none"
                onChange={handleAddFile}
                ref={fileInputRef}
              />
            </>
          </Flex>
        }
      />
      {contract?.media?.find((m) => m.type === 'document') && (
        <Flex alignItems="center" gap="8px">
          <Text variant="contractFileText">
            {contract?.media?.find((m) => m.type === 'document')?.displayName}
          </Text>
          <IconButton
            icon={<Icon as={FiX} color="#939393" />}
            aria-label="delete"
            onClick={() => {
              clearFile('document');
            }}
          />
        </Flex>
      )}
      {/* <AssingAutocompleteField
        label={t('create_contract.send_contract_to')}
        placeholder={t('create_contract.send_contract_to_placeholder')}
        w="100%"
        options={displayingSentTo}
        onSelected={handleSelectSentTo}
        selectedOptions={contract?.sentTo || []}
        value={currentSentTo}
        onChange={handleChangeCurrentSentTo}
        handleRemove={handleRemoveSentToItem}
      /> */}
      <InputField
        label={t('create_contract.send_contract_to')}
        placeholder={t('create_contract.send_contract_to_placeholder')}
        w="100%"
        value={contract?.sentTo}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange('sentTo', e.target.value);
        }}
      />
      <InputField
        label={t('create_contract.legal_text')}
        placeholder={t('create_contract.legal_text_placeholder')}
        tooltipTopLabel={t('create_contract.legal_text_tooltip')}
        w="100%"
        value={contract?.legalText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange('legalText', e.target.value);
        }}
      />
    </Flex>
  );
}
