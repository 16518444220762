import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { createInvite } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IClient } from 'services/@types';

export default function useInviteClient({ client }: { client: IClient }) {
  const { invites } = useSelector((state: RootState) => state.invites);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const myBusiness = useMyBusiness();
  const myUser = useMyUser();

  const isInvited = useMemo(() => {
    return !!invites?.find(
      (invite) =>
        invite?.email === client.email &&
        invite?.type === 'client' &&
        invite?.businessID === myBusiness?.id &&
        invite?.senderID === myUser?.id,
    );
  }, [invites, client.email, myBusiness?.id, myUser?.id]);

  const handleInviteToBrilliant = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setLoading(true);
      const response = await dispatch(
        createInvite({
          email: client.email,
          businessID: myBusiness?.id,
          type: 'client',
          fullName: `${client.firstName || ''} ${client.lastName || ''}`,
        }),
      );
      if (!response?.error) {
        setLoading(false);
      }
    },
    [client, dispatch, myBusiness?.id],
  );

  return { isInvited, handleInviteToBrilliant, loading };
}
