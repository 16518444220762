import { Flex, HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  selectSelectedDate,
  setSelectedDate,
} from 'contexts/redux/createMeeting/createMeetingSlice';
import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useMyUserPreferences } from 'contexts/redux/userPreferences/userPreferencesSlice';
import { fetchCalendars } from 'contexts/redux/calendar/calendarsSlice';
import { updateViewedCalendars } from 'contexts/redux/calendarEvent/calendarEventSlice';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './custom.css';

require('moment/locale/he');

export const MeetingAgenda = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const selectedDate = useSelector(selectSelectedDate);
  const localizer = momentLocalizer(moment);
  const { activeCalendars } = useMyUserPreferences();
  const { calendarEvents } = useSelector(
    (state: RootState) => state.calendarEvent,
  );

  // Format events for display
  const displayedEvents = useMemo(() => {
    const startOfDay = moment(selectedDate.start).startOf('day');
    const endOfDay = moment(selectedDate.start).endOf('day');

    return calendarEvents
      .filter((event) => {
        const eventStart = moment(event.start);
        const eventEnd = moment(event.end);
        return (
          (eventStart.isSame(startOfDay, 'day') ||
            eventStart.isAfter(startOfDay)) &&
          (eventEnd.isSame(endOfDay, 'day') || eventEnd.isBefore(endOfDay))
        );
      })
      .map((event) => ({
        ...event,
        title: event?.summary,
      }));
  }, [calendarEvents, selectedDate]);

  const handleNextDay = useCallback(() => {
    const nextDay = moment(selectedDate.start).add(1, 'day').toDate();
    dispatch(
      setSelectedDate({
        start: nextDay,
        end: moment(nextDay).add(1, 'hour').toDate(),
      }),
    );
  }, [selectedDate, dispatch]);

  const handlePrevDay = useCallback(() => {
    const prevDay = moment(selectedDate.start).subtract(1, 'day').toDate();
    dispatch(
      setSelectedDate({
        start: prevDay,
        end: moment(prevDay).add(1, 'hour').toDate(),
      }),
    );
  }, [selectedDate, dispatch]);

  const handleSelectSlot = useCallback(
    (slotInfo: SlotInfo) => {
      // When a slot is selected, we want to:
      // 1. Set the start time to the selected slot's start time
      // 2. Set the end time to 1 hour after the start time
      const startDate = moment(slotInfo.start).toDate();
      const endDate = moment(slotInfo.end).toDate();
      console.log('handle select slot ->', startDate, endDate);
      dispatch(
        setSelectedDate({
          start: startDate,
          end: endDate,
        }),
      );
    },
    [dispatch],
  );

  const getDayProp = useCallback(
    (date: Date) => {
      return {
        style: {
          backgroundColor: moment(date).isSame(selectedDate.start, 'day')
            ? '#00000011'
            : 'transparent',
        },
      };
    },
    [selectedDate],
  );

  // Fetch calendars and events
  useEffect(() => {
    dispatch(fetchCalendars({}));
  }, [dispatch]);

  useEffect(() => {
    if (activeCalendars && selectedDate) {
      dispatch(
        updateViewedCalendars({
          calendars: activeCalendars,
          currentDate: selectedDate.start,
          calendarViewType: 'day',
        }),
      );
    }
  }, [activeCalendars, dispatch, selectedDate]);

  return (
    <VStack w={{ base: '100%', md: '40%' }} align="center" p={4} spacing={4}>
      <HStack w="100%" justify="center" spacing="12px">
        <IconButton
          size="xs"
          variant="ghost"
          aria-label="previous-day"
          icon={<Icon as={BiChevronLeft} h="24px" w="24px" />}
          transform={`${i18n.dir() === 'rtl' ? 'rotate(180deg)' : ''}`}
          onClick={handlePrevDay}
        />
        <Flex flex={1} justify="center" align="center">
          <Text minW={200} textAlign="center" fontSize="2xl" fontWeight="bold">
            {moment(selectedDate.start)
              .locale(i18n.language)
              .format('ddd, MMMM D')}
          </Text>
        </Flex>
        <IconButton
          size="xs"
          aria-label="next-day"
          variant="ghost"
          icon={<Icon as={BiChevronRight} h="24px" w="24px" />}
          transform={`${i18n.dir() === 'rtl' ? 'rotate(180deg)' : ''}`}
          onClick={handleNextDay}
        />
      </HStack>

      <Calendar
        popup
        localizer={localizer}
        date={selectedDate.start}
        view="day"
        views={['day']}
        step={120}
        timeslots={1}
        culture={i18n.language}
        rtl={i18n.dir() === 'rtl'}
        onNavigate={(date) => {
          dispatch(
            setSelectedDate({
              start: date,
              end: moment(date).add(1, 'hour').toDate(),
            }),
          );
        }}
        selectable={true}
        dayPropGetter={getDayProp}
        toolbar={false}
        min={moment(selectedDate.start).startOf('day').add(0, 'hours').toDate()} // Start at 6 AM
        max={moment(selectedDate.start)
          .startOf('day')
          .add(23, 'hours')
          .add(59, 'minutes')
          .toDate()} // End at 11 PM
        onSelectSlot={handleSelectSlot}
        events={displayedEvents}
        eventPropGetter={(event) => {
          let backgroundColor = '#AA915D';

          if (event?.color) {
            backgroundColor = event.color;
          } else if (event?.category === 'meeting') {
            backgroundColor = '#AA915D';
          } else if (event?.category === 'event') {
            backgroundColor = '#93C3B3';
          }
          return {
            style: {
              backgroundColor,
              color: 'white',
              fontSize: '12px',
              paddingTop: '0px',
              paddingBottom: '0px',
              borderRadius: '5px',
            },
          };
        }}
        style={{ width: '100%' }}
        defaultView="day"
      />
    </VStack>
  );
};
