import {
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
// Assets
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import InputField from 'components/fields/InputField';
import TagsField from 'components/fields/TagsField';
import TextField from 'components/fields/TextField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import {
  createNewProductCategory,
  deleteProduct,
  updateProduct,
} from 'contexts/redux/product/productSlice';
import { RootState } from 'contexts/redux/store';
import { useAutocompleteItems } from 'hooks/useAutocompleteItems';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from 'services/@types';
import { PackageContentSection } from './PackageContentSection';
export default function ProductDetails(props: {
  status: string;
  name: string;
  onEditProduct: (product: Product) => void;
  onDeleteProduct: (product: Product) => void;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  [x: string]: any;
}) {
  const { status, onEditProduct, name, isEditMode, setIsEditMode, ...rest } =
    props;

  // Chakra Color Mode
  const { t } = useTranslation();
  const business = useMyBusiness();
  const dispatch = useDispatch<any>();
  const { currentProduct: product, productCategories } = useSelector(
    (state: RootState) => state.product,
  );
  const autoCompletedItems = useMemo(() => {
    return productCategories.map((p) => ({
      value: p.id,
      label:
        p.name === 'general_category' ? t('products.general_category') : p.name,
    }));
  }, [productCategories, t]);
  const {
    setNewItemInputValue,
    displayingOptions,
    newItemInputValue: newProductCategoryInputValue,
    selectedItem: selectedProductCategory,
    handleOnSelected,
  } = useAutocompleteItems(autoCompletedItems);

  console.log('ProductDetails', product);
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const [productProps, setProductProps] = useState<Partial<Product>>({});
  useEffect(() => {
    setProductProps({ ...product });
    if (product?.name === 'your_first_product') {
      setProductProps({
        ...product,
        name: t('products.your_first_product_name'),
      });
    }
  }, [product, t]);
  const handleChange = useCallback(
    (e: any) => {
      setProductProps({ ...productProps, [e.target.name]: e.target.value });
    },
    [productProps],
  );
  useEffect(() => {
    if (selectedProductCategory) {
      setProductProps({ ...productProps, category: selectedProductCategory });
    }
  }, [productProps, selectedProductCategory]);
  const handleSave = useCallback(() => {
    console.log(
      'handleSave',
      newProductCategoryInputValue,
      selectedProductCategory,
    );
    const existingProductCategory = productCategories.find(
      (p) => p.name === newProductCategoryInputValue,
    );
    if (newProductCategoryInputValue && !existingProductCategory) {
      // no category selected, generate new one if input is not empty
      dispatch(
        openAlertDialog({
          title: t('products.dialog.new_category.title'),
          content: t('products.dialog.new_category.description', {
            name: newProductCategoryInputValue,
          }),
          onConfirm: async () => {
            const { payload } = await dispatch(
              createNewProductCategory({
                businessID: business.id,
                categoryName: newProductCategoryInputValue,
              }),
            );

            await dispatch(
              updateProduct({
                productId: product.id,
                updates: { ...productProps, category: payload.id },
              }),
            );
            dispatch(closeAlertDialog());
            setIsEditMode(!isEditMode);
          },
        }),
      );
      return;
    }
    dispatch(updateProduct({ productId: product.id, updates: productProps }));
    setIsEditMode(!isEditMode);
  }, [
    business,
    dispatch,
    isEditMode,
    newProductCategoryInputValue,
    product,
    productCategories,
    productProps,
    selectedProductCategory,
    t,
  ]);

  const placeholderCategoryName = useMemo(() => {
    const cName = productCategories?.find(
      (p) => p.id === product?.category,
    )?.name;
    if (cName === 'general_category') {
      return t('products.general_category');
    } else {
      return (
        cName || t('products.product_details.product_category_placeholder')
      );
    }
  }, [product?.category, productCategories, t]);

  const handleDeleteProduct = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('products.dialog.delete.title'),
        content: t('products.dialog.delete.description', {
          name: product.name,
        }),
        onConfirm: () => dispatch(deleteProduct(product.id)),
      }),
    );
  }, [dispatch, product?.id, product?.name, t]);
  return (
    <Flex flexDirection="column" {...rest} role="group" px={'18px'} gap={4}>
      <Flex borderColor={borderColor} align="start" gap={{ sm: 2, md: 4 }}>
        <Flex gap={0} flexDirection="column" ps={{ base: 0, sm: 6, md: 0 }}>
          <Text fontSize={{ base: 'sm', md: 'xl' }} fontWeight="700">
            {t('products.product_details.product_information')}
          </Text>
          <Text fontSize={{ base: 'xs', md: 'md' }}>
            {t('products.product_details.product_info_description')}
          </Text>
        </Flex>
        <Spacer />
        <IconButton
          aria-label="edit"
          variant="actionIconBlue"
          p={2}
          as={MdEdit}
          onClick={() => setIsEditMode(!isEditMode)}
          display={isEditMode ? 'none' : 'flex'}
        />
      </Flex>

      <Flex w="100%" wrap="wrap" flex={1} gap={10}>
        <VStack w="100%" minW={200} maxW={{ base: '100%', md: 300 }}>
          <InputField
            w="100%"
            name="name"
            value={productProps?.name}
            disabled={!isEditMode}
            onChange={handleChange}
            label={t('products.product_details.product_name')}
          />

          <InputAutocompleteField
            flex={1}
            label={t('products.product_details.product_category')}
            w="100%"
            disabled={!isEditMode}
            maxW={{ base: '100%', md: 320 }}
            id="category"
            placeholder={placeholderCategoryName}
            value={newProductCategoryInputValue}
            onChange={(e) => setNewItemInputValue(e.target.value)}
            options={displayingOptions}
            onSelected={handleOnSelected}
            selectedOptions={[]}
          />
          <InputField
            w="100%"
            name="brand"
            value={productProps?.brand}
            onChange={handleChange}
            disabled={!isEditMode}
            label={t('products.product_details.brand_supplier')}
          />
        </VStack>
        <VStack w="100%" minW={200} flex={1} align="start">
          <TextField
            name="description"
            value={productProps?.description}
            w="100%"
            minH={220}
            maxLength={500}
            maxW={{ base: '100%', md: 320 }}
            disabled={!isEditMode}
            label={t('products.product_details.product_description')}
            onChange={handleChange}
            placeholder={t('products.product_details.product_description')}
          />
        </VStack>
      </Flex>
      <Divider />
      <Flex w="100%" display={product?.subItems?.length > 0 ? 'flex' : 'none'}>
        <PackageContentSection product={product} isEditMode={isEditMode} />
      </Flex>
      <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="700">
        {t('products.product_details.pricing_details')}
      </Text>
      <Flex align="start" gap={10} wrap="wrap">
        <VStack w="100%" minW={100} maxW={{ base: '100%', md: 300 }}>
          <InputField
            w="100%"
            name="price"
            value={productProps?.price}
            onChange={handleChange}
            disabled={!isEditMode}
            placeholder={t('products.product_details.price_for_client')}
            label={t('products.product_details.price_for_client')}
          />
          <InputField
            w="100%"
            name="productCost"
            value={productProps?.productCost}
            onChange={handleChange}
            disabled={!isEditMode}
            placeholder={t('products.product_details.product_cost')}
            label={t('products.product_details.product_cost')}
          />
          <TagsField
            placeholderTags={productProps?.tags}
            disabled={!isEditMode}
            onTagsChange={(tags: string[]) => {
              setProductProps({
                ...productProps,
                tags: tags.filter((tag) => tag.length > 0),
              });
            }}
            w="100%"
            label={t('the_office.add_seat_modal.tags')}
          />
        </VStack>
        <VStack w="100%" minW={200} minH={20} maxW={{ base: '100%', md: 300 }}>
          <InputField
            w="100%"
            name="uniqueCode"
            value={productProps?.uniqueCode}
            onChange={handleChange}
            placeholder={t('products.product_details.unique_code')}
            disabled={!isEditMode}
            label={t('products.product_details.unique_code')}
          />
          <InputField
            w="100%"
            name="minPrice"
            value={productProps?.minPrice}
            onChange={handleChange}
            disabled={!isEditMode}
            placeholder={t('products.product_details.minimum_sale_price')}
            label={t('products.product_details.minimum_sale_price')}
          />
        </VStack>
      </Flex>
      <HStack
        alignSelf={'end'}
        w="100%"
        justify="space-between"
        gap={4}
        flexDirection={{ base: 'column-reverse', md: 'row' }}>
        <Spacer />
        <Button
          variant="underlineRed"
          w="fit-content"
          alignSelf="center"
          sx={{ width: '100%' }}
          onClick={() => handleDeleteProduct()}>
          {t('products.actions.delete')}
        </Button>
        <Button
          variant="h1cta"
          px={10}
          w="fit-content"
          alignSelf="center"
          onClick={handleSave}
          display={isEditMode ? 'flex' : 'none'}>
          {t('products.product_details.save')}
        </Button>
      </HStack>
    </Flex>
  );
}
