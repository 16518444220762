import {
  HStack,
  Icon,
  Text,
  Button,
  IconButton,
  useOutsideClick,
  useToast,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { deleteCalendar } from 'contexts/redux/calendar/calendarsSlice';
import { updateInvite } from 'contexts/redux/invite/inviteSlice';
import { fetchUser } from 'contexts/redux/user/userSlice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { ExtendedCalendar, Invite, User } from 'services/@types';

type CalendarItemProps = {
  itemData: ExtendedCalendar | Invite;
};

export const CalendarItem = ({ itemData }: CalendarItemProps) => {
  const { isCalendar, data } = useMemo<
    | { isCalendar: true; data: ExtendedCalendar }
    | { isCalendar: false; data: Invite }
  >(() => {
    return 'userId' in itemData
      ? {
          isCalendar: true,
          data: itemData as ExtendedCalendar,
        }
      : {
          isCalendar: false,
          data: itemData as Invite,
        };
  }, [itemData]);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const toast = useToast();
  const { t, i18n } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref,
    handler: () => setShowMenu(false),
  });

  // fetch details for the user
  useEffect(() => {
    const fetchUserDetails = async () => {
      (async () => {
        const res = await dispatch(
          fetchUser(isCalendar === true ? data.userId : data.recipientID),
        );
        if (res.payload) {
          setUserDetails(res.payload);
        }
      })();
    };
    fetchUserDetails();
  }, [data, isCalendar, dispatch]);

  const handleDelete = useCallback(async () => {
    let response;
    if (isCalendar) {
      response = await dispatch(
        deleteCalendar({
          id: data.id,
        }),
      );
    } else {
      response = await dispatch(
        updateInvite({
          userId: isCalendar ? data.inviteId : data.id,
          updates: { status: 'cancelled' },
        }),
      );
    }

    const payload = response.payload as any;
    console.log('#Redux remove calendar response ->', payload);
    // const response = (payload as any).response;
    payload as any;
    if (payload.status === 409) {
      toast({
        title: t(payload.title),
        description: t(payload.content),
        status: 'error',
        variant: 'error',
      });
    } else if (payload.status >= 400) {
      toast({
        title: t('error_messages.400'),
        // description: t('error_messages.400'),
        status: 'error',
        variant: 'error',
      });
    } else {
      toast({
        title: t('invite_seat.invite_cancelled'),
        status: 'success',
        variant: 'main',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    }
  }, [dispatch, isCalendar, data, toast, t]);

  return (
    <HStack w="100%" alignItems="center" bg="white" _hover={{ bg: 'gray.50' }}>
      <HStack flex={1}>
        {isCalendar ? (
          <Icon me={3} as={BsCircleFill} fontSize={12} color={data.color} />
        ) : (
          <AuthImage
            _hover={{ cursor: 'pointer' }}
            name={userDetails?.firstName}
            borderRadius="50%"
            overflow="hidden"
            size="original"
            w={'24px'}
            h={'24px'}
            me={3}
            path={userDetails?.media?.find((m) => m.type === 'avatar')?.url}
            id={userDetails?.id}
            isAvatar
          />
        )}
        <Text
          fontSize={'sm'}
          marginInlineStart={'0px !important'}
          letterSpacing={'-0.02em'}
          color="#1B2037">
          {userDetails
            ? `${userDetails.firstName} ${userDetails.lastName}`
            : 'nameeee'}
        </Text>
      </HStack>
      <HStack ref={ref} pos="relative">
        <IconButton
          aria-label="Show menu"
          size={'xs'}
          variant={'ghost'}
          onClick={() => setShowMenu(true)}
          icon={
            <Icon as={FiMoreHorizontal} w="20px" h="20px" color="#465D55" />
          }
        />
        {showMenu && (
          <Button
            pos="absolute"
            zIndex={2}
            p={5}
            left={i18n.dir() === 'rtl' ? 0 : null}
            right={i18n.dir() !== 'rtl' ? 0 : null}
            top={'50%'}
            transform="auto"
            translateY={'-50%'}
            bgColor="white"
            padding={'24px'}
            boxShadow={'0px 0px 15px -5px #00000040'}
            borderRadius={'24px'}
            whiteSpace={'nowrap'}
            variant={'ghost'}
            width={'unset'}
            height={'unset'}
            fontWeight={'normal'}
            color={'#999EA9'}
            onClick={() => {
              handleDelete();
              setShowMenu(false);
            }}>
            <Text
              casing={'capitalize'}
              color="#999EA9"
              letterSpacing={'-0.02em'}>
              {isCalendar
                ? t('shared_calendars.delete')
                : t('shared_calendars.stop_sharing')}
            </Text>
          </Button>
        )}
      </HStack>
    </HStack>
  );
};
