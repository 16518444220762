import {
  Flex,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from 'components/card/Card';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import {
  setNavigateTo,
  setStartingPayloadEvent,
} from 'contexts/redux/createMeeting/createMeetingSlice';
import { useCallback, useMemo } from 'react';
import SuppierMeetingItem from './SuppierMeetingItem';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { IEvent, MeetingWithCalendar } from 'services/@types';

type Props = {
  meetingsAndEventDetails: {
    meetings: MeetingWithCalendar[];
    eventDetails: IEvent;
  };
  isLoading: boolean;
};

const Meetings = ({ meetingsAndEventDetails, isLoading }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { style, ref } = useHighlightAnimation('meetings');

  const handleNavigate = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );

  const { eventDetails, meetings } = useMemo(() => {
    if (meetingsAndEventDetails) {
      return meetingsAndEventDetails;
    }
    return {
      eventDetails: null,
      meetings: [],
    };
  }, [meetingsAndEventDetails]);
  return (
    <Card
      ref={ref}
      overflowY={'auto'}
      flex={1}
      minW={{ base: '100%', lg: '310px' }}
      minH={{ base: 'unset', lg: '535px' }}
      maxH="535px"
      w="100%"
      borderRadius={{ base: 0, md: '30px' }}
      gap="24px"
      {...style}>
      {isLoading ? (
        /* loading overlay */
        <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Flex alignItems="center" justify="space-between">
            <Text variant="eventPageCardTitle">
              {t('event.top_bar_tabs.sub_tabs.meetings')}
            </Text>
            <IconButton
              margin={'0 !important'}
              borderRadius="8px"
              maxWidth="24px"
              height="24px"
              minWidth="24px"
              p="0 !important"
              order={{ base: 1, lg: 0 }}
              aria-label="add-event"
              variant={'actionIconBlue'}
              icon={<Icon as={MdAdd} fontSize="16px" />}
              onClick={() => {
                dispatch(setNavigateTo(`/main/event/${eventDetails.id}`));
                dispatch(setStartingPayloadEvent(eventDetails));
                handleNavigate('/main/meetings/create');
              }}
            />
          </Flex>
          <VStack
            w="100%"
            h="100%"
            p="0px"
            margin="0 !important"
            overflowY={'auto'}
            pb="2"
            spacing={0}
            gap={4}>
            {isLoading ? (
              <Flex
                h="100%"
                w="100%"
                justifyContent="center"
                alignItems="center">
                <Spinner />
              </Flex>
            ) : meetings.length === 0 ? (
              <VStack
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                gap="24px"
                fontWeight={500}
                textAlign="center">
                <Image
                  src={NoEventsImg}
                  w="180px"
                  h="180px"
                  alt="Meeting Not Found"
                />
                <Text color="#00000033" fontSize="24px" lineHeight="26px">
                  {t('event.no_data_found')}
                </Text>
                <Text color="#00000033" fontSize="14px" lineHeight="18px">
                  {t('event.no_data_message')}
                </Text>
              </VStack>
            ) : (
              meetings.map((meeting) => (
                <SuppierMeetingItem meeting={meeting} key={meeting.id} />
              ))
            )}
          </VStack>
        </>
      )}
    </Card>
  );
};

export default Meetings;
