import { Flex, Hide, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { formatNumber } from 'helpers/formatNumber';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ProductService from 'services/product.api';
import { VAT_PERCENTAGE } from 'variables/currency';
import PackageIcon from '../../createQuote/components/PackageIcon';
import PackageProductInfo from '../../createQuote/components/PackageProductInfo';
import { headers, QuoteProduct } from './ProductsList';

interface IProductListItemProps {
  product: QuoteProduct[0];
  isLast: boolean;
  formattedCurrency: string;
}

export default function ProductListItem(props: IProductListItemProps) {
  const { product, isLast, formattedCurrency } = props;
  const { t } = useTranslation();

  const { data: productData } = useQuery({
    queryKey: ['product', product.productId],
    queryFn: () => ProductService.getProduct(product.productId),
    enabled: !!product.productId,
  });

  const isPackage = useMemo(() => {
    return (
      Array.isArray(productData?.subItems) && productData?.subItems?.length > 0
    );
  }, [productData?.subItems]);

  return (
    <>
      <Flex
        py={4}
        px={{ base: 0, md: 6 }}
        gap={{ base: '13px', md: 0 }}
        flexDirection={{ base: 'column', md: 'row' }}
        borderBottom={{
          base: isLast ? 'none' : '1px solid #E9EDF7',
          md: 'none',
        }}>
        <Flex align="center" justify="space-between" flex={{ base: 0, md: 2 }}>
          <Hide above="md">
            <Text variant="previewQuoteProductHeader">
              {t(`quote_preview.${headers[0]}`)}
            </Text>
          </Hide>
          <Flex
            flexDir="column"
            flex={1}
            align={{ base: 'flex-end', md: 'flex-start' }}>
            <Flex>
              <PackageIcon isPackage={isPackage} px="5px" />
              <Text variant="previewQuoteProductItemName">
                {product?.description}
              </Text>
            </Flex>

            {productData?.description && (
              <Text variant="previewQuoteProductItemDescription">
                {productData.description}
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex align="center" justify="space-between" flex={{ base: 0, md: 1 }}>
          <Hide above="md">
            <Text variant="previewQuoteProductHeader">
              {t(`quote_preview.${headers[1]}`)}
            </Text>
          </Hide>
          <Flex
            align="center"
            flex={1}
            justify={{ base: 'flex-end', md: 'flex-start' }}>
            <Text variant="previewQuoteProductItemName">
              {product?.quantity}
            </Text>
          </Flex>
        </Flex>

        <Flex align="center" justify="space-between" flex={{ base: 0, md: 1 }}>
          <Hide above="md">
            <Text variant="previewQuoteProductHeader">
              {t(`quote_preview.${headers[2]}`)}
            </Text>
          </Hide>
          <Flex
            flexDir="column"
            flex={1}
            align={{ base: 'flex-end', md: 'flex-start' }}>
            <Text variant="previewQuoteProductItemName">
              {formattedCurrency}
              {product?.isVatIncluded
                ? formatNumber(
                    product?.amount + product?.amount * VAT_PERCENTAGE,
                  ).toLocaleString()
                : formatNumber(product?.amount).toLocaleString()}
            </Text>
            <Text
              variant="previewQuoteProductItemVat"
              display={product?.isVatIncluded ? 'block' : 'none'}>
              {t('quote_preview.exc_vat', {
                amount: `${formattedCurrency}${formatNumber(
                  product?.amount,
                ).toLocaleString()}`,
              })}
            </Text>
          </Flex>
        </Flex>

        <Flex align="center" justify="space-between" flex={{ base: 0, md: 1 }}>
          <Hide above="md">
            <Text variant="previewQuoteProductHeader">
              {t(`quote_preview.${headers[3]}`)}
            </Text>
          </Hide>
          <Flex
            flexDir="column"
            flex={1}
            align={{ base: 'flex-end', md: 'flex-start' }}>
            <Text variant="previewQuoteProductItemName">
              {formattedCurrency}
              {product?.isVatIncluded
                ? product?.gross?.toLocaleString()
                : product?.net?.toLocaleString()}
            </Text>
            <Text
              variant="previewQuoteProductItemVat"
              display={product?.isVatIncluded ? 'block' : 'none'}>
              {t('quote_preview.exc_vat', {
                amount: `${formattedCurrency}${formatNumber(
                  product?.net,
                ).toLocaleString()}`,
              })}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {isPackage && (
        <Flex py={4} px={{ base: 0, md: 6 }} flexDirection="column" gap="12px">
          {productData?.subItems?.map((subItem, key) => (
            <PackageProductInfo productId={subItem as string} key={key} />
          ))}
        </Flex>
      )}
    </>
  );
}
