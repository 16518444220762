import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import SelectedClientDetails from './SelectedClientDetails';
import Card from 'components/card/Card';
import NoSelectedClientDetails from './NoSelectedClientDetails';
import { useSelectedClient } from 'contexts/redux/client/clientSlice';
import useManageSelectedClient from '../hooks/useManageSelectedClient';
export default function ClientDetailsCard() {
  const { t } = useTranslation();
  const { client } = useSelectedClient();
  const {
    handleRemoveClient,
    handleEditClient,
    handleGoToEventsHistory,
    handleDuplicateClient,
  } = useManageSelectedClient();

  return (
    <Card
      flex="1"
      h="calc(100vh - 170px)"
      borderRadius={{ base: '0px', md: '30px' }}
      gap={!client ? '64px' : '24px'}>
      <Flex alignItems="center" justifyContent="space-between" h="50px">
        <Text variant="previewQuoteContactTitle">
          {t('clients.client_detail')}
        </Text>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label=""
            icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
            variant="ghost"
            w="32px"
            h="32px"
            minW="32px"
            display={client ? 'flex' : 'none'}
          />
          <MenuList>
            <MenuItem onClick={handleEditClient}>
              <Text variant="previewQuotePaymentItem">{t('clients.edit')}</Text>
            </MenuItem>
            <MenuItem onClick={handleRemoveClient}>
              <Text variant="previewQuotePaymentItem">
                {t('clients.remove')}
              </Text>
            </MenuItem>
            {/* <MenuItem onClick={handleDuplicateClient}>
              <Text variant="previewQuotePaymentItem">
                {t('clients.duplicate')}
              </Text>
            </MenuItem> */}
            <MenuItem onClick={handleGoToEventsHistory}>
              <Text variant="previewQuotePaymentItem">
                {t('clients.events')}
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      {client && <SelectedClientDetails client={client} />}
      {!client && <NoSelectedClientDetails />}
    </Card>
  );
}
