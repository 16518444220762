import { Divider, VStack } from '@chakra-ui/react';
import { setCurrentProduct } from 'contexts/redux/product/productSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Product } from 'services/@types';
import ProductHeader from './ProductHeader';
interface ProductItemComponentProps {
  product: Product;
  currentProduct?: Product;
  onOpen: () => void;
  onEdit: () => void;
}
export const ProductItemComponent: React.FC<ProductItemComponentProps> = ({
  product,
  currentProduct,
  onOpen,
  onEdit,
}) => {
  const dispatch = useDispatch<any>();

  const memoProduct = useMemo(() => product, [product]);

  return (
    <VStack
      key={product.id}
      gap={0}
      bg={product.id === currentProduct?.id ? '#D9D9D9' : 'transparent'}>
      <ProductHeader
        product={memoProduct}
        // onSendInvite={handleSendInvite}
        onClick={() => {
          dispatch(setCurrentProduct(memoProduct));
          onOpen();
        }}
        onEdit={onEdit}
      />
      <Divider
        sx={{
          marginTop: '0px !important',
          w: '90%',
          borderColor: 'gray.300',
        }}
      />
    </VStack>
  );
};
