import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  Flex,
  Hide,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import TextField from 'components/fields/TextField';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IClient } from 'services/@types';
import ChosenClient from '../../events/createUpdateEvent/ChosenClient';
import useClientForm from '../hooks/useClientForm';
import { useFindClient } from '../hooks/useFindClient';
import AddNewClientContact from './AddNewClientContact';

interface AddClientModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type inputKeys = Omit<
  IClient,
  'media' | 'id' | 'createdAt' | 'updatedAt' | 'contacts'
>;

const observedFields: (keyof IClient)[] = ['phone', 'email'];

export default function AddClientModal(props: AddClientModalProps) {
  const { isOpen, onClose } = props;
  const { t, i18n } = useTranslation();
  const {
    newClient,
    handleChange,
    handleSubmit,
    errors,
    isInviteToBrilliant,
    setIsInviteToBrilliant,
    handleFoundClient,
    handleClearClient,
    isEdit,
    handleCreateContact,
    handleRemoveContact,
  } = useClientForm({
    onClose,
  });
  const {
    isOpen: isCreateContactOpen,
    onClose: onCloseCreateContact,
    onOpen: onOpenCreateContact,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  const outerFields = useMemo(() => {
    if (!newClient?.type) return [];
    if (newClient.type === 'business') {
      // if business
      return [
        { key: 'company', type: 'text' },
        { key: 'address', type: 'text' },
        { key: 'phone', type: 'text', isRequired: true },
        { key: 'email', type: 'email', isRequired: true },
        { key: 'idNumber', type: 'text' },
        { key: 'website', type: 'text' },
        { key: 'socialProfile', type: 'text' },
      ];
    } else if (newClient.type === 'private') {
      // if private (user)
      return [
        { key: 'firstName', type: 'text', isRequired: true },
        { key: 'lastName', type: 'text', isRequired: true },
        { key: 'phone', type: 'text', isRequired: true },
        { key: 'address', type: 'text' },
        { key: 'email', type: 'email', isRequired: true },
        { key: 'idNumber', type: 'text' },
        { key: 'website', type: 'text' },
        { key: 'socialProfile', type: 'text' },
      ];
    }
    return [];
  }, [newClient?.type]);

  const { findClient } = useFindClient({
    observedObject: newClient,
    onConfirm: handleFoundClient,
    observedFields,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        handleClearClient();
      }}
      isCentered
      size="6xl">
      <ModalOverlay />
      <ModalContent
        position="relative"
        maxH={{ md: '84dvh' }}
        overflow="hidden"
        borderRadius={{ base: '0px', md: '30px' }}
        h={{ base: '100%', md: 'auto' }}>
        {/* main content */}
        <VStack
          w="100%"
          p="24px"
          gap="50px"
          overflow="auto"
          h="100%"
          alignItems="flex-start"
          flexDir="column">
          {/* header */}
          <VStack spacing={0} gap={3} alignItems="flex-start" w="100%">
            <Flex justifyContent="space-between" w="full">
              <Flex flexDir="column" gap={1}>
                <Text variant="previewQuoteContactTitle">
                  {isEdit ? t('clients.edit_client') : t('clients.add_client')}
                </Text>
                <Text
                  variant="addClientSubTitle"
                  display={isEdit ? 'none' : 'block'}>
                  {t('clients.add_client_description')}
                </Text>
              </Flex>
              <Hide above="md">
                <IconButton
                  aria-label="close"
                  icon={
                    <Icon as={CloseIcon} color="#939393" w="12px" h="12px" />
                  }
                  onClick={() => {
                    onClose();
                    handleClearClient();
                  }}
                />
              </Hide>
            </Flex>
            {/* switch between private and business */}
            <VStack spacing={0} alignItems="flex-start" gap={1} w="100%">
              <SwitchField
                w="100%"
                wrapperProps={{
                  w: '100%',
                  p: '0px',
                }}
                labelProps={{
                  marginLeft: i18n.dir() === 'rtl' ? 0 : '12px',
                  marginRight: i18n.dir() === 'rtl' ? '12px' : 0,

                  fontSize: '14px',
                  fontWeight: 400,
                }}
                colorScheme="yellow"
                color="#AA915D"
                display="flex"
                labelColor="#AA915D"
                reversed
                label={t('clients.business_client')}
                isChecked={newClient.type === 'business'}
                onChange={() =>
                  handleChange({
                    target: {
                      name: 'type',
                      value:
                        newClient.type === 'business' ? 'private' : 'business',
                    },
                  } as any)
                }
                id="isTeamMember"
              />
              <Text fontSize="12px" color="gray.500">
                {t('clients.business_client_warning')}
              </Text>
            </VStack>
          </VStack>
          {/* fields */}
          <Flex flexDir="column" gap="24px">
            {newClient.type === 'business' && (
              <Text
                fontSize={'base'}
                lineHeight="28px"
                color="black"
                fontWeight={600}>
                {t('clients_business_details.business_details')}
              </Text>
            )}
            <Flex flexWrap="wrap" gap="24px 12px">
              {outerFields.map((field) => (
                <InputField
                  key={field.key}
                  name={field.key}
                  value={newClient?.[field.key as keyof inputKeys] || ''}
                  onChange={handleChange}
                  flex={{ base: '1', md: '1 1 30%' }}
                  w={{
                    base: '100%',
                    md: `calc((100% - 12px) / 2)`,
                    lg: `calc((100% - 24px) / 3)`,
                  }}
                  label={
                    t(
                      `${
                        newClient.type === 'business'
                          ? 'clients_business_details.label'
                          : 'clients'
                      }.${field.key}`,
                    ) + (field.isRequired ? ' *' : '')
                  }
                  borderColor={
                    errors.includes(field.key) ? 'red.500' : 'gray.300'
                  }
                  onBlur={(e) => {
                    if (
                      observedFields?.includes(e.target.name as keyof IClient)
                    ) {
                      findClient();
                    }
                  }}
                  placeholder={t(`clients.${field.key}`)}
                  type={field.type}
                />
              ))}
            </Flex>
            {newClient?.type === 'private' && (
              <TextField
                label={t('clients.note')}
                placeholder={t('clients.note')}
                tooltipTopLabel={t('clients.note_tooltip')}
                value={newClient?.note || ''}
                name="note"
                onChange={handleChange}
              />
            )}
            {newClient.type === 'business' && (
              <>
                <Text
                  fontSize={'base'}
                  lineHeight="28px"
                  color="black"
                  fontWeight={600}>
                  {t('client_contact.label')}
                </Text>
                <Button
                  margin={'0 !important'}
                  onClick={onOpenCreateContact}
                  variant="h2outlined"
                  w="full"
                  maxW={{ base: 'unset', md: '320px' }}>
                  {t('client_contact.title')}
                </Button>
                <HStack gap={2} wrap="wrap" w="full">
                  {newClient?.contacts?.map(
                    (client: any, key: number) =>
                      client && (
                        <ChosenClient
                          key={key}
                          client={client}
                          handleRemoveClient={handleRemoveContact}
                          index={key}
                        />
                      ),
                  )}
                </HStack>
              </>
            )}
            {!isEdit && (
              <Checkbox
                variant="roundBrand"
                borderColor="#AA915D"
                isChecked={isInviteToBrilliant}
                onChange={(e) => setIsInviteToBrilliant(e.target.checked)}
                alignItems="center">
                <Text variant="inviteToBrilliant">
                  {t('clients.invite_to_brilliant')}
                </Text>
              </Checkbox>
            )}
          </Flex>
          {/* footer */}
          <Flex justifyContent="center" w="100%">
            <Button
              variant="h1cta"
              isDisabled={errors.length > 0}
              w={{ base: '100%', md: '320px' }}
              onClick={handleSubmit}>
              {t('clients.save')}
            </Button>
          </Flex>
        </VStack>
        {/* add new contact slide */}
        <AddNewClientContact
          isCreateContactOpen={isCreateContactOpen}
          onCloseCreateContact={onCloseCreateContact}
          onOpenCreateContact={onOpenCreateContact}
          handleCreateContact={(newClientContact) => {
            handleCreateContact(newClientContact);
            onCloseCreateContact();
          }}
        />
      </ModalContent>
    </Modal>
  );
}
