import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { fetchClients } from 'contexts/redux/client/clientSlice';
import {
  AppDialogProps,
  closeClientDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import useDebounce from 'hooks/useDebounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ClientCard from './ClientCard';

const trimAndLowerCase = (str: string) => str.toLowerCase().trim();

function ClientDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const { clientDialog, clients } = useSelector((state: RootState) => ({
    clientDialog: state.dialogs.clientDialog,
    clients: state.clients.clients,
  }));
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 700);

  const filteredClients = useMemo(() => {
    if (!debouncedSearch) return clients;
    return clients.filter((c) =>
      trimAndLowerCase(
        `${c.firstName}${c.lastName || ''}${c.email}${c.company || ''}${
          c.note
        }`,
      ).includes(debouncedSearch.toLowerCase().trim()),
    );
  }, [clients, debouncedSearch]);

  const dialog = clientDialog as AppDialogProps;

  const handleClose = useCallback(() => {
    dispatch(closeClientDialog());
  }, [dispatch]);

  const handleSubmitClientsSelection = useCallback(() => {
    if (clientDialog?.onConfirm) {
      clientDialog.onConfirm(
        clients.filter((c) => selectedClients.includes(c.id)),
      );
    }
    handleClose();
  }, [handleClose, clientDialog, selectedClients, clients]);

  const handleSelectClient = useCallback(
    (clientId: string) => {
      if (selectedClients.includes(clientId)) {
        setSelectedClients((prev) => prev.filter((id) => id !== clientId));
      } else {
        setSelectedClients((prev) => [...prev, clientId]);
      }
    },
    [selectedClients],
  );

  useEffect(() => {
    const getClients = async () => {
      if (!!dialog) {
        await dispatch(fetchClients({}));
      }
    };
    getClients();
  }, [dispatch, dialog]);

  useEffect(() => {
    if (clientDialog?.item?.chosenClients) {
      setSelectedClients(clientDialog.item.chosenClients.map((c: any) => c.id));
    }
  }, [clients, clientDialog?.item]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size={isMobile ? 'full' : 'sm'}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0px', md: '30px' }}
        minH={{ base: '100%', md: '50dvh' }}>
        <ModalBody
          p="24px 16px"
          w="full"
          h="100%"
          display="flex"
          flexDir="column">
          <VStack w="100%" gap={4} spacing={0} h={'full'}>
            <HStack w="100%" justifyContent="space-between" spacing={0}>
              <Text
                variant="cardSectionTitle"
                fontSize={'base'}
                fontWeight={700}
                color="#141718">
                {t('dialogs.client.title')}
              </Text>
              <ModalCloseButton
                position="relative"
                top={0}
                right={0}
                left={0}
                justifyContent={'center'}
                alignItems="center"
                display={'flex'}
                boxShadow="none !important"
              />
            </HStack>
            {/* Search */}
            <SearchBar
              value={search}
              margin={'0 !important'}
              inputProps={{
                onChange: (e: any) => setSearch(e.target.value),
              }}
            />
            <VStack
              w="100%"
              maxH="500px"
              overflowY="auto"
              gap={2}
              display="flex"
              flexDirection="column"
              h="100%"
              flex={1}>
              {/* TODO: add Recents and divider  */}
              {filteredClients.length === 0 && (
                <Text variant="errorTitle">
                  {t('dialogs.client.no_client')}
                </Text>
              )}
              {filteredClients.map((client) => (
                <ClientCard
                  key={client.id}
                  client={client}
                  onSelect={handleSelectClient}
                  isSelected={selectedClients.includes(client.id)}
                />
              ))}
            </VStack>
          </VStack>
          <Spacer />
          <Button
            variant="h1cta"
            w="full"
            onClick={handleSubmitClientsSelection}>
            {t('dialogs.client.add')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ClientDialog;
