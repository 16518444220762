import { useToast } from '@chakra-ui/react';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { fetchClients } from 'contexts/redux/client/clientSlice';
import {
  createContract,
  fetchContract,
  updateContract,
} from 'contexts/redux/contract/contractSlice';
import { setEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { fetchProducts } from 'contexts/redux/product/productSlice';
import { getQuotes } from 'contexts/redux/quote/quoteSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { formatNumber } from 'helpers/formatNumber';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IContract, IEvent, Upload, UrlItem, User } from 'services/@types';
import UploadService from 'services/upload.api';
import UserService from 'services/user.api';
import {
  currency,
  EXCHANGE_RATE_FROM_ILS_TO_USD,
  VAT_PERCENTAGE,
} from 'variables/currency';
import { languages } from 'variables/languages';

const initialProducts: IContract['products'][0] = {
  productId: '',
  description: '',
  amount: 0,
  price: 0,
  totalPrice: 0,
  isVatIncluded: false,
};

const initialRecipient: IContract['recipients'][0] = {
  firstName: '',
  lastName: '',
};

export default function useContractForm() {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const myBusiness = useMyBusiness();
  const myUser = useMyUser();
  const { qoutes, clients, products, loading } = useSelector(
    (state: RootState) => ({
      qoutes: state.quote.qoutes,
      clients: state.clients.clients,
      products: state.product.products,
      loading: state.contracts.loading,
    }),
  );
  const [currentSentTo, setCurrentSentTo] = useState<string>('');
  const [displayingSentTo, setDisplayingSentTo] = useState<
    {
      avatar: UrlItem | undefined;
      email: string;
      label: string;
      value: string;
    }[]
  >([]);
  const [event, setEvent] = useState<IEvent>(null);
  const [contract, setContract] = useState<IContract>({
    products: [{}],
    recipients: [{}],
  });
  const [radioValue, setRadioValue] = useState<'assign' | 'attach'>('assign');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const selectedCurrency: { label: string; value: string; symbol: string }[] =
    useMemo(() => {
      const selected = currency.find((c) => c.value === contract?.currency);
      return selected ? [selected] : [];
    }, [contract?.currency]);

  const selectedLanguage = useMemo(() => {
    const selected = languages.find((l) => l.value === contract?.language);
    return selected ? [selected] : [];
  }, [contract?.language]);

  const currentEventType = useMemo(
    () => contract?.eventType || '',
    [contract?.eventType],
  );

  const showedEventType = useMemo(
    () => (currentEventType ? t(`event_types.${currentEventType}`) : ''),
    [currentEventType, t],
  );

  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );

  const showedCurrency: string = useMemo(() => {
    const selected = currency.find((c) => c.value === contract?.currency);
    return selected ? t(selected.label) : '';
  }, [contract?.currency, t]);

  const showedLanguage: string = useMemo(() => {
    const selected = languages.find((l) => l.value === contract?.language);
    return selected ? t(selected.label) : '';
  }, [contract?.language, t]);

  const displayedQoutes = useMemo(() => {
    return qoutes.map((qoute) => {
      let label = '';
      const values = [
        qoute?.quoteNumber,
        qoute?.eventName,
        ...qoute?.clients?.map(
          (c) => `${c?.firstName || ''} ${c?.lastName || ''}`,
        ),
      ];
      for (const key in values) {
        if (!!values[key]) {
          label += `${values[key]} `;
        }
      }
      return {
        value: qoute.id,
        label,
      };
    });
  }, [qoutes]);

  const displayedClients = useMemo(() => {
    return clients.map((client) => ({
      value: client.id,
      label: `${client?.firstName || ''} ${client?.lastName || ''}`,
    }));
  }, [clients]);

  const displayedProducts = useMemo(() => {
    return products.map((product) => ({
      value: product.id,
      label: product.name,
    }));
  }, [products]);

  const currencySymbol = useMemo(() => {
    const selected = currency.find((c) => c.value === contract?.currency);
    return selected ? selected.symbol : '';
  }, [contract?.currency]);

  const userId = useMemo(() => myUser?.id, [myUser?.id]);

  const handleChange = useCallback((path: string, value: any) => {
    setContract((prev) => ({ ...prev, [path]: value }));
  }, []);

  const handleSelectedQuote = useCallback(
    (id: string) => {
      const quote = qoutes.find((q) => q.id === id);
      if (quote) {
        const products =
          quote?.products?.map((p) => ({
            productId: p?.productId?.toString() || '',
            description: p?.description || '',
            amount: p?.quantity || 0,
            price: p?.amount || 0,
            totalPrice: p?.net || 0,
            isVatIncluded: p?.isVatIncluded || false,
          })) || [];

        setContract((prev) => ({
          ...prev,
          quoteId: quote.id,
          products: [...products],
          currency: quote?.currency || '',
        }));
      }
    },
    [qoutes],
  );

  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');

      if (selectedEventType.includes(selected)) {
        handleChange('eventType', '');
        return;
      }
      handleChange('eventType', formattedSelected);
    },
    [selectedEventType, handleChange],
  );

  const handleSelectedClient = useCallback(
    (id: string) => {
      const client = clients.find((c) => c.id === id);
      if (client) {
        setContract((prev) => ({
          ...prev,
          clientDetails: {
            ...{
              name: `${client?.firstName} ${client?.lastName}`,
              email: client?.email,
              phone: client?.phone,
              clientId: client?.id,
            },
          },
        }));
      }
    },
    [clients],
  );

  const handleProductDescriptionSelected = useCallback(
    (id: string, index: number) => {
      const product = products.find((p) => p.id === id);
      if (product) {
        setContract((prev) => ({
          ...prev,
          products: prev.products.map((p, i) =>
            i === index
              ? {
                  description: product.name,
                  productId: id,
                  amount: 0,
                  price: product?.price || 0,
                  totalPrice: 0,
                  isVatIncluded: false,
                }
              : p,
          ),
        }));
      }
    },
    [products],
  );

  const handleProductChange = useCallback(
    ({
      name,
      value,
      index,
    }: {
      name: string;
      value: number;
      index: number;
    }) => {
      if (name === 'amount') {
        setContract((prev) => ({
          ...prev,
          products: prev.products.map((p, i) =>
            i === index
              ? { ...p, amount: value, totalPrice: value * p.price }
              : p,
          ),
        }));
      }

      if (name === 'price') {
        setContract((prev) => ({
          ...prev,
          products: prev.products.map((p, i) =>
            i === index
              ? { ...p, price: value, totalPrice: value * p.amount }
              : p,
          ),
        }));
      }
    },
    [],
  );

  const handleProductVatChange = useCallback((index: number) => {
    setContract((prev) => ({
      ...prev,
      products: prev.products.map((p, i) =>
        i === index
          ? {
              ...p,
              isVatIncluded: !p.isVatIncluded,
              totalPrice: !p.isVatIncluded
                ? p.totalPrice + p.totalPrice * VAT_PERCENTAGE
                : p.price * p.amount,
            }
          : p,
      ),
    }));
  }, []);

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          setEvent(e);
          setContract((prev) => ({
            ...prev,
            eventId: e.id,
            eventType: e.type,
          }));
        },
      }),
    );
  }, [dispatch]);

  const handleRemoveProduct = useCallback((index: number) => {
    setContract((prev) => ({
      ...prev,
      products: prev.products.filter((_, i) => i !== index),
    }));
  }, []);

  const handleAddProduct = useCallback(() => {
    setContract((prev) => ({
      ...prev,
      products: [...prev.products, initialProducts],
    }));
  }, []);

  const handleSelectSentTo = useCallback((value) => {
    setContract((prev) => ({
      ...prev,
      sentTo: [...(prev?.sentTo || []), value],
    }));
    setCurrentSentTo('');
  }, []);

  const handleChangeCurrentSentTo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentSentTo(e.target.value);
    },
    [],
  );

  const handleRemoveSentToItem = useCallback((value) => {
    setContract((prev) => ({
      ...prev,
      sentTo: prev.sentTo.filter((guest: string) => guest !== value),
    }));
  }, []);

  const handleChangeCurrency = useCallback(
    (value: string) => {
      handleChange('currency', value);

      if (contract?.products?.length === 0) return;

      // convert from ILS to USD
      if (contract?.currency === 'ILS' && value === 'USD') {
        handleChange(
          'products',
          contract?.products.map((p) => ({
            ...p,
            price: formatNumber(p?.price * EXCHANGE_RATE_FROM_ILS_TO_USD),
            totalPrice: formatNumber(
              p?.price * EXCHANGE_RATE_FROM_ILS_TO_USD * p.amount,
            ),
          })),
        );
      }

      // convert from USD to ILS
      if (contract?.currency === 'USD' && value === 'ILS') {
        handleChange(
          'products',
          contract.products.map((p) => {
            const product = products.find(
              (product) => product.id === p?.productId,
            );

            if (!product) {
              return { ...p };
            }

            return {
              ...p,
              price: formatNumber(product?.price),
              totalPrice: formatNumber(product?.price * p.amount),
            };
          }),
        );
      }
    },
    [handleChange, contract?.currency, products, contract?.products],
  );

  const addRecipient = useCallback(() => {
    setContract((prev) => ({
      ...prev,
      recipients: [...(prev?.recipients || []), initialRecipient],
    }));
  }, []);

  const handleChangeRecipient = useCallback(
    (payload: { name: string; value: string; index: number }) => {
      setContract((prev) => ({
        ...prev,
        recipients: prev.recipients.map((r, i) =>
          i === payload.index ? { ...r, [payload.name]: payload.value } : r,
        ),
      }));
    },
    [],
  );

  const handleRemoveRecipient = useCallback((index: number) => {
    setContract((prev) => ({
      ...prev,
      recipients: prev.recipients.filter((_, i) => i !== index),
    }));
  }, []);

  const handleUploadFile = useCallback(
    async (event: any) => {
      const file = event?.target?.files[0];

      if (!file) return;

      try {
        const newFile: Upload = await UploadService.uploadFile(
          'user',
          myUser?.id,
          'private',
          file,
        );

        return newFile;
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [myUser?.id],
  );

  // add file near comment
  const handleAddFile = useCallback(
    async (event: any) => {
      event.stopPropagation();
      try {
        const newFile = await handleUploadFile(event);
        if (newFile) {
          setContract((prev) => ({
            ...prev,
            media: [
              ...(prev?.media || []),
              {
                url: newFile.filePath,
                type: 'document',
                displayName: newFile.fileName,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [handleUploadFile],
  );

  // add quote file
  const handleAddQuoteFile = useCallback(async (urlItem: UrlItem) => {
    if (!urlItem) {
      console.error('use add quote file error -> urlItem is required');
      return;
    }
    setContract((prev) => ({
      ...prev,
      media: [
        ...(prev?.media || []),
        {
          url: urlItem.url,
          type: 'quote',
          displayName: urlItem.displayName,
        },
      ],
    }));
  }, []);

  const clearFile = useCallback(
    async (type: 'document' | 'quote') => {
      if (!type) {
        console.error('use clear file error -> type is required');
        return;
      }
      try {
        const [target, targetId, access, fileId] = contract?.media
          ?.find((m) => m.type === type)
          ?.url.split('/');
        await UploadService.deleteUpload({
          target,
          targetId,
          access,
          fileId,
        });

        setContract((prev) => ({
          ...prev,
          media: prev.media.filter((m) => m.type !== type),
        }));
      } catch (error) {
        console.error('use clear file error ->', error);
      }
    },
    [contract?.media],
  );

  const handleCreateContract = useCallback(
    async (isDraft = false) => {
      if (contract?.id) {
        const response = await dispatch(
          updateContract({
            contractId: contract.id,
            updates: {
              ...contract,
              isDraft,
            },
          }),
        );

        if (response.type?.includes('rejected')) {
          toast({
            title: (response?.payload as any)?.response?.data?.message,
            status: 'error',
            position: 'top-right',
          });
          return;
        }
        toast({
          title: t('create_contract.contract_updated'),
          variant: 'main',
          position: 'top-right',
        });
      } else {
        const response = await dispatch(
          createContract({
            ...contract,
            isDraft,
          }),
        );

        if (response.type?.includes('rejected')) {
          toast({
            title: (response?.payload as any)?.response?.data?.message,
            status: 'error',
            position: 'top-right',
          });
          return;
        }

        toast({
          title: isDraft
            ? t('create_contract.contract_drafted')
            : t('create_contract.contract_sent'),
          variant: 'main',
          position: 'top-right',
        });
      }

      navigate('/main/finance/contracts');
    },
    [contract, dispatch, navigate, t],
  );

  useEffect(() => {
    dispatch(getQuotes({}));
    dispatch(fetchClients({}));
    dispatch(fetchProducts({}));
  }, [dispatch]);

  // autocomplete for sent to
  useEffect(() => {
    if (currentSentTo.length > 0 && myBusiness?.id) {
      const fetchGuests = async () => {
        const guests = await UserService.getUsers({
          businessID: myBusiness?.id,
          name: currentSentTo,
          limit: 5,
        });

        if (guests?.results) {
          setDisplayingSentTo(
            guests.results
              ?.filter((guest: User) => !contract?.sentTo?.includes(guest?.id))
              .map((guest: User) => ({
                avatar: guest.media?.find((media) => media.type === 'avatar'),
                email: guest.email,
                label: `${guest.firstName} ${guest.lastName}`,
                value: guest.id,
              })),
          );
        }
      };
      fetchGuests();
    }
  }, [currentSentTo, myBusiness?.id, dispatch]);

  useEffect(() => {
    if (params?.contractId) {
      const getContract = async () => {
        const response = await dispatch(fetchContract(params.contractId));
        if (response.type?.includes('fulfilled')) {
          setContract(response.payload as IContract);
        }
      };
      getContract();
    }
  }, [params?.contractId, dispatch]);

  return {
    event,
    handleOpenEventDialog,
    handleChange,
    contract,
    selectedCurrency,
    selectedLanguage,
    showedCurrency,
    showedLanguage,
    radioValue,
    setRadioValue,
    qoutes: displayedQoutes,
    clients: displayedClients,
    products: displayedProducts,
    handleSelectedQuote,
    handleSelectedClient,
    selectedEventType,
    showedEventType,
    handleChangeEventType,
    handleAddProduct,
    handleRemoveProduct,
    handleProductDescriptionSelected,
    handleProductChange,
    handleProductVatChange,
    currencySymbol,
    displayingSentTo,
    handleSelectSentTo,
    currentSentTo,
    handleChangeCurrentSentTo,
    handleRemoveSentToItem,
    handleChangeCurrency,
    addRecipient,
    handleChangeRecipient,
    handleRemoveRecipient,
    fileInputRef,
    handleAddFile,
    clearFile,
    handleCreateContract,
    loading,
    handleAddQuoteFile,
    userId,
  };
}
