import { Divider, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import VatIcon from 'assets/svg/supplierInfo/vat-icon.svg';
import Card from 'components/card/Card';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { formatDate } from 'date-fns';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { isNumber } from 'lodash';
import useGetEvent from 'queries/event';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit3, FiPhone, FiUser } from 'react-icons/fi';
import { HiOutlineLocationMarker, HiOutlineMailOpen } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import _businessEventPlanService from 'services/businessEventPlan.api';

export default function ClientDetails() {
  const params = useParams();
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('details');
  const navigate = useNavigate();
  const { id: businessId } = useMyBusiness();
  const { data: eventResult } = useQuery({
    queryKey: [`getEvent-${params?.id}`],
    queryFn: () =>
      _businessEventPlanService.getBusinessEventPlans({
        businessId,
        eventId: params?.id,
      }),
    enabled: !!params?.id,
  });
  const { data: eventData } = useGetEvent(params?.id);
  const handleEditEventClientDetails = useCallback(() => {
    navigate(`/main/managment/events/update/${params?.id}`);
  }, [params?.id, navigate]);

  const eventDate = useMemo(() => {
    return eventData?.dateAndTime
      ? formatDate(eventData?.dateAndTime, 'MMM dd, yyyy  hh:mm a')
      : '';
  }, [eventData?.dateAndTime]);

  const numberOfGuests = useMemo(() => {
    let numberOfGuests = 0;
    if (isNumber(eventData?.numberOfGuests)) {
      numberOfGuests = +eventData?.numberOfGuests;
    }
    if (isNumber(eventData?.numberOfGuestsChildren)) {
      numberOfGuests += +eventData?.numberOfGuestsChildren;
    }
    return numberOfGuests;
  }, [eventData?.numberOfGuests, eventData?.numberOfGuestsChildren]);

  return (
    <Card
      ref={ref}
      {...style}
      w="100%"
      overflow="auto"
      minW={{ base: '100%', lg: '310px' }}
      maxH="620px"
      flex={1}
      height="unset"
      display="flex"
      flexDirection="column"
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px">
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.client_details')}</Text>
        <Icon
          as={FiEdit3}
          color="#999EA9"
          w="24px"
          h="24px"
          cursor="pointer"
          onClick={handleEditEventClientDetails}
        />
      </Flex>
      <Flex flexDirection="column" gap="16px">
        <Flex flexDirection="column" gap="4px">
          <Text
            variant="eventGridCardTitle"
            display={eventData?.name ? 'flex' : 'none'}>
            {eventData?.name}
          </Text>
          <Text
            variant="taskHistoryType"
            display={eventData?.type ? 'flex' : 'none'}>
            {t(`event_types.${eventData?.type}`)}
          </Text>
        </Flex>
        <Flex flexDirection="column" gap="4px">
          <Text
            variant="clientDetailInfo"
            display={eventDate ? 'flex' : 'none'}>
            {eventDate}
          </Text>
          <Flex alignItems="center" gap="4px">
            <Flex
              alignItems="center"
              gap="2px"
              display={numberOfGuests > 0 ? 'flex' : 'none'}>
              <Icon as={FiUser} />
              <Text variant="eventGridCardDesc" minW="60px">
                {t('event.invited', { count: numberOfGuests })}
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              gap="2px"
              display={eventData?.location?.label ? 'flex' : 'none'}>
              <Icon as={HiOutlineLocationMarker} />
              <Text variant="eventGridCardDesc">
                {eventData?.location?.label}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {eventData?.clients?.map((contact, key) => (
        <React.Fragment key={key}>
          <Divider />
          {key > 0 && (
            <Text variant="clientDetailInfo">
              {t('event.contact', { count: key + 1 })}
            </Text>
          )}
          <Flex flexDirection="column" gap="14px">
            <Flex
              alignItems="center"
              gap="8px"
              display={
                contact?.firstName || contact?.lastName ? 'flex' : 'none'
              }>
              <Icon as={FiUser} color="#00000099" />
              <Text variant="eventTabButtonTitle">
                {`${contact?.firstName || ''} ${contact?.lastName || ''}`}
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              gap="8px"
              display={contact?.phone ? 'flex' : 'none'}>
              <Icon as={FiPhone} color="#00000099" />
              <Text
                variant="eventTabButtonTitle"
                cursor="pointer"
                _hover={{
                  textDecoration: 'underline',
                }}
                transition="all 0.3s ease"
                onClick={() => {
                  window.open(`tel:${contact?.phone}`, '_blank');
                }}>
                {contact?.phone}
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              gap="8px"
              display={contact?.address ? 'flex' : 'none'}>
              <Icon as={HiOutlineLocationMarker} color="#00000099" />
              <Text variant="eventTabButtonTitle">{contact?.address}</Text>
            </Flex>
            <Flex
              alignItems="center"
              gap="8px"
              display={contact?.idNumber ? 'flex' : 'none'}>
              <Image src={VatIcon} />
              <Text variant="eventTabButtonTitle">{contact?.idNumber}</Text>
            </Flex>
            <Flex
              alignItems="center"
              gap="8px"
              display={contact?.email ? 'flex' : 'none'}>
              <Icon as={HiOutlineMailOpen} color="#00000099" />
              <Text
                variant="eventTabButtonTitle"
                cursor="pointer"
                _hover={{
                  textDecoration: 'underline',
                }}
                transition="all 0.3s ease"
                onClick={() => {
                  window.open(`mailto:${contact?.email}`, '_blank');
                }}>
                {contact?.email}
              </Text>
            </Flex>
          </Flex>
        </React.Fragment>
      ))}
    </Card>
  );
}
