import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
interface DetailInfoItemProps {
  title?: string;
  value: string;
  icon?: React.ReactNode;
}

export const DetailInfoItem: React.FC<DetailInfoItemProps> = ({
  title,
  value,
  icon,
}) => {
  return (
    <HStack align="center" display={!value ? 'none' : 'flex'}>
      {icon && icon}
      <Text fontWeight={'bold'}>{title}</Text>
      <Text variant="supplierDetailsInfo">{value}</Text>
    </HStack>
  );
};
