import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'contexts/redux/store';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  fetchClients,
  setCurrentClient,
  setEditClient,
} from 'contexts/redux/client/clientSlice';
import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

export default function useClients() {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<string>('');
  const queryClient = useQueryClient();
  const { clients, loading, editClient } = useSelector((state: RootState) => ({
    clients: state.clients.clients,
    loading: state.clients.loading,
    editClient: state.clients.editClient,
  }));
  const {
    isOpen: isAddClientOpen,
    onOpen: onOpenAddClient,
    onClose: onAddClientClosed,
  } = useDisclosure();

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      queryClient.invalidateQueries({ queryKey: ['clients', e.target.value] });
    },
    [queryClient],
  );

  const handleCloseClientModal = useCallback(() => {
    dispatch(setEditClient(null));
    onAddClientClosed();
  }, [dispatch, onAddClientClosed]);

  const handleOpenAddClient = useCallback(() => {
    dispatch(setEditClient(null));
    onOpenAddClient();
  }, [dispatch, onOpenAddClient]);

  useQuery({
    queryKey: ['clients', search],
    queryFn: () => {
      const params: { page: number; limit: number; name?: string } = {
        page: 1,
        limit: 10,
      };

      if (search.length > 0) {
        params.name = search;
        // reset current client
        dispatch(setCurrentClient(null));
      }

      return dispatch(fetchClients(params));
    },
  });

  // open modal for edit client
  useEffect(() => {
    if (editClient) {
      onOpenAddClient();
    }
  }, [editClient, onOpenAddClient]);

  return {
    clients,
    isAddClientOpen,
    onOpenAddClient: handleOpenAddClient,
    onAddClientClosed: handleCloseClientModal,
    search,
    handleSearch,
    loading,
  };
}
