import { useCallback, useEffect, useState } from 'react';
import { Business, ContactPerson, Supplier } from 'services/@types';
import _businessService from 'services/business.api';

export const useBusinessContact = <T = Business>(
  supplier?: Supplier | Business,
  populateCategories: boolean = false,
): {
  businessContact?: ContactPerson;
  business?: T;
} => {
  const [businessContact, setBusinessContact] = useState<ContactPerson>();
  const [business, setBusiness] = useState<T>();

  const handleLookupBusiness = useCallback(
    async (businessId: string) => {
      const business = await _businessService.getBusiness<T>(
        businessId,
        populateCategories
          ? {
              populateCategories: 'true',
            }
          : undefined,
      );
      setBusiness(business as T);
    },
    [setBusiness, populateCategories],
  );

  useEffect(() => {
    setBusiness(null);
    console.log('supplier ->', supplier);
    if (supplier && 'contacts' in supplier) {
      if (supplier?.contacts?.length > 0) {
        setBusinessContact(supplier.contacts[0]);
      }

      if (supplier.businessID) {
        // get user from server...
        handleLookupBusiness(supplier.businessID);
      }
    }
  }, [supplier, setBusiness, handleLookupBusiness]);

  return {
    businessContact,
    business: business as T,
  };
};
