import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IClient } from 'services/@types';
import AddClientModal from 'views/main/managment/clients/components/AddClientModal';
import useClients from 'views/main/managment/clients/hooks/useClients';
import ChosenClient from '../ChosenClient';

type SelectClientsProps = {
  handleOpenClientDialog: () => void;
  handleRemoveClient: (client: IClient) => void;
  clients: IClient[];
  hasError: boolean;
};

const SelectClients = ({
  handleOpenClientDialog,
  handleRemoveClient,
  clients,
  hasError,
}: SelectClientsProps) => {
  const { t } = useTranslation();

  const { onOpenAddClient, isAddClientOpen, onAddClientClosed } = useClients();
  return (
    <>
      <Stack gap={3} spacing={0}>
        <Text
          fontSize={'base'}
          lineHeight={'28px'}
          fontWeight={700}
          color="black">
          {t('create_event.label.client_details')}
        </Text>
        <HStack
          w="full"
          spacing={0}
          gap={3}
          flexDir={{ base: 'column', md: 'row' }}>
          <Button
            margin={'0 !important'}
            onClick={handleOpenClientDialog}
            variant="h1cta"
            w="full"
            maxW={{ base: 'unset', md: '320px' }}
            style={{
              outline: hasError ? '1px solid red' : 'none',
              outlineOffset: '4px',
            }}>
            {t('create_event.label.select_clients')}
          </Button>
          <Button
            margin={'0 !important'}
            onClick={onOpenAddClient}
            variant="h2outlined"
            w="full"
            maxW={{ base: 'unset', md: '320px' }}>
            {t('create_event.label.create_new_client')}
          </Button>
        </HStack>
        <HStack gap={2} wrap="wrap" w="full">
          {clients?.map(
            (client: any, key: number) =>
              client && (
                <ChosenClient
                  client={client}
                  handleRemoveClient={handleRemoveClient}
                />
              ),
          )}
        </HStack>
      </Stack>
      <AddClientModal isOpen={isAddClientOpen} onClose={onAddClientClosed} />
    </>
  );
};

export default SelectClients;
