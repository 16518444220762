import { Button, Divider, Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import SelectClients from 'views/main/managment/events/createUpdateEvent/components/SelectClients';
import CreateQuoteHeader from './components/CreateQuoteHeader';
import PaymentDetails from './components/PaymentDetails';
import Products from './components/Products';
import useCreateQuote from './hooks/useCreateQuote';

export default function CreateQuote() {
  const { t } = useTranslation();
  const {
    newQuote,
    handleChange,
    handleAddProduct,
    handleChangeEventType,
    selectedEventType,
    handleOpenEventDialog,
    handleAddPayment,
    handleCreateQuote,
    handleIncreasePaymentQuantity,
    handleDecreasePaymentQuantity,
    event,
    setNewQuote,
    isLoading,
    products,
    handleSelectedProduct,
    handleChangeFile,
    clearFile,
    handleChangePaymentAmount,
    currencyIcon,
    error,
    handleChangeAllProductsVatIncluded,
    isAllProductsVatIncluded,
    handleChangeProductVatIncluded,
    handleBlurPaymentAmount,
    handleOpenClientDialog,
    handleRemoveClient,
  } = useCreateQuote();

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '57px' }}
      flexDirection="column"
      gap={4}>
      <Card gap="36px">
        <CreateQuoteHeader
          handleChange={handleChange}
          newQuote={newQuote}
          handleChangeEventType={handleChangeEventType}
          selectedEventType={selectedEventType}
          handleOpenEventDialog={handleOpenEventDialog}
          event={event}
        />
        <SelectClients
          handleOpenClientDialog={handleOpenClientDialog}
          handleRemoveClient={handleRemoveClient}
          clients={newQuote?.clients}
          hasError={error?.clients}
        />
        <Flex
          w="100%"
          flexDirection={{ base: 'column', md: 'row' }}
          gap="16px"
          h="100%">
          <Products
            handleAddProduct={handleAddProduct}
            newQuote={newQuote}
            handleChange={handleChange}
            setNewQuote={setNewQuote}
            products={products}
            handleSelectedProduct={handleSelectedProduct}
            handleChangeFile={handleChangeFile}
            clearFile={clearFile}
            currencyIcon={currencyIcon}
            error={error}
            handleChangeAllProductsVatIncluded={
              handleChangeAllProductsVatIncluded
            }
            isAllProductsVatIncluded={isAllProductsVatIncluded}
            handleChangeProductVatIncluded={handleChangeProductVatIncluded}
          />
          <Divider
            orientation="vertical"
            borderColor="#EBEDF0"
            h="900px"
            display={{ base: 'none', md: 'block' }}
          />
          <PaymentDetails
            newQuote={newQuote}
            handleAddPayment={handleAddPayment}
            handleIncreasePaymentQuantity={handleIncreasePaymentQuantity}
            handleDecreasePaymentQuantity={handleDecreasePaymentQuantity}
            setNewQuote={setNewQuote}
            currencyIcon={currencyIcon}
            error={error}
            handleChangePaymentAmount={handleChangePaymentAmount}
            handleBlurPaymentAmount={handleBlurPaymentAmount}
          />
        </Flex>
        <Flex justify="flex-end">
          <Button
            isLoading={isLoading}
            disabled={error?.clients || error?.products || error?.totalPayment}
            variant="h1cta"
            w="240px"
            onClick={handleCreateQuote}>
            {t('create_quote.save')}
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
}
