import { closeAlertDialog } from 'contexts/redux/dialog/dialogsSlice';

import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { openAlertDialog } from 'contexts/redux/dialog/dialogsSlice';
import { updateQuote } from 'contexts/redux/quote/quoteSlice';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import QuoteService from 'services/quote.api';
import { AppDispatch } from 'contexts/redux/store';

interface IuseHandlePaymentItemProps {
  quoteId: string;
  paymentIndex: number;
  isPaid: boolean;
  date: string | Date;
}

export default function useHandlePaymentItem(
  props: IuseHandlePaymentItemProps,
) {
  const { quoteId, paymentIndex, isPaid, date } = props;
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const { t } = useTranslation();
  const formattedDate = useMemo(() => {
    return date ? format(date, 'yyyy-MM-dd') : '';
  }, [date]);
  const { data: quoteData } = useQuery({
    queryKey: ['quote', quoteId],
    queryFn: () => QuoteService.getQuote(quoteId),
    enabled: !!quoteId,
  });

  const handlePay = useCallback(() => {
    if (!isPaid) {
      dispatch(
        openAlertDialog({
          title: t('event_finance.mark_as_paid'),
          content: t('event_finance.mark_as_paid_description'),
          onConfirm: () => {
            dispatch(
              updateQuote({
                id: quoteData?.id,
                updates: {
                  paymentInInstalments: {
                    ...quoteData?.paymentInInstalments,
                    payment: quoteData?.paymentInInstalments.payment.map(
                      (payment, index) =>
                        index === paymentIndex
                          ? {
                              ...payment,
                              isPaid: true,
                              paymentDate: new Date(),
                            }
                          : payment,
                    ),
                  },
                },
              }),
            );
            toast({
              title: t('event_finance.paid_successfully'),
              variant: 'main',
              position: 'top-right',
            });
            dispatch(closeAlertDialog());
          },
        }),
      );
    }
  }, [
    isPaid,
    quoteData?.id,
    quoteData?.paymentInInstalments,
    paymentIndex,
    dispatch,
    t,
    toast,
  ]);

  return {
    handlePay,
    formattedDate,
  };
}
