import { Flex, Hide } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { IQoute } from 'services/@types';
import { QuoteThreeDotsMenu } from './QuoteThreeDotsMenu';

export default function QuoteListItem({ quote }: { quote: IQoute }) {
  const navigate = useNavigate();

  const totalPayment = useMemo(() => {
    const total = quote?.generalPaymentDetails?.totalPayment;
    if (!total) return '';
    const currency = quote?.currency === 'ILS' ? '₪' : '$';
    return `${currency} ${total.toLocaleString()}`;
  }, [quote?.generalPaymentDetails?.totalPayment, quote?.currency]);

  const customers = useMemo(() => {
    const customers = quote?.clients
      .map((c) => `${c?.firstName || ''} ${c?.lastName || ''}`)
      .join(', ');
    return customers;
  }, [quote?.clients]);

  return (
    <Flex
      key={quote.id}
      justify="space-between"
      onClick={() => navigate(`/main/finance/quotations/${quote.id}`)}
      py={4}
      px={6}
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      fontSize="14px">
      <Flex
        flex="1"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        gap={2}
        fontWeight="500">
        <Hide below="md">
          <Flex flex={1} fontWeight="700" align="center">
            {quote?.createdAt && format(quote.createdAt, 'dd.MM.yy')}
          </Flex>
        </Hide>
        <Flex flex={1} fontWeight={{ base: '700', md: '500' }} align="center">
          {quote?.eventName}
        </Flex>
        <Flex flex={1} align="center">
          {totalPayment}
        </Flex>
        <Flex flex={1} align="center">
          {customers}
        </Flex>
        <Hide below="md">
          <Flex flex="0 0 60px" justify="center" align="center">
            <QuoteThreeDotsMenu id={quote.id} />
          </Flex>
        </Hide>
      </Flex>
      <Hide above="md">
        <Flex
          flex="0 1 100px"
          justify={{ base: 'space-between', md: 'flex-start' }}
          flexDirection="column">
          <QuoteThreeDotsMenu id={quote.id} />
          <Flex>
            {quote?.createdAt && format(quote.createdAt, 'dd MMM, yyyy')}
          </Flex>
        </Flex>
      </Hide>
    </Flex>
  );
}
