import { Flex, FlexProps } from '@chakra-ui/react';
import { IQuotePaymentFinanceItem } from 'services/@types';
import useFinance from '../../hooks/useFinance';
import FinanceStatRibbon from './FinanceStatRibbon';
import PaymentTrackingCard from './PaymentTrackingCard';
const upcoming: IQuotePaymentFinanceItem[] = [
  {
    title: 'Samantha & Yohai',
    date: '2024-01-01',
    subtitle: 'Pool party wedding',
    amount: '50,000',
    status: 'pending',
  },
  {
    title: 'Samantha & Yohai',
    subtitle: 'Pool party wedding First payment',
    date: '2024-01-01',
    amount: '27,000',
    status: 'pending',
  },
  {
    title: 'Samantha & Yohai',
    subtitle: 'Pool party wedding',
    date: '2024-01-01',
    amount: '27,000',
    status: 'pending',
  },
];
const paid: IQuotePaymentFinanceItem[] = [
  {
    title: 'Client 2',
    date: '2024-01-01',
    subtitle: 'Pool party wedding',
    amount: '27,000',
    status: 'received',
  },
  {
    title: 'Client 2',
    date: '2024-01-01',
    subtitle: 'Pool party wedding',
    amount: '8,563',
    status: 'received',
  },
  {
    title: 'Client 2',
    subtitle: 'Pool party wedding',
    date: '2024-01-01',
    amount: '27,000',
    status: 'received',
  },
];
const incomeStat = [
  {
    label: 'expected',
    value: '₪110,000',
    bgGradient: 'linear(to-r, brand.400, brand.600)',
  },
  {
    label: 'received',
    value: '₪108,000',
    bgGradient: 'linear(to-r, brand.400, brand.600)',
  },
  {
    label: 'due',
    value: '₪12,527',
    bgGradient: 'linear(to-r, brand.400, brand.600)',
  },
];

const trackingCardsContainerStyles: FlexProps = {
  width: '100%',
  direction: { base: 'column', md: 'row' },
  gap: '24px',
};
const containerStyles: FlexProps = {
  width: '100%',
  gap: '24px',
};
const FinanceCard = () => {
  const { expensesStat, upcomingExpenses, paidExpenses } = useFinance();

  return (
    <Flex
      direction="column"
      pb={{ base: '40px', md: '60px' }}
      {...containerStyles}>
      <FinanceStatRibbon />

      <Flex {...trackingCardsContainerStyles}>
        <Flex flex={1} minW={{ base: '100%', md: '0' }}>
          <PaymentTrackingCard
            title="event_finance.tracking_income"
            stats={incomeStat}
            firstSection={upcoming}
            secondSection={paid}
            firstSectionTitle="event_finance.pending"
            secondSectionTitle="event_finance.received"
            highlightName="income"
          />
        </Flex>
        <Flex flex={1} minW={{ base: '100%', md: '0' }}>
          <PaymentTrackingCard
            title="event_finance.tracking_expenses"
            stats={expensesStat}
            firstSection={upcomingExpenses}
            secondSection={paidExpenses}
            firstSectionTitle="event_finance.pending"
            secondSectionTitle="event_finance.paid"
            highlightName="outcome"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FinanceCard;
