import { Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AppDispatch } from 'contexts/redux/store';
import { HighlightableComponent } from 'contexts/redux/uiHelper/uiHelperSlice';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { IQuotePaymentFinanceItem, UserPreferences } from 'services/@types';
import PaymentInfoListItem from './PaymentInfoListItem';

interface PaymentTrackingCardProps {
  title: string;
  stats: MiniDataStatProps[];
  firstSection: IQuotePaymentFinanceItem[];
  secondSection: IQuotePaymentFinanceItem[];
  firstSectionTitle: string;
  secondSectionTitle: string;
  highlightName: HighlightableComponent;
}

interface MiniDataStatProps {
  label: string;
  value: string;
  bgGradient: string;
}

const MiniDataStat = ({
  label,
  value,
  bgGradient,
  isBlurred,
}: MiniDataStatProps & { isBlurred?: boolean }) => {
  const { t } = useTranslation();
  return (
    <Flex
      direction="column"
      p={2}
      flex={1}
      gap={2}
      borderRadius="2xl"
      bgGradient={bgGradient || 'linear(to-r, brand.400, brand.600)'}
      color="white">
      <Text fontSize="sm" opacity={0.9}>
        {t(`event_finance.${label}`)}
      </Text>
      <Text
        fontSize="xl"
        fontWeight="bold"
        transition="all 0.3s ease"
        filter={isBlurred ? 'blur(8px)' : 'none'}>
        {value}
      </Text>
    </Flex>
  );
};

const PaymentTrackingCard = ({
  title,
  stats,
  firstSection,
  secondSection,
  firstSectionTitle,
  secondSectionTitle,
  highlightName,
}: PaymentTrackingCardProps) => {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation(highlightName);
  const { id, blur } = useMyUserPreferences();
  const dispatch = useDispatch<AppDispatch>();

  const isBlurred = useMemo(() => {
    const isExpenses = title === 'event_finance.tracking_expenses';
    const isIncome = title === 'event_finance.tracking_income';
    return (
      (isExpenses && blur.financeExpenses) || (isIncome && blur.financeIncome)
    );
  }, [title, blur]);

  const toggleDataVisibility = useCallback(() => {
    const isExpenses = title === 'event_finance.tracking_expenses';
    let updates: Partial<UserPreferences> = { blur: { ...blur } };
    if (isExpenses) {
      updates.blur.financeExpenses = !blur.financeExpenses;
    } else {
      updates.blur.financeIncome = !blur.financeIncome;
    }

    dispatch(updateUserPreferences({ id, updates }));
  }, [blur, dispatch, id, title]);

  return (
    <Card
      ref={ref}
      {...style}
      p={{ base: 3, md: 6 }}
      display="flex"
      flexDirection="column"
      flex={1}
      borderRadius={{ base: 'none', md: '20px' }}
      h="100%">
      <Flex direction="column" gap={6}>
        <HStack justify="space-between">
          <Text fontSize="xl" fontWeight="bold">
            {t(title)}
          </Text>
          <IconButton
            aria-label={
              isBlurred ? 'Hide financial data' : 'Show financial data'
            }
            icon={!isBlurred ? <FiEye /> : <FiEyeOff />}
            variant="ghost"
            color="black"
            onClick={toggleDataVisibility}
          />
        </HStack>
        {/* top stats squares section */}
        <Flex gap={4} wrap="nowrap">
          {stats.map((stat, index) => (
            <MiniDataStat
              key={index}
              {...stat}
              bgGradient={stat.bgGradient}
              isBlurred={isBlurred}
            />
          ))}
        </Flex>
        {/* top list section */}
        <Flex
          direction="column"
          gap={6}
          overflow="scroll"
          flex={1}
          maxH={{ base: 'auto', md: '620px' }}>
          <Flex
            direction="column"
            display={firstSection.length > 0 ? 'flex' : 'none'}>
            <Text fontWeight="bold" fontSize={'sm'} color="brand.50">
              {t(firstSectionTitle)}
            </Text>
            <VStack align="stretch" spacing={2}>
              {firstSection.map((payment, index) => (
                <PaymentInfoListItem
                  key={index}
                  {...payment}
                  isBlurred={isBlurred}
                />
              ))}
            </VStack>
          </Flex>

          {/* bottom list section */}
          <Flex
            direction="column"
            display={secondSection.length > 0 ? 'flex' : 'none'}>
            <Text fontWeight="bold" fontSize={'sm'} color="brand.50">
              {t(secondSectionTitle)}
            </Text>
            <VStack align="stretch" spacing={2}>
              {secondSection.map((payment, index) => (
                <PaymentInfoListItem
                  key={index}
                  {...payment}
                  isBlurred={isBlurred}
                />
              ))}
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default PaymentTrackingCard;
