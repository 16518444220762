import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import clockRewindIcon from 'assets/svg/event/finance/clock-rewind-icon.svg';
import creditCardIcon from 'assets/svg/event/finance/credit-card-icon.svg';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuotePaymentFinanceItem } from 'services/@types';
import useHandlePaymentItem from '../../hooks/useHandlePaymentItem';

interface PaymentItemProps extends IQuotePaymentFinanceItem {
  isBlurred?: boolean;
}

const getStatusProps = (status: string) => {
  switch (status) {
    case 'received':
    case 'sent':
      return {
        icon: creditCardIcon,
        badge: { colorScheme: 'green', text: 'Completed' },
      };
    case 'pending':
      return {
        icon: clockRewindIcon,
        badge: { colorScheme: 'gray', text: 'Pending' },
      };
    default:
      return {
        icon: clockRewindIcon,
        badge: { colorScheme: 'green', text: 'Overdue' },
      };
  }
};

const PaymentItem = ({
  title,
  subtitle,
  date,
  amount,
  status,
  isBlurred,
  isPaid,
  quoteId,
  paymentIndex,
}: PaymentItemProps) => {
  const { icon } = getStatusProps(status);
  const { t } = useTranslation();
  const textColor = useMemo(() => {
    if (status === 'sent') {
      return 'brand.900';
    } else if (status === 'received') {
      return 'green.500';
    }
    return 'gray.700';
  }, [status]);
  const bgColor = useMemo(() => {
    if (status === 'sent') {
      return '#aa915d30';
    } else if (status === 'received') {
      return 'green.100';
    }
    return 'gray.100';
  }, [status]);

  const { handlePay, formattedDate } = useHandlePaymentItem({
    quoteId,
    paymentIndex,
    isPaid,
    date,
  });

  return (
    <HStack
      w="100%"
      justify="space-between"
      py={2}
      cursor={isPaid ? 'default' : 'pointer'}
      onClick={handlePay}>
      <HStack spacing={4}>
        <Image src={icon} boxSize={7} />
        <VStack align="start" spacing={0}>
          <HStack>
            <Text fontWeight="medium">{title}</Text>
            <Text fontWeight={'bold'} color="brand.900">
              {subtitle || ''}
            </Text>
          </HStack>
          <Text fontSize="sm" color="gray.500">
            {status === 'pending'
              ? t(`event_finance.due_on`, { date: formattedDate })
              : formattedDate}
          </Text>
        </VStack>
      </HStack>
      <HStack spacing={4}>
        <Text
          fontSize="md"
          fontWeight="bold"
          color={textColor}
          bg={bgColor}
          p={2}
          borderRadius="10px"
          transition="all 0.3s ease"
          filter={isBlurred ? 'blur(8px)' : 'none'}>
          {amount}
        </Text>
      </HStack>
    </HStack>
  );
};

export default PaymentItem;
