import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import ContractList from './components/ContractList';
import NoContracts from './components/NoContracts';
import NoContractsCreated from './components/NoContractsCreated';
import useContracts from './hooks/useContracts';

export default function Contracts() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    contracts,
    search,
    handleDeleteContract,
    handleSearch,
    isMobile,
    headerFields,
    handleSort,
    sortBy,
  } = useContracts();

  return (
    <Flex
      w="100%"
      pt={{ base: '200px', md: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap="8px">
      <Card
        gap={5}
        h="calc(100vh - 170px)"
        borderRadius={{ base: 0, md: '30px' }}>
        <Flex
          justify="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          gap={5}>
          <Text variant="cardTitle">{t('contracts.title')}</Text>
          <Flex gap={2} align="center">
            <InputField
              w={{ base: '100%', md: 'auto' }}
              placeholder={t('clients.search')}
              iconRight={<Icon as={FiSearch} w="20px" h="20px" />}
              onChange={handleSearch}
              value={search}
            />
            <Button
              variant="h1cta"
              w={{ base: '110px', md: 'auto' }}
              leftIcon={<Icon as={FiPlus} />}
              onClick={() => navigate('/main/finance/contracts/create')}>
              {t(
                isMobile
                  ? 'contracts.new_contract_mobile'
                  : 'contracts.new_contract',
              )}
            </Button>
          </Flex>
        </Flex>

        {contracts.length === 0 && search === '' && <NoContractsCreated />}
        {contracts.length === 0 && search !== '' && (
          <NoContracts search={search} />
        )}
        {contracts.length > 0 && (
          <ContractList
            contracts={contracts}
            handleDeleteContract={handleDeleteContract}
            headers={headerFields}
            handleSort={handleSort}
            sortBy={sortBy}
          />
        )}
      </Card>
    </Flex>
  );
}
