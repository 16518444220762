import { Flex, Text } from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { IClient } from 'services/@types';
import ClientInfo from './ClientInfo';
interface SelectedClientDetailsProps {
  client: IClient;
}

export default function SelectedClientDetails({
  client,
}: SelectedClientDetailsProps) {
  return (
    <Flex flexDirection="column" gap="36px">
      <Flex alignItems="center" gap="24px">
        <AuthImage path="" isAvatar name="" w="60px" h="60px" />
        <Flex flexDirection="column">
          <Text variant="clientName">
            {client?.firstName} {client?.lastName}
          </Text>
          <Text variant="clientDetailUnderline">{client?.email}</Text>
        </Flex>
      </Flex>
      <ClientInfo client={client} />
    </Flex>
  );
}
