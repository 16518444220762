import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { BusinessEventPlan } from 'services/@types';

export interface BusinessEventPlanState {
  businessEventPlan: BusinessEventPlan | null;
}

const initialState: BusinessEventPlanState = {
  businessEventPlan: null,
};

const businessEventPlanSlice = createSlice({
  name: 'businessEventPlan',
  initialState,
  reducers: {
    setBusinessEventPlan: (state, action) => {
      state.businessEventPlan = action.payload;
    },
    clearBusinessEventPlan: (state) => {
      state.businessEventPlan = null;
    },
  },
});

export const { setBusinessEventPlan, clearBusinessEventPlan } =
  businessEventPlanSlice.actions;
export const useBusinessEventPlanSelector = () =>
  useSelector((state: RootState) => state.businessEventPlan);
export default businessEventPlanSlice.reducer;
