import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal, FiPlus } from 'react-icons/fi';
import { IContract } from 'services/@types';

const InputFieldNames: {
  label: string;
  name: keyof IContract['recipients'][0];
}[] = [
  { label: 'first_name', name: 'firstName' },
  { label: 'last_name', name: 'lastName' },
  { label: 'id', name: 'idNumber' },
  { label: 'signature', name: 'signature' },
];
interface ICreateContractResipientProps {
  addRecipient: () => void;
  contract: IContract;
  handleChangeRecipient: (payload: {
    name: string;
    value: string;
    index: number;
  }) => void;
  handleRemoveRecipient: (index: number) => void;
}
export default function CreateContractResipient(
  props: ICreateContractResipientProps,
) {
  const {
    addRecipient,
    contract,
    handleChangeRecipient,
    handleRemoveRecipient,
  } = props;
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap="24px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="createQuoteTitle">{t('create_contract.recipient')}</Text>
        {contract?.recipients?.length < 2 && (
          <IconButton
            borderRadius="5px"
            icon={<Icon as={FiPlus} color="white" w="15px" h="15px" />}
            onClick={addRecipient}
            aria-label="add"
            w="20px"
            h="20px"
            minW="20px"
            bg="#93C3B3"
          />
        )}
        {contract?.recipients?.length === 2 && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label=""
              icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
              variant="ghost"
              h="50px"
            />
            <MenuList>
              <MenuItem onClick={() => handleRemoveRecipient(0)}>
                <Text variant="underlineRed">
                  {t('create_contract.remove_first_contact')}
                </Text>
              </MenuItem>
              <MenuItem onClick={() => handleRemoveRecipient(1)}>
                <Text variant="underlineRed">
                  {t('create_contract.remove_second_contact')}
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      <Flex flexWrap="wrap" gap="16px">
        {contract.recipients.map((recipient, index) =>
          InputFieldNames.map((field) => (
            <InputField
              key={index + field.name}
              label={t(`create_contract.${field.label}`)}
              placeholder={t(`create_contract.${field.label}`)}
              bg={field.name === 'signature' ? '#F6F6F6' : 'white'}
              borderColor={field.name === 'signature' ? '#F6F6F6' : '#E0E0E0'}
              w={{ base: '100%', md: '253px' }}
              value={recipient?.[field.name]}
              onChange={(e) => {
                handleChangeRecipient({
                  name: field.name,
                  value: e.target.value,
                  index,
                });
              }}
            />
          )),
        )}
      </Flex>
    </Flex>
  );
}
