import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import { TbMailOpened, TbMapPin } from 'react-icons/tb';

type ChosenClientProps = {
  client: any;
  index?: number;
  handleRemoveClient: (client: any, index?: number) => void;
};

const ChosenClient = ({
  client,
  handleRemoveClient,
  index,
}: ChosenClientProps) => {
  return (
    <HStack
      p={'8px 12px'}
      overflow="hidden"
      borderRadius={'16px'}
      w="283px"
      color={'#939393'}
      alignItems={'center'}
      gap={'10px'}
      spacing={0}
      fontWeight={400}
      fontSize={'12px'}
      border={'1px solid #AA915D'}
      lineHeight="16px">
      <VStack
        spacing={0}
        alignItems={'flex-start'}
        flex={1}
        w="full"
        overflow="hidden">
        <Text
          color="#141718"
          fontSize={'14px'}
          fontWeight={700}
          lineHeight="19px">
          {client.firstName} {client.lastName}
        </Text>
        <HStack spacing={0} gap={3} flex={1} w="full">
          <HStack
            spacing={0}
            gap={1}
            alignItems="center"
            flex={1}
            w="full"
            overflow="hidden"
            isTruncated>
            <Icon as={TbMailOpened} fontSize={'16px'} />
            <Text title={client.email}>{client.email}</Text>
          </HStack>
          <HStack
            w="full"
            overflow="hidden"
            isTruncated
            spacing={0}
            gap={1}
            flex={1}
            alignItems="center">
            <Icon as={TbMapPin} fontSize={'16px'} />
            <Text title={client.address}>{client.address}</Text>
          </HStack>
        </HStack>
      </VStack>
      <IconButton
        flexShrink={0}
        size="xs"
        color={'#939393'}
        onClick={() => handleRemoveClient(client, index)}
        aria-label="Remove client"
        icon={<CloseIcon />}
      />
    </HStack>
  );
};

export default ChosenClient;
