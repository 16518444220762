import { Flex, Stack } from '@chakra-ui/react';
import { SharedCalendars } from './sharedCalendars';
import DeletingCard from './deletingCard';
import TeamContainer from './team';

export default function TheOffice(props: { [x: string]: any }) {
  return (
    <Flex {...props} direction={{ base: 'column', md: 'row' }} w="100%">
      <Flex wrap="wrap" gap={5} w="100%" pb={5}>
        <Stack
          w={{ base: '100%', md: '100%' }}
          direction={{ base: 'column', md: 'row' }}
          alignItems="stretch">
          <TeamContainer />
          <SharedCalendars />
        </Stack>
        <DeletingCard />
      </Flex>
    </Flex>
  );
}
