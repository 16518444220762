import { AxiosInstance, AxiosResponse } from 'axios';
import { IClient, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class ClientService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createClient(Client: Partial<IClient>): Promise<IClient> {
    const response: AxiosResponse<IClient> = await this.api.post(
      `/clients`,
      Client,
    );
    return response.data;
  }

  public async getClients(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IClient>> {
    const response: AxiosResponse<PaginatedResults<IClient>> =
      await this.api.get('/clients', {
        params: queryParams,
      });
    return response.data;
  }

  public async getClient(clientId: string): Promise<IClient> {
    const response: AxiosResponse<IClient> = await this.api.get(
      `/clients/${clientId}`,
    );
    return response.data;
  }

  public async updateClient(
    clientId: string,
    updates: Partial<IClient>,
  ): Promise<IClient> {
    const response: AxiosResponse<IClient> = await this.api.patch(
      `/clients/${clientId}`,
      updates,
    );
    return response.data;
  }

  public async deleteClient(clientId: string): Promise<void> {
    await this.api.delete(`/clients/${clientId}`);
  }
}

const _ClientService = new ClientService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _ClientService;
