import { useDisclosure } from '@chakra-ui/react';
import { setCurrentClient } from 'contexts/redux/client/clientSlice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IClient } from 'services/@types';

export default function useClientView() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch<any>();

  const handleSelectClient = useCallback(
    (client: IClient) => {
      dispatch(setCurrentClient(client));
      onOpen();
    },
    [dispatch, onOpen],
  );
  return { isOpen, onOpen, onClose, handleSelectClient };
}
