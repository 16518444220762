// Chakra imports
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { RootState } from 'contexts/redux/store';
import { useBusinessContact } from 'hooks/useBusinessContact';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Supplier } from 'services/@types';
import FavoriteIcon from '../../../../../assets/img/favorite/favorite.png';
export default function SupplierHeader(props: {
  supplier: Supplier;
  onSendInvite?: () => void;
  [x: string]: any;
}) {
  const { supplier, onSendInvite, ...rest } = props;
  const { name, category, internalNotes, isFavorite, id } = supplier;
  const textColor = '#000000CC';

  const secondaryTextColor = useColorModeValue('primary.100', 'whiteAlpha.600');
  const { loading, loadingId, invites, businessCategories } = useSelector(
    (state: RootState) => ({
      loading: state.invites.loading,
      loadingId: state.invites.loadingId,
      invites: state.invites.invites,
      businessCategories: state.businessCategories.businessCategories,
    }),
  );
  const { business } = useBusinessContact(supplier);
  const { t } = useTranslation();
  const memoIsFavorite = useMemo(() => isFavorite, [isFavorite]);
  const isPending = useMemo(() => {
    return (
      invites?.find(
        (i) =>
          i?.email === supplier?.email &&
          i?.senderID === supplier?.creatorUserID &&
          i?.type === 'supplier',
      )?.status === 'pending'
    );
  }, [invites, supplier?.email, supplier.creatorUserID]);
  return (
    <Flex
      w="100%"
      py={4}
      ps={5}
      pe={'18px'}
      gap={6}
      flex={1}
      cursor="pointer"
      {...rest}
      align="center">
      <Flex pos="relative">
        <Image
          alt=""
          src={FavoriteIcon}
          pos="absolute"
          w="25px"
          h="25px"
          zIndex="20"
          visibility={memoIsFavorite ? 'visible' : 'hidden'}
        />
        <AuthImage
          name={name}
          isAvatar
          path={business?.media?.find((m) => m.type === 'profileImage')?.url}
          h={{ base: '60px', sm: '52px', md: '60px' }}
          w={{ base: '60px', sm: '52px', md: '60px' }}
          borderRadius="full"
          id={id}
        />
      </Flex>

      <Flex direction="column" flex={1} w="100%" overflow="hidden">
        <Box flex={1} wordBreak={'break-word'} fontSize="base" fontWeight="700">
          <Text color={textColor} display="inline">
            {name}{" "}
          </Text>
          <Text
            color="brand.900"
            flex={1}
            display={category?.length > 0 ? 'inline' : 'none'}>
            {supplier.category
              ?.map((c) =>
                t(
                  `categories.${
                    businessCategories?.find((bc) => bc.id === c)?.name
                  }`,
                ),
              )
              .join(', ')}
          </Text>
        </Box>
        <Text
          // display={{ base: 'none', xl: 'unset' }}
          color={secondaryTextColor}
          fontSize={{ base: 'md', sm: 'xs', md: 'sm', '2xl': 'md' }}
          fontWeight="300"
          display="flex"
          noOfLines={2}>
          {internalNotes}
        </Text>
      </Flex>
      <Button
        onClick={() => !isPending && onSendInvite()}
        alignSelf={'center'}
        cursor={isPending ? 'not-allowed' : 'pointer'}
        size="xs"
        isLoading={loading && loadingId === supplier.email}
        variant={isPending ? 'h3outlinedYellow' : 'h3outlinedGreen'}
        height="34px"
        borderRadius="10px"
        display={supplier.businessID ? 'none' : 'flex'}>
        {isPending
          ? t('suppliers.invitation_sent')
          : t('suppliers.invite_to_brilliant')}
      </Button>
    </Flex>
  );
}
