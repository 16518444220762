import {
  deleteClient,
  setEditClient,
  useSelectedClient,
} from 'contexts/redux/client/clientSlice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function useManageSelectedClient() {
  const { client } = useSelectedClient();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const handleRemoveClient = useCallback(() => {
    if (!client) return;
    dispatch(deleteClient(client?.id));
  }, [client, dispatch]);

  const handleEditClient = useCallback(() => {
    if (!client) return;
    dispatch(setEditClient(client));
  }, [client, dispatch]);

  const handleGoToEventsHistory = useCallback(() => {
    navigate(`/main/managment/clients/${client.id}/events-history`);
  }, [client?.id, navigate]);

  const handleDuplicateClient = useCallback(() => {}, []);

  return {
    handleRemoveClient,
    handleEditClient,
    handleGoToEventsHistory,
    handleDuplicateClient,
  };
}
