import { Flex, Image, Text } from '@chakra-ui/react';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import { useTranslation } from 'react-i18next';

export default function NoContracts({ search }: { search: string }) {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h="100%"
      flexDirection="column"
      gap="36px">
      <Image w="300px" h="300px" src={NoEventsImg} alt="" />
      <Flex gap="16px" flexDirection="column" textAlign="center">
        <Text variant="noEventsTitle">
          {t('contracts.no_contracts_found')}
          <Text as="span" color="#000000" fontWeight="700">
            {`"${search}"`}
          </Text>
        </Text>
        <Text variant="noEventsSubTitle">
          {t('contracts.no_contracts_found_description')}
        </Text>
      </Flex>
    </Flex>
  );
}
