import {
  Box,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';

import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useMemo } from 'react';
import { MdAdd } from 'react-icons/md';
import { IEvent } from 'services/@types';
import useParticipants from '../hooks/useParticipants';
import ParticipantItem from './ParticipantItem';
import { useEffect } from 'react';
import { setAddParticipantDialog } from 'contexts/redux/dialog/dialogsSlice';
import { useDispatch } from 'react-redux';
import {
  setBusinessEventPlan,
  useBusinessEventPlanSelector,
} from 'contexts/redux/businessEventPlan/businessEventPlanSlice';

type Props = {
  event: IEvent;
};

const Participants = ({ event }: Props) => {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('participants');
  const dispatch = useDispatch();

  const { businessEventPlan } = useBusinessEventPlanSelector();

  const { participantsApproved, participantsPending } = businessEventPlan || {
    businessId: null,
    participantsApproved: null,
    participantsPending: null,
    id: null,
  };
  const { data, isLoading } = useParticipants({
    eventId: event?.id,
  });

  useEffect(() => {
    dispatch(
      setBusinessEventPlan(
        data
          ? {
              id: data.id,
              eventId: event,
              businessId: data.businessId,
              participantsApproved: data.participantsApproved,
              participantsPending: data.participantsPending,
            }
          : null,
      ),
    );
  }, [data, dispatch, event]);

  return (
    <Card
      ref={ref}
      minH={{ base: 'unset', lg: '535px' }}
      minW={{ base: '100%', lg: '310px' }}
      maxH="535px"
      borderRadius={{ base: 0, md: '30px' }}
      overflowY={'auto'}
      flex={1}
      w="100%"
      gap="24px"
      {...style}>
      {/* Header */}
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">
          {t('event.top_bar_tabs.sub_tabs.participants')}
        </Text>
        <IconButton
          margin={'0 !important'}
          borderRadius="8px"
          maxWidth="24px"
          height="24px"
          minWidth="24px"
          p="0 !important"
          order={{ base: 1, lg: 0 }}
          aria-label="add-event"
          variant={'actionIconBlue'}
          icon={<Icon as={MdAdd} fontSize="16px" />}
          onClick={() => {
            dispatch(
              setAddParticipantDialog({
                eventId: event.id,
              }),
            );
          }}
        />
      </Flex>
      {/* Tabs */}
      {isLoading ? (
        <Box
          position="absolute"
          w="100%"
          h="100%"
          top="50%"
          left="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          transform="translate(-50%, -50%)"
          zIndex="1">
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <Tabs
          variant="unstyled"
          display="flex"
          h="100%"
          flexDir="column"
          gap={6}
          defaultIndex={0}>
          <TabList gap={6}>
            <Tab
              p={0}
              _focus={{ borderColor: 'unset', userSelect: 'none' }}
              _selected={{ color: 'yellow.600' }}>
              Approved
            </Tab>
            <Tab
              p={0}
              _focus={{ borderColor: 'unset', userSelect: 'none' }}
              _selected={{ color: 'yellow.600' }}>
              Pending
            </Tab>
          </TabList>
          <TabPanels p={0} flex={1} gap={6}>
            {/* approved participants */}
            <TabPanel p={0}>
              {participantsApproved &&
                participantsApproved?.map((participant, index) => (
                  <ParticipantItem participant={participant} key={index} />
                ))}
            </TabPanel>
            {/* pending participants */}
            <TabPanel p={0}>
              {participantsPending &&
                participantsPending?.map((participant, index) => (
                  <ParticipantItem participant={participant} key={index} />
                ))}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Card>
  );
};

export default Participants;
