import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { fetchClients } from 'contexts/redux/client/clientSlice';
import {
  getStartingPayloadDate,
  setStartingPayloadDate,
} from 'contexts/redux/createMeeting/createMeetingSlice';
import {
  closeSplashDialog,
  setClientDialog,
  setSelectSupplierDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import {
  createEvent,
  getEvent,
  updateEvent,
} from 'contexts/redux/event/eventSlice';
import store, { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IClient, IEvent, Supplier } from 'services/@types';
const requiredFields = ['name', 'type', 'location'];

interface ICreateEventResult {
  handleChange: (key: string, value: any) => void;
  handleCreateUpdateEvent: () => void;
  event: Partial<IEvent>;
  errorList: string[];
  isEventPlannerFormVisible: boolean;
  setIsEventPlannerFormVisible: (value: boolean) => void;
  handleOpenClientDialog: () => void;
  handleRemoveClient: (client: IClient) => void;
  handleOpenSelectSupplierDialog: () => void;
}

export default function useCreateEvent(): ICreateEventResult {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const startingPayloadDate = useSelector(getStartingPayloadDate);
  const eventData = useSelector((state: RootState) => state.event.event);
  const [event, setEvent] = useState<Partial<IEvent>>({
    dateAndTime: new Date(),
    clients: [],
    eventPlanner: null,
  });
  const [isEventPlannerFormVisible, setIsEventPlannerFormVisible] =
    useState<boolean>(false);
  const [errorList, setErrorList] = useState<string[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    // find event calendar id and add it to the event as default calendar id
    const calendar = store
      .getState()
      .calendars.calendars.find(
        (calendar) => calendar.isSystem && calendar.name === 'events',
      );
    if (calendar) {
      setEvent((prev) => ({ ...prev, calendarId: calendar.id }));
    }
  }, []);
  const handleOpenClientDialog = useCallback(() => {
    dispatch(
      setClientDialog({
        item: {
          chosenClients: event.clients,
          eventId: event?.id,
        },
        onConfirm: (e: IClient[]) => {
          if (errorList.includes('clients')) {
            setErrorList((prev) => prev.filter((field) => field !== 'clients'));
          }
          setEvent((prevstate) => {
            return {
              ...prevstate,
              clients: e,
            };
          });
        },
      }),
    );
  }, [dispatch, errorList, event.clients, event?.id]);
  const handleOpenSelectSupplierDialog = useCallback(() => {
    dispatch(
      setSelectSupplierDialog({
        item: {
          eventId: event?.id,
        },
        onConfirm: (e: Supplier[]) => {
          if (errorList.includes('eventPlanner')) {
            setErrorList((prev) =>
              prev.filter((field) => field !== 'eventPlanner'),
            );
          }
          console.log('onconfim supplier', e);
          setEvent((prevstate) => ({
            ...prevstate,
            eventPlanner: e?.[0],
          }));
        },
      }),
    );
  }, [dispatch, errorList, event?.id]);

  const handleRemoveClient = useCallback((client: IClient) => {
    setEvent((prev) => ({
      ...prev,
      clients: prev.clients.filter((c: any) => c?.id !== client.id),
    }));
  }, []);
  const validateEvent = useCallback(() => {
    const allRequiredFields = [...requiredFields];

    if (isEventPlannerFormVisible) {
      allRequiredFields.push('eventPlanner.id');
    } else {
      // for each client detail, add the required fields
      allRequiredFields.push('clients');
    }

    const result: string[] = allRequiredFields.filter((field) => {
      const key = field as keyof IEvent;
      if (field === 'clients') {
        return event.clients.length === 0;
      } else if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return !event[parent as keyof IEvent]?.[
          child as keyof (typeof event)[keyof IEvent]
        ];
      }
      return typeof event[key] === 'string'
        ? !event[key]
        : !event[key]?.['value'];
    });
    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [event, isEventPlannerFormVisible]);
  const handleCreateUpdateEvent = useCallback(async () => {
    const isError = validateEvent();
    if (isError) {
      console.log('errorList ->', errorList);
      return;
    }
    let response;
    if (params?.id) {
      const updates: any = {};
      // compare the event data with the eventData from the store
      for (const key in event) {
        if (event.hasOwnProperty(key)) {
          const typedKey = key as keyof IEvent;

          if (typedKey === 'eventPlanner') {
            if (event[typedKey]?.id) {
              updates[typedKey] = event[typedKey]?.id;
            }
          } else if (typedKey === 'clients') {
            const newClients = event[typedKey].map((client: any) => client.id);
            if (newClients.length > 0) {
              updates[typedKey] = newClients;
            }
          } else {
            if (event[typedKey] !== eventData[typedKey]) {
              updates[typedKey] = event[typedKey];
            }
          }
        }
      }
      response = await dispatch(
        updateEvent({
          id: params.id,
          updates,
        }),
      );
      queryClient.invalidateQueries({
        queryKey: [`getEvent-${params.id}`],
      });
    } else {
      if (event.calendarId === null) {
        delete event.calendarId;
      }

      if (event.clients) {
        event.clients = event.clients.map((client: any) => client.id);
      } else {
        delete event.clients;
      }

      if (event.eventPlanner?.id) {
        event.eventPlanner = event.eventPlanner.id;
      } else {
        delete event.eventPlanner;
      }

      response = await dispatch(
        createEvent({ ...event, curator: user?.id } as IEvent),
      );
    }
    if (response?.payload) {
      await dispatch(fetchClients({}));
      setEvent({});
      toast({
        variant: 'main',
        title: t(
          params?.id
            ? 'create_event.toast.event_updated'
            : 'create_event.toast.event_added',
        ),
      });
      navigate(`/main/event/${response?.payload?.id}`);
    }
    if (response?.error) {
      dispatch(closeSplashDialog());
    }
  }, [
    validateEvent,
    params.id,
    errorList,
    dispatch,
    event,
    eventData,
    user?.id,
    toast,
    t,
    navigate,
    queryClient,
  ]);
  const handleChange = useCallback(
    (key: string, value: any, errorKey?: string) => {
      if (errorList.includes(key)) {
        setErrorList((prev) => prev.filter((error) => error !== key));
      }
      if (errorKey && errorList.includes(errorKey)) {
        setErrorList((prev) => prev.filter((error) => error !== errorKey));
      }
      setEvent((prev) => ({ ...prev, [key]: value }));
    },
    [errorList],
  );

  useEffect(() => {
    if (params?.id) {
      const fetchEvent = async () => {
        const event = await dispatch(getEvent(params?.id));
        if (event.payload) {
          const eventData = { ...event.payload };
          if (eventData.dateAndTime) {
            eventData.dateAndTime = new Date(event.payload.dateAndTime);
          }
          setEvent(eventData);
          if (eventData.eventPlanner) {
            setIsEventPlannerFormVisible(true);
          }
        }
      };
      fetchEvent();
    } else {
      if (startingPayloadDate) {
        handleChange('dateAndTime', startingPayloadDate);
        dispatch(setStartingPayloadDate(null));
      }
    }
  }, [params?.id, dispatch, handleChange, startingPayloadDate]);

  useEffect(() => {
    if (!isEventPlannerFormVisible) {
      setErrorList((prev) =>
        prev.filter(
          (error) =>
            error !== 'eventPlanner' && !error.startsWith('eventPlanner'),
        ),
      );
    } else {
      setErrorList((prev) => prev.filter((error) => error !== 'clients'));
    }
  }, [setErrorList, isEventPlannerFormVisible]);

  return {
    handleChange,
    handleCreateUpdateEvent,
    event,
    errorList,
    isEventPlannerFormVisible,
    setIsEventPlannerFormVisible,
    handleOpenClientDialog,
    handleRemoveClient,
    handleOpenSelectSupplierDialog,
  };
}
