import { Flex, Icon, Text, useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { BusinessEventPlan, EventStatus, IEvent } from 'services/@types';
import _businessEventPlanService from 'services/businessEventPlan.api';
import EventTabButton from './EventTabButton';
import StatusDropdown from './StatusDropdown';

const eventTabButtons: {
  type: 'main' | 'client_file' | 'finance' | 'supplier_assignment';
  max: number;
  current: number;
  title: string;
}[] = [
  {
    title: 'event_tab_buttons.general',
    max: 10,
    current: 5,
    type: 'main',
  },
  {
    title: 'event_tab_buttons.finance',
    max: 3,
    current: 1,
    type: 'finance',
  },
  {
    title: 'event_tab_buttons.client_file',
    max: 5,
    current: 3,
    type: 'client_file',
  },
  {
    title: 'event_tab_buttons.supplier_assignment',
    max: 2,
    current: 1,
    type: 'supplier_assignment',
  },
];

export default function ListEventCard({
  event,
  isArchive,
}: {
  event: IEvent;
  isArchive?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const eventDate = useMemo(() => {
    const date = moment(event.dateAndTime)
      .locale(i18n.language)
      .format('MMMM DD, YYYY hh:mm A');
    return date;
  }, [event.dateAndTime, i18n.language]);

  const queryClient = useQueryClient();

  const myBusiness = useMyBusiness();
  const businessId = myBusiness?.id;
  const toast = useToast();

  const businessEventPlanQueryKey = `getEventParticipants-${event.id}-${businessId}`;
  const { data: businessEventPlan } = useQuery<BusinessEventPlan | null>({
    queryKey: [businessEventPlanQueryKey],
    queryFn: async () => {
      const result = await _businessEventPlanService.getBusinessEventPlans({
        eventId: event.id,
        populate: 'businessId',
      });

      const data = result.results[0];
      return data;
    },
    enabled: !!event.id && !!businessId,
  });

  const handleStatusChange = useCallback(
    async (newStatus: EventStatus) => {
      console.log('newStatus', newStatus);
      const res = await _businessEventPlanService.updateBusinessEventPlan(
        businessEventPlan?.id,
        {
          status: newStatus,
        },
      );
      if (res) {
        queryClient.invalidateQueries({
          queryKey: [businessEventPlanQueryKey],
        });
        toast({
          title:
            newStatus === 'completed'
              ? t('event.status_change.toast_success_completed')
              : t('event.status_change.toast_success'),
          variant: 'green',
          status: 'success',
        });
      }
    },
    [businessEventPlan?.id, businessEventPlanQueryKey, queryClient, t, toast],
  );

  const handleCardClick = useCallback(
    (e: React.MouseEvent) => {
      // Prevent navigation when clicking on the status dropdown
      if ((e.target as HTMLElement).closest('.chakra-menu__menu-button')) {
        e.stopPropagation();
        return;
      }
      navigate(`/main/event/${event?.id}`);
    },
    [navigate, event?.id],
  );

  return (
    <Flex
      borderRadius="24px"
      w="100%"
      flex="1"
      p="16px"
      border="1px solid #EBEDF0"
      gap="16px"
      cursor="pointer"
      onClick={handleCardClick}
      bg="#fafbfc"
      transition="all 0.2s linear"
      _hover={{
        bg: '#ffffff',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04)',
      }}>
      <Flex
        flexDirection="column"
        w={{ base: '275px', lg: '325px', '3xl': '425px' }}>
        <Flex alignItems="center" gap="8px">
          <StatusDropdown
            event={event}
            filteredStatuses={
              isArchive
                ? (['cancelled', 'completed'] as EventStatus[])
                : (['new', 'active', 'quotation', 'post'] as EventStatus[])
            }
            status={businessEventPlan?.status as EventStatus}
            onStatusChange={handleStatusChange}
          />
          <Text variant="eventGridCardDate">{eventDate}</Text>
        </Flex>
        <Flex flexDirection="column" w="100%" gap="8px">
          <Flex flexDirection="column" flex="1">
            <Text variant="eventGridCardTitle">{event.name}</Text>
            <Text variant="eventGridCardSubTitle">
              {t(`event_types.${event.type}`)}
            </Text>
          </Flex>
          <Flex alignItems="center" gap="4px" flex="1">
            {event?.numberOfGuests && (
              <Flex alignItems="center" gap="2px">
                <Icon as={HiOutlineMailOpen} color="#00000099" />
                <Text variant="eventGridCardDesc">
                  {t('create_event.modals.found_event.invited', {
                    numberOfGuests: event?.numberOfGuests,
                  })}
                </Text>
              </Flex>
            )}
            <Flex gap="2px">
              <Icon as={IoLocationOutline} color="#00000099" />
              <Text variant="eventGridCardDesc">{event?.location?.label}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap="8px" flex="1">
        {eventTabButtons.map((button, index) => (
          <EventTabButton
            key={index}
            title={button.title}
            max={button.max}
            current={button.current}
            type={button.type}
            flex="1"
            minW="auto"
            w="100%"
            h="92px"
          />
        ))}
      </Flex>
    </Flex>
  );
}
