import { Flex, Icon, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit3 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import useGetLatestQuote from '../hooks/useGetLatestQuote';
import { QuoteItem } from './QuoteItem';
import { Summary } from './Summary';
import NoEventsImg from 'assets/svg/event/not-found.svg';

type ClientQuoteProps = {
  eventId: string;
};

export const ClientQuote = ({ eventId }: ClientQuoteProps) => {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('quote');
  const navigate = useNavigate();

  const { isLoading, quote, products, summary } = useGetLatestQuote(eventId);

  const handleEditQuote = useCallback(
    () => navigate(`/main/finance/quotations/${quote?.id}`),
    [quote?.id, navigate],
  );
  return (
    <Card
      ref={ref}
      {...style}
      w="100%"
      flex={1}
      overflow="auto"
      minW={{ base: '100%', lg: '310px' }}
      maxH="620px"
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px">
      <Flex alignItems="center" justify="space-between" h='40px'>
        <Text variant="eventPageCardTitle">{t('event.quote')}</Text>
        <Icon
          as={FiEdit3}
          color="#999EA9"
          w="24px"
          h="24px"
          cursor="pointer"
          onClick={handleEditQuote}
        />
      </Flex>
      {isLoading ? (
        <Flex
          w="100%"
          h="100%"
          flex={1}
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Flex>
      ) : products.length > 0 ? (
        <>
          <VStack
            w="full"
            spacing={0}
            letterSpacing="tight"
            lineHeight="4"
            gap={{ base: 5, lg: '20px' }}
            color="blackAlpha.600">
            {products.map((product, index) => (
              <QuoteItem
                currency={quote.currency}
                key={product.productId}
                product={product}
                number={index + 1}
              />
            ))}
          </VStack>
          <Summary sum={summary.sum} vat={summary.vat} total={summary.total} />
        </>
      ) : (
        <VStack
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          gap="24px"
          fontWeight={500}
          textAlign="center">
          <Image src={NoEventsImg} w="180px" h="180px" alt="Quote Not Found" />
          <Text color="#00000033" fontSize="24px" lineHeight="26px">
            {t('event.no_data_found')}
          </Text>
          <Text color="#00000033" fontSize="14px" lineHeight="18px">
            {t('event.no_data_message')}
          </Text>
        </VStack>
      )}
    </Card>
  );
};
