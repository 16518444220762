export const currency: { symbol: string; label: string; value: string }[] = [
  {
    symbol: '₪',
    label: 'currency.ILS',
    value: 'ILS',
  },
  {
    symbol: '$',
    label: 'currency.USD',
    value: 'USD',
  },
];

export const VAT_PERCENTAGE = 0.17;

export const EXCHANGE_RATE_FROM_ILS_TO_USD = 0.27;
