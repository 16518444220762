import { SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Input,
  useColorModeValue,
  useOutsideClick,
  useToast,
  VStack,
  useDisclosure,
  Text,
  Flex,
  CircularProgress,
} from '@chakra-ui/react';

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCalendarSearch from '../hooks/useCalendarSearch';
import CalendarSearchItem from './CalendarSearchItem';

const CalendarSearch = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const searchIconColor = useColorModeValue('brand.400', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const ref = useRef<HTMLDivElement>(null);

  const [mobileShown, setMobileShown] = useState(false);
  const { results, isLoading, error, search, handleSearch } =
    useCalendarSearch();

  const { onOpen, onClose, isOpen } = useDisclosure();

  useOutsideClick({
    ref,
    handler: () => {
      onClose();
      if (mobileShown) {
        setMobileShown(false);
      }
      handleSearch('');
    },
  });

  useEffect(() => {
    if (error) {
      toast({
        title: t('calendar.search_error'),
        status: 'error',
        variant: 'error',
      });
    }
  }, [error, toast, t]);

  return (
    <HStack
      ref={ref}
      onFocus={onOpen}
      spacing={0}
      w={{ base: 'unset', xl: '277px' }}>
      {/* Trigger button for mobile */}
      <Button
        margin="0 !imortant"
        cursor={'pointer !important'}
        display={{ base: 'flex', xl: 'none' }}
        variant="actionIconBlue"
        width={isOpen ? { base: '35px', xl: '50px' } : '35px'}
        minWidth={isOpen ? { base: '35px', xl: '50px' } : '35px'}
        height={isOpen ? { base: '35px', xl: '50px' } : '35px'}
        borderRadius={'50%'}
        bgColor="#f5f5f5"
        onClick={() => setMobileShown(true)}>
        <SearchIcon color={searchIconColor} w="12px" h="12px" />
      </Button>
      {/* Wrapper */}
      <VStack
        gap={1}
        h={{ xl: '44px', base: isOpen ? '50px' : '44px' }}
        overflow={'visible'}
        position={{ base: 'absolute', xl: 'relative' }}
        left={{
          base: isOpen ? '16px !important' : '120% !important',
          xl: '0 !important',
        }}
        transition="left 0.3s ease-in-out"
        maxWidth={{
          base: 'calc(100% - 32px)',
          xl: '277px',
        }}
        minWidth={{ base: '120px', '2xl': '277px' }}
        w="100%"
        zIndex={999}>
        {/* Search input */}
        <HStack
          flex={1}
          bgColor="#f5f5f5"
          padding={'12px'}
          borderRadius={'49px'}
          height={{ xl: '44px', base: isOpen ? '50px' : '44px' }}
          maxWidth={{
            base: '100%',
            xl: '277px',
          }}
          w="100%"
          spacing={0}
          gap={3}>
          {/* Submit button */}
          <SearchIcon
            color={searchIconColor}
            w="12px"
            h="12px"
            cursor={'pointer'}
          />
          {/* Input */}
          <Input
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            variant="search"
            fontSize="14px"
            color={inputText}
            bg="transparent"
            ps={0}
            p="0"
            height="max-content"
            borderRadius="0"
            fontWeight="400"
            _placeholder={{ color: 'gray.400', fontSize: 'sm' }}
            placeholder={t('calendar.keyword')}
          />
        </HStack>
        <Flex
          overflow="hidden"
          borderRadius="16px"
          minHeight={isOpen && search.length > 0 ? 340 : 0}
          transition="min-height 0.3s ease-in-out"
          boxShadow="0px 0px 16px 0px #00000029"
          margin="0 !important"
          w="100%"
          bg="none">
          <VStack
            w="100%"
            p="16px 8px"
            bg={'white'}
            margin="0 !important"
            minHeight={340}
            maxHeight={340}
            overflowY={'auto'}>
            {isLoading ? (
              <CircularProgress isIndeterminate color="brand.900" size="14px" />
            ) : search.length > 0 && results?.length > 0 ? (
              results.map((result, index) => (
                <CalendarSearchItem key={result.id} calendarEvent={result} />
              ))
            ) : (
              <Text>No results found</Text>
            )}
          </VStack>
        </Flex>
      </VStack>
    </HStack>
  );
};

export default CalendarSearch;
