import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

export type HighlightableComponent =
  | 'tasks'
  | 'tracker'
  | 'messages'
  | 'meetings'
  | 'participants'
  | 'contracts'
  | 'details'
  | 'event_brief'
  | 'quote'
  | 'client_documents'
  | 'line_up'
  | 'income'
  | 'outcome'
  | 'profit'
  | null
  | string;

interface UiHelperState {
  highlightedComponent: HighlightableComponent;
}

const initialState: UiHelperState = {
  highlightedComponent: null,
};

export const highlightComponent = createAsyncThunk(
  'uiHelper/highlightComponent',
  async (componentName: HighlightableComponent, { dispatch }) => {
    dispatch(setHighlightedComponent(componentName));

    // Clear the highlight after 5 seconds
    setTimeout(() => {
      dispatch(setHighlightedComponent(null));
    }, 2000);

    return componentName;
  },
);

const uiHelperSlice = createSlice({
  name: 'uiHelper',
  initialState,
  reducers: {
    setHighlightedComponent: (
      state,
      action: PayloadAction<HighlightableComponent>,
    ) => {
      state.highlightedComponent = action.payload;
    },
  },
});

export const { setHighlightedComponent } = uiHelperSlice.actions;
export default uiHelperSlice.reducer;
