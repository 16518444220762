import { Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import ProductService from 'services/product.api';
export default function PackageProductInfo({
  productId,
}: {
  productId: string;
}) {
  const { data: productData } = useQuery({
    queryKey: ['product', productId],
    queryFn: () => ProductService.getProduct(productId),
    enabled: !!productId,
  });

  if (!productData) {
    return null;
  }

  return (
    <Flex alignItems="center" gap="12px">
      <Text variant="previewQuoteProductItemName">{productData?.name}</Text>
      <Text variant="previewQuotePaymentItem">{productData?.description}</Text>
    </Flex>
  );
}
