import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreateBriefNoteDto,
  GetBriefNotesParams,
  UpdateBriefNoteDto,
} from 'services/@types';
import _briefNoteService from 'services/briefNote.api';

// Query keys
export const briefNoteKeys = {
  all: ['briefNotes'] as const,
  lists: () => [...briefNoteKeys.all, 'list'] as const,
  list: (filters: GetBriefNotesParams) =>
    [...briefNoteKeys.lists(), filters] as const,
  details: () => [...briefNoteKeys.all, 'detail'] as const,
  detail: (id: string) => [...briefNoteKeys.details(), id] as const,
};

// Get Brief Notes
export const useGetBriefNotes = (params?: GetBriefNotesParams) => {
  return useQuery({
    queryKey: briefNoteKeys.list(params || {}),
    queryFn: () => _briefNoteService.getBriefNotes(params),
  });
};

// Get Single Brief Note
export const useGetBriefNote = (briefNoteId: string) => {
  return useQuery({
    queryKey: briefNoteKeys.detail(briefNoteId),
    queryFn: () => _briefNoteService.getBriefNote(briefNoteId),
    enabled: !!briefNoteId,
  });
};

// Create Brief Note
export const useCreateBriefNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newBriefNote: CreateBriefNoteDto) =>
      _briefNoteService.createBriefNote(newBriefNote),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: briefNoteKeys.lists() });
    },
  });
};

interface UpdateBriefNoteParams {
  briefNoteId: string;
  updates: UpdateBriefNoteDto;
}

// Update Brief Note
export const useUpdateBriefNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ briefNoteId, updates }: UpdateBriefNoteParams) =>
      _briefNoteService.updateBriefNote(briefNoteId, updates),
    onSuccess: (_, { briefNoteId }) => {
      queryClient.invalidateQueries({
        queryKey: briefNoteKeys.detail(briefNoteId),
      });
      queryClient.invalidateQueries({ queryKey: briefNoteKeys.lists() });
    },
  });
};

// Delete Brief Note
export const useDeleteBriefNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (briefNoteId: string) =>
      _briefNoteService.deleteBriefNote(briefNoteId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: briefNoteKeys.lists() });
    },
  });
};
