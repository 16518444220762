import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { IEvent } from 'services/@types';
import { RootState } from '../store';

interface CreateMeetingState {
  selectedDate: { start: Date; end: Date };
  startingPayloadDate: Date | null;
  startingPayloadEvent: IEvent | null;
  navigateTo: string | null;
}

const initialState: CreateMeetingState = {
  selectedDate: {
    start: new Date(),
    end: moment(new Date()).add(1, 'hour').toDate(),
  },
  startingPayloadDate: null,
  startingPayloadEvent: null,
  navigateTo: null,
};

const createMeetingSlice = createSlice({
  name: 'createMeeting',
  initialState,
  reducers: {
    setSelectedDate: (
      state,
      action: PayloadAction<{ start: Date; end: Date }>,
    ) => {
      state.selectedDate = action.payload;
    },
    setStartingPayloadDate: (state, action: PayloadAction<Date | null>) => {
      state.startingPayloadDate = action.payload;
    },
    setStartingPayloadEvent: (state, action: PayloadAction<IEvent | null>) => {
      state.startingPayloadEvent = action.payload;
    },
    setNavigateTo: (state, action: PayloadAction<string | null>) => {
      state.navigateTo = action.payload;
    },
  },
});

export const {
  setSelectedDate,
  setStartingPayloadDate,
  setStartingPayloadEvent,
  setNavigateTo,
} = createMeetingSlice.actions;
export const selectSelectedDate = (state: RootState) =>
  state.createMeeting.selectedDate;
export const getStartingPayloadDate = (state: RootState) =>
  state.createMeeting.startingPayloadDate;
export const getStartingPayloadEvent = (state: RootState) =>
  state.createMeeting.startingPayloadEvent;
export const getNavigateTo = (state: RootState) =>
  state.createMeeting.navigateTo;

export default createMeetingSlice.reducer;
