import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';

interface DataStatSquareProps {
  label: string;
  amount: number;
  icon: any;
  bg?: string;
  borderColor?: string;
  textColor?: string;
  isBlurred?: boolean;
}

const formatCurrency = (amount: number): string => {
  return `$${amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const DataStatSquare = ({
  label,
  amount,
  icon,
  bg,
  borderColor,
  textColor,
  isBlurred,
}: DataStatSquareProps) => {
  const formattedAmount = useMemo(() => formatCurrency(amount), [amount]);

  return (
    <HStack
      spacing={4}
      pe={8}
      ps={2}
      py={2}
      flex={1}
      bg={bg || 'white'}
      borderRadius="xl"
      border="1px solid"
      justify={'stretch'}
      maxW={{ base: '100%', md: '240px' }}
      borderColor={borderColor || 'gray.100'}
      boxShadow="sm"
      align="center">
      <Image src={icon} boxSize={{ base: 6, md: 9 }} color="brand.500" />
      <VStack spacing={0} align="start">
        <Text fontSize="md" color={textColor || 'black'} fontWeight="medium">
          {label}
        </Text>
        <Text
          fontSize={{ base: 'xl', md: '2xl' }}
          fontWeight="bold"
          color={textColor}
          transition="all 0.3s ease"
          filter={isBlurred ? 'blur(8px)' : 'none'}>
          {formattedAmount}
        </Text>
      </VStack>
    </HStack>
  );
};

export default DataStatSquare;
