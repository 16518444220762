import { AxiosInstance, AxiosResponse } from 'axios';
import { IEvent, IEventFinance, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class EventService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createEvent(eventRequest: IEvent): Promise<IEvent> {
    const response: AxiosResponse<IEvent> = await this.api.post(
      '/events',
      eventRequest,
    );
    return response.data;
  }

  public async getEvents(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IEvent>> {
    if (queryParams?.status) {
      queryParams.status = queryParams.status.join(',');
    }
    console.log(`getting events -> ${JSON.stringify(queryParams)}`);
    const response: AxiosResponse<PaginatedResults<IEvent>> =
      await this.api.get('/events', {
        params: queryParams,
      });
    return response.data;
  }

  public async getEvent(eventId: string): Promise<IEvent> {
    const response: AxiosResponse<IEvent> = await this.api.get(
      `/events/${eventId}`,
    );
    return response.data;
  }

  public async updateEvent(
    eventId: string,
    updates: Partial<IEvent>,
  ): Promise<IEvent> {
    const response: AxiosResponse<IEvent> = await this.api.patch(
      `/events/${eventId}`,
      updates,
    );
    return response.data;
  }

  public async deleteEvent(eventId: string): Promise<void> {
    await this.api.delete(`/events/${eventId}`);
  }

  public async getSimilarEvents(
    params: Record<string, any>,
  ): Promise<IEvent[]> {
    const response: AxiosResponse<IEvent[]> = await this.api.get(
      '/events/similar',
      {
        params,
      },
    );
    return response.data;
  }

  public async getEventFinance(eventId: string): Promise<IEventFinance> {
    const response: AxiosResponse<IEventFinance> = await this.api.get(
      `/events/${eventId}/finance`,
    );
    return response.data;
  }
}

const _eventService = new EventService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _eventService;
