import { Flex } from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { IContract } from 'services/@types';
import { eventTypes } from 'variables/eventTypes';
const InputFieldNames: { label: string; name: string }[] = [
  { label: 'client_address', name: 'address' },
  { label: 'phone', name: 'phone' },
  { label: 'email', name: 'email' },
];

interface ICreateContractClientInfoProps {
  clients: {
    value: string;
    label: string;
  }[];
  handleSelectedClient: (e: any) => void;
  contract: IContract;
  handleChange: (name: string, value: any) => void;
  selectedEventType: string[];
  showedEventType: string;
  handleChangeEventType: (e: string) => void;
}
export default function CreateContractClientInfo(
  props: ICreateContractClientInfoProps,
) {
  const {
    clients,
    handleSelectedClient,
    contract,
    handleChange,
    selectedEventType,
    showedEventType,
    handleChangeEventType,
  } = props;
  const { t } = useTranslation();

  return (
    <Flex wrap="wrap" gap="44.5px">
      <DropdownField
        closeOnSelect
        showedOptions={showedEventType}
        minW={{ base: '100%', md: '320px' }}
        label={t('create_contract.event_type')}
        options={eventTypes.map((type) => `event_types.${type}`)}
        placeholder={t('create_contract.event_type')}
        onSelected={handleChangeEventType}
        selectedOptions={selectedEventType}
        formLabelStyles={{
          mb: '4px',
        }}
        menuButton={{
          w: {
            base: '100%',
            md: '320px',
          },
          bg: '#FFFFFF',
        }}
      />
      <InputAutocompleteField
        label={t('create_contract.client_name') + '*'}
        options={clients}
        placeholder={t('create_contract.client_name')}
        w={{ base: '100%', md: '320px' }}
        onSelected={handleSelectedClient}
        value=""
      />
      {InputFieldNames.map((input) => (
        <InputField
          key={input.name}
          label={t(`create_contract.${input.label}`)}
          placeholder={t(`create_contract.${input.label}`)}
          w={{ base: '100%', md: '320px' }}
          value={
            contract?.clientDetails?.[
              input.name as keyof IContract['clientDetails']
            ] as string
          }
          onChange={(e) => {
            handleChange(input.name, e.target.value);
          }}
        />
      ))}
    </Flex>
  );
}
