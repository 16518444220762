import {
  Badge,
  Flex,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import CalendarsDropDown from 'components/calendarsDropDown';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { eventTypes } from 'variables/eventTypes';
interface CreateEventHeaderProps {
  handleChange: (key: string, value: any) => void;
  event: Record<string, any>;
  errorList: string[];
}
export default function CreateEventHeader(props: CreateEventHeaderProps) {
  const { handleChange, event, errorList } = props;
  console.log(event);
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const currentEventType = useMemo(() => event?.type || '', [event?.type]);
  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );
  const clients = useMemo(
    () => event?.clients || [],
    [event?.clients],
  );
  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');
      const res = clients;
      handleChange(
        'clients',
        res.map((contact: any) => ({})),
      );
      if (selectedEventType.includes(selected)) {
        handleChange('type', '');
        return;
      }
      handleChange('type', formattedSelected);
    },
    [selectedEventType, handleChange, clients],
  );
  return (
    <Stack>
      <HStack justify="space-between">
        <HStack
          flexDirection={{ base: 'column', md: 'row' }}
          align={{ base: 'start', md: 'center' }}>
          <Text variant="cardTitle">
            {params?.id
              ? t('create_event.edit_event')
              : t('create_event.title')}
          </Text>
          <Badge bg={`eventStatus.${event?.status || 'new'}`} variant="tag">
            {t(`event.status.${event?.status || 'new'}`)}
          </Badge>
        </HStack>
        <VStack>
          <HStack>
            <IconButton
              aria-label="back"
              variant="ghost"
              opacity={0.6}
              icon={<FiX fontSize={'24px'} />}
              onClick={() => navigate(-1)}
              cursor="pointer"
            />
          </HStack>
        </VStack>
      </HStack>
      <Flex align="end" wrap="wrap" gap="40px">
        <DropdownField
          options={eventTypes.map((type) => `event_types.${type}`)}
          onSelected={handleChangeEventType}
          selectedOptions={selectedEventType}
          placeholder={t('create_event.placeholder.select_event_type')}
          w={{ base: '100%', lg: '318px' }}
          menuButton={{
            borderColor: errorList.includes('type') ? 'error.100' : 'gray.200',
          }}
          showedOptions={t(selectedEventType[0])}
          closeOnSelect
        />
        <InputField
          label={t('create_event.label.event_title')}
          placeholder={t('create_event.placeholder.override_title_if_needed')}
          w={{ base: '100%', lg: '318px' }}
          name="name"
          onChange={(e: any) => handleChange(e.target.name, e.target.value)}
          borderColor={errorList.includes('name') ? 'error.100' : 'gray.200'}
          value={event?.name || ''}
        />
        <CalendarsDropDown
          w={{ base: '100%', lg: '318px' }}
          value={event?.calendarId}
          isEvent
          onChange={(value: string) => {
            handleChange(
              'calendarId',
              value === event?.calendarId ? '' : value,
            );
          }}
        />
      </Flex>
    </Stack>
  );
}
