import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  setClientDialog,
  setEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { fetchProducts } from 'contexts/redux/product/productSlice';
import {
  createQuote,
  fetchQuote,
  updateQuote,
} from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { formatNumber } from 'helpers/formatNumber';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IClient, IEvent, IQoute, Upload } from 'services/@types';
import EventService from 'services/event.api';
import UploadService from 'services/upload.api';
import { currency, VAT_PERCENTAGE } from 'variables/currency';

const defaultQuote: IQoute = {
  eventDate: new Date(),
  eventType: 'other', // default event type
  currency: 'ILS',
  products: [
    {
      description: '',
      amount: 1,
      quantity: 1,
      net: 0,
      vat: 0,
      gross: 0,
      isVatIncluded: false,
    },
  ],
  clients: [],
  generalPaymentDetails: {
    isVatIncluded: true,
    discountType: 'fixed',
  },
  paymentInInstalments: {
    quantity: 1,
    payment: [
      {
        amount: 0,
        date: new Date(),
        isPaid: false,
      },
    ],
  },
};

export default function useCreateQuote() {
  const { loading } = useSelector((state: RootState) => state.quote);
  const { products } = useSelector((state: RootState) => state.product);
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const params = useParams();
  const toast = useToast();
  const myUser = useMyUser();
  const navigate = useNavigate();
  const [event, setEvent] = useState<IEvent | null>(null);
  const [error, setError] = useState<{
    clients: boolean;
    products: boolean;
    totalPayment: boolean;
  }>({
    clients: false,
    products: false,
    totalPayment: false,
  });
  const [newQuote, setNewQuote] = useState<IQoute>(defaultQuote);
  const queryClient = useQueryClient();

  const handleOpenClientDialog = useCallback(() => {
    dispatch(
      setClientDialog({
        item: {
          chosenClients: newQuote?.clients || [],
        },
        onConfirm: (e: IClient[]) => {
          if (error?.clients) {
            setError((prev) => ({ ...prev, clients: false }));
          }
          setNewQuote((prevstate) => {
            return {
              ...prevstate,
              clients: e,
            };
          });
        },
      }),
    );
  }, [dispatch, error, newQuote?.clients]);

  const handleRemoveClient = useCallback(
    (client: IClient) => {
      setNewQuote((prev) => ({
        ...prev,
        clients: prev.clients.filter((c: any) => c?.id !== client.id),
      }));
    },
    [setNewQuote],
  );

  // new fileds
  const [isAllProductsVatIncluded, setIsAllProductsVatIncluded] =
    useState<boolean>(false);

  const currentEventType = useMemo(
    () => newQuote?.eventType || '',
    [newQuote?.eventType],
  );

  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );

  const displayedProducts = useMemo(() => {
    return products.map((product) => ({
      value: product.id,
      label: product.name,
    }));
  }, [products]);

  const currencyIcon = useMemo(() => {
    const selected = currency.find((c) => c.value === newQuote?.currency);
    return selected ? selected.symbol : '';
  }, [newQuote?.currency]);

  const handleChange = useCallback((key: string, value: any) => {
    setNewQuote((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleAddProduct = () => {
    setNewQuote((prev) => ({
      ...prev,
      products: [
        ...(prev?.products || []),
        {
          description: '',
          amount: 1,
          quantity: 1,
          net: 0,
          vat: 0,
          gross: 0,
          isVatIncluded: true,
        },
      ],
    }));
  };

  const handleValidateQuote = useCallback(() => {
    const error = {
      clients: newQuote?.clients.length === 0,
      products: newQuote?.products.some((p) => p.amount === 0),
      totalPayment: newQuote?.generalPaymentDetails?.totalPayment === 0,
    };

    setError(error);

    return Object.values(error).every((value) => value === false);
  }, [
    newQuote?.clients,
    newQuote?.products,
    newQuote?.generalPaymentDetails?.totalPayment,
  ]);

  const handleCreateQuote = useCallback(async () => {
    if (!handleValidateQuote()) return;

    if (params?.id) {
      const updatedQuote: any = {
        ...newQuote,
        clients: newQuote.clients.map((c) => c.id),
      };
      delete updatedQuote.businessId;
      if (updatedQuote?.requestQuoteToken) {
        delete updatedQuote.requestQuoteToken;
      }
      if (updatedQuote?.id) {
        delete updatedQuote.id;
      }
      const response = await dispatch(
        updateQuote({ id: params.id, updates: updatedQuote }),
      );

      if (response.error) {
        toast({
          title: t('create_quote.error'),
          status: 'error',
          variant: 'error',
        });
        return;
      }

      toast({
        title: t('create_quote.quote_updated'),
        status: 'success',
        variant: 'main',
      });
      queryClient.invalidateQueries({
        queryKey: [`quoteInEventPage-${newQuote.eventId}`],
      });

      if (window?.history?.length > 1) {
        navigate(-1);
      } else {
        navigate('/main/dashboard');
      }
      return;
    }

    const newQuoteWithSenderId: any = { ...newQuote };

    newQuoteWithSenderId.clients = newQuote.clients.map((c) => c.id);

    const response = await dispatch(createQuote(newQuoteWithSenderId));
    if (response.error) {
      toast({
        title: t('create_quote.error'),
        status: 'error',
        variant: 'error',
      });
      return;
    }

    toast({
      title: t('create_quote.quote_created'),
      status: 'success',
      variant: 'main',
    });
    queryClient.invalidateQueries({
      queryKey: [`quoteInEventPage-${newQuote.eventId}`],
    });
    navigate('/main/finance/quotations');
  }, [
    handleValidateQuote,
    params.id,
    newQuote,
    dispatch,
    toast,
    t,
    queryClient,
    navigate,
  ]);

  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');

      if (selectedEventType.includes(selected)) {
        handleChange('eventType', '');
        return;
      }
      handleChange('eventType', formattedSelected);
    },
    [selectedEventType, handleChange],
  );

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: IEvent) => {
          setEvent(e);

          setNewQuote((prev) => ({
            ...prev,
            eventId: e?.id,
            location: e?.location?.label,
            eventType: e?.type,
            eventDate: e?.dateAndTime,
            numberOfGuests: e?.numberOfGuests,
            eventName: e?.name,
          }));
        },
      }),
    );
  }, [dispatch]);

  const clearFile = useCallback(async () => {
    if (params?.id) {
      dispatch(updateQuote({ id: params.id, updates: { media: [] } }));
    } else {
      try {
        const [target, targetId, access, fileId] =
          newQuote?.media[0]?.url.split('/');
        await UploadService.deleteUpload({ target, targetId, access, fileId });
      } catch (error) {
        console.error('use clear file error ->', error);
      }
    }

    setNewQuote((prev) => ({
      ...prev,
      media: [],
    }));
  }, [newQuote?.media, params?.id, dispatch]);

  const handleChangeFile = useCallback(
    async (event: any) => {
      event.stopPropagation();
      const file = event?.target?.files[0];

      if (!file) return;

      try {
        const newImg: Upload = await UploadService.uploadFile(
          'user',
          myUser?.id,
          'private',
          file,
        );

        if (newImg) {
          setNewQuote((prev) => ({
            ...prev,
            media: [
              {
                url: newImg.filePath,
                type: 'document',
                displayName: newImg.fileName,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [myUser?.id],
  );

  const handleRemovePayment = useCallback(() => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        payment: prev.paymentInInstalments.payment.slice(0, -1),
      },
    }));

    // handle total payment in instalments
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        payment: prev.paymentInInstalments.payment.map((p) => ({
          ...p,
          amount: formatNumber(
            prev.generalPaymentDetails.totalPayment /
              prev.paymentInInstalments.payment.length,
          ),
        })),
      },
    }));
  }, []);

  const handleAddPayment = useCallback(() => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        quantity: prev.paymentInInstalments.quantity + 1,
      },
    }));
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        payment: [
          ...(prev?.paymentInInstalments?.payment || []),
          {
            amount: 0,
            date: new Date(),
            isPaid: false,
          },
        ],
      },
    }));

    // handle total payment in instalments
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        payment: prev.paymentInInstalments.payment.map((p) => ({
          ...p,
          amount: formatNumber(
            prev.generalPaymentDetails.totalPayment /
              prev.paymentInInstalments.payment.length,
          ),
        })),
      },
    }));
  }, []);

  const handleIncreasePaymentQuantity = useCallback(() => {
    handleAddPayment();
  }, [handleAddPayment]);

  const handleDecreasePaymentQuantity = useCallback(() => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        quantity: prev.paymentInInstalments.quantity - 1,
      },
    }));

    handleRemovePayment();
  }, [handleRemovePayment]);

  const handleSelectedProduct = useCallback(
    (e: any, index: number) => {
      const selectedProduct = products.find((p) => p.id === e);
      setNewQuote((prev) => ({
        ...prev,
        products: prev.products.map((p, key) =>
          key === index
            ? {
                ...p,
                description: selectedProduct.name,
                productId: e,
                amount: selectedProduct.price,
                quantity: 1,
                net: selectedProduct.price,
                vat: 0,
                gross: selectedProduct.price,
                isVatIncluded: true,
              }
            : p,
        ),
      }));
    },
    [products],
  );

  const handleChangePaymentAmount = useCallback(
    (e: any, index: number) => {
      const total = newQuote.generalPaymentDetails?.totalPayment;
      const rest = total - e.target.value;

      setNewQuote((prev) => ({
        ...prev,
        paymentInInstalments: {
          ...prev.paymentInInstalments,
          payment: prev?.paymentInInstalments?.payment.map((p, key) =>
            key === index
              ? { ...p, amount: e.target.value }
              : {
                  ...p,
                  amount: formatNumber(
                    rest / (prev?.paymentInInstalments?.payment.length - 1),
                  ),
                },
          ),
        },
      }));
    },
    [newQuote.generalPaymentDetails?.totalPayment],
  );

  const handleChangeAllProductsVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log('isAllProductsVatIncluded: ', e.target.checked);
      setIsAllProductsVatIncluded(e.target.checked);
      setNewQuote((prev) => {
        const products = prev?.products?.map((p) => ({
          ...p,
          isVatIncluded: e.target.checked,
          vat: e.target.checked ? formatNumber(p.net * VAT_PERCENTAGE) : 0,
          gross: e.target.checked
            ? formatNumber(p.net - p.net * VAT_PERCENTAGE)
            : p.net,
        }));
        return {
          ...prev,
          products,
        };
      });
    },
    [],
  );

  const handleChangeProductVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setNewQuote((prev) => ({
        ...prev,
        products: prev.products.map((p, key) =>
          key === index
            ? {
                ...p,
                isVatIncluded: e.target.checked,
                vat: e.target.checked
                  ? formatNumber(p.net * VAT_PERCENTAGE)
                  : 0,
                gross: e.target.checked
                  ? formatNumber(p.net - p.net * VAT_PERCENTAGE)
                  : p.net,
              }
            : p,
        ),
      }));
    },
    [],
  );

  // calculate difference and create new payment
  const handleBlurPaymentAmount = useCallback(() => {
    const total = newQuote.generalPaymentDetails?.totalPayment;
    const rest = newQuote?.paymentInInstalments?.payment?.reduce(
      (acc, curr) => acc + curr?.amount,
      0,
    );

    const difference = total - rest;

    if (difference > 0) {
      setNewQuote((prev) => ({
        ...prev,
        paymentInInstalments: {
          ...prev.paymentInInstalments,
          quantity: prev.paymentInInstalments.quantity + 1,
          payment: [
            ...(prev?.paymentInInstalments?.payment || []),
            {
              amount: difference,
              date: null,
              isPaid: false,
            },
          ],
        },
      }));
    }
  }, [
    newQuote?.generalPaymentDetails?.totalPayment,
    newQuote?.paymentInInstalments?.payment,
  ]);

  useEffect(() => {
    dispatch(fetchProducts({}));
  }, [dispatch]);

  // handle total generalPaymentDetails
  useEffect(() => {
    setNewQuote((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        total: prev.products.reduce((acc, curr) => acc + curr.net, 0),
      },
    }));
  }, [newQuote.products, newQuote.currency]);

  // handle total including discount
  useEffect(() => {
    setNewQuote((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        totalIncludingDiscount:
          prev.generalPaymentDetails.discountType === 'fixed'
            ? formatNumber(
                prev.generalPaymentDetails?.total -
                  (prev.generalPaymentDetails?.discount || 0),
              )
            : formatNumber(
                prev.generalPaymentDetails?.total -
                  (prev.generalPaymentDetails?.discount
                    ? prev.generalPaymentDetails?.total *
                      (prev.generalPaymentDetails?.discount / 100)
                    : 0),
              ),
      },
    }));
  }, [
    newQuote?.generalPaymentDetails?.discountType,
    newQuote?.generalPaymentDetails?.discount,
    newQuote?.generalPaymentDetails?.total,
  ]);

  // handle total including vat
  useEffect(() => {
    setNewQuote((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        vat: prev.generalPaymentDetails?.isVatIncluded
          ? formatNumber(
              prev.generalPaymentDetails?.totalIncludingDiscount *
                VAT_PERCENTAGE,
            )
          : 0,
        totalPayment: formatNumber(
          prev.generalPaymentDetails?.totalIncludingDiscount +
            (prev.generalPaymentDetails?.isVatIncluded
              ? formatNumber(
                  prev.generalPaymentDetails?.totalIncludingDiscount *
                    VAT_PERCENTAGE,
                )
              : 0),
        ),
      },
    }));
  }, [
    newQuote.generalPaymentDetails?.isVatIncluded,
    newQuote.generalPaymentDetails?.totalIncludingDiscount,
    newQuote.generalPaymentDetails?.total,
  ]);

  // handle payment in instalments
  useEffect(() => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstalments: {
        ...prev.paymentInInstalments,
        payment: prev?.paymentInInstalments?.payment?.map((p) => ({
          ...p,
          amount: formatNumber(
            prev.generalPaymentDetails?.totalPayment /
              prev.paymentInInstalments?.quantity,
          ),
        })),
      },
    }));
  }, [newQuote?.generalPaymentDetails?.totalPayment]);

  useEffect(() => {
    if (params.id) {
      const getQuote = async () => {
        const response = await dispatch(fetchQuote(params.id));
        setNewQuote({ ...defaultQuote, ...response.payload });

        if (response.payload?.eventId) {
          const event = await EventService.getEvent(response.payload.eventId);
          if (event) {
            setEvent(event);
          }
        }
      };
      getQuote();
    }
  }, [params.id, dispatch]);

  // observe error and clear error
  useEffect(() => {
    if (error.clients && newQuote.clients.length > 0) {
      setError((prev) => ({ ...prev, clients: false }));
    }

    if (error.products && newQuote.products.every((p) => p.amount !== 0)) {
      setError((prev) => ({ ...prev, products: false }));
    }

    if (
      error.totalPayment &&
      newQuote.generalPaymentDetails?.totalPayment !== 0
    ) {
      setError((prev) => ({ ...prev, totalPayment: false }));
    }
  }, [
    newQuote.clients,
    newQuote.products,
    newQuote.generalPaymentDetails?.totalPayment,
    error,
  ]);

  useEffect(() => {
    if (newQuote?.products?.length > 0) {
      setIsAllProductsVatIncluded(
        newQuote?.products?.every((p) => p?.isVatIncluded),
      );
    }
  }, [newQuote?.products]);

  return {
    newQuote,
    handleChange,
    handleAddProduct,
    handleChangeEventType,
    selectedEventType,
    handleOpenEventDialog,
    event,
    handleAddPayment,
    handleCreateQuote,
    handleIncreasePaymentQuantity,
    handleDecreasePaymentQuantity,
    setNewQuote,
    isLoading: loading,
    products: displayedProducts,
    handleSelectedProduct,
    handleChangeFile,
    clearFile,
    currencyIcon,
    error,
    handleChangePaymentAmount,
    handleChangeAllProductsVatIncluded,
    isAllProductsVatIncluded,
    handleChangeProductVatIncluded,
    handleBlurPaymentAmount,
    handleOpenClientDialog,
    handleRemoveClient,
  };
}
