import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import Dropzone from 'components/dropzone';
import TextField from 'components/fields/TextField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  closeAddTrackerNoteDialog,
  setViewPhotoDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import {
  fetchTrackedEvent,
  updateTrackedEvent,
} from 'contexts/redux/trackedEvent/trackedEventSlice';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuX } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { INote, ITrackedEvent, UrlItem } from 'services/@types';
import { isImageName } from 'utils/isImageName';

export default function AddTrackerNoteDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addTrackerNoteDialog,
  );
  const myUser = useMyUser();
  const myBusiness = useMyBusiness();
  const queryClient = useQueryClient();
  const [note, setNote] = useState<INote>({
    text: '',
    creator: myUser?.id,
    media: [],
  });

  const onClose = useCallback(() => {
    dispatch(closeAddTrackerNoteDialog());
    setNote({
      text: '',
      media: [],
      creator: myUser?.id,
    });
  }, [dispatch, myUser?.id]);

  const handleInput = useCallback(
    (e: any) => {
      const text = e.target.value;
      if (text.length <= 100) {
        console.log(`text`, text);
        setNote({
          ...note,
          text: text,
        });
      }
    },
    [note],
  );

  const handleUploadCompleted = useCallback(
    (media: UrlItem) => {
      console.log(`media`, media);
      setNote({
        ...note,
        media: [...note.media, media],
      });
    },
    [note],
  );
  const handleCreateTrackerNote = useCallback(async () => {
    const newNote: INote & { trackedEventId?: string } = {
      ...note,
    };
    delete newNote.trackedEventId;

    const { payload: trackedEvent } = await dispatch(
      fetchTrackedEvent(dialog?.item.trackedEventId),
    );
    if (dialog?.item?._id) {
      newNote._id = dialog?.item?._id;
    }

    const notesWithoutCurrent = trackedEvent?.notes?.filter(
      (note: INote) => note._id !== dialog?.item?._id,
    );
    const res = await dispatch(
      updateTrackedEvent({
        id: dialog?.item.trackedEventId,
        notes: [...notesWithoutCurrent, newNote],
      } as ITrackedEvent),
    );

    if (res.payload && myBusiness?.id) {
      queryClient.invalidateQueries({
        queryKey: ['dashboardTrackedEvents', myBusiness.id],
      });
    }
    onClose();
  }, [note, dispatch, dialog?.item, onClose, myBusiness?.id, queryClient]);

  useEffect(() => {
    if (dialog?.item?._id) {
      setNote({
        ...dialog.item,
      });
    } else {
      setNote({
        text: '',
        media: [],
        creator: myUser?.id,
      });
    }
  }, [dialog?.item, myUser?.id]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent minW="350px" p="24px" gap="12px" borderRadius="18px">
        <HStack justifyContent="space-between">
          <Flex alignItems="center" gap="10px">
            <Text color={'black'} fontWeight="700" fontSize={'2xl'}>
              {t('tracked_event.add_note')}
            </Text>
          </Flex>
          <Icon
            as={LuX}
            color="#00000066"
            w="20px"
            h="20px"
            cursor="pointer"
            onClick={onClose}
          />
        </HStack>

        <Flex position="relative">
          <TextField
            name="description"
            value={note?.text || ''}
            w="100%"
            height="140px"
            maxHeight="140px"
            maxLength={100}
            onChange={handleInput}
            placeholder={t('tracked_event.add_note')}
          />
          <Text
            sx={{
              position: 'absolute',
              bottom: '4px',
              right: '10px',
            }}
            variant="textAreaLengthCounter">
            {(note?.text || '').length}/100
          </Text>
        </Flex>

        <Flex h="70px" gap={2} w="100%">
          {/* <HStack>
            {dialog?.item?.media?.map((media: UrlItem) => (
              <AuthImage
                onClick={() => {
                  dispatch(
                    setViewPhotoDialog({
                      item: { ...media, type: 'image' },
                    }),
                  );
                }}
                key={media.url}
                borderRadius="12px"
                path={media.url}
                width="68px"
                height="68px"
              />
            ))}
          </HStack> */}
          <Dropzone
            labelSize="14px"
            maxW={note?.media?.length > 0 ? '68px' : '100%'}
            maxH="68px"
            display={
              note?.media?.length === 0 ||
              isImageName(note?.media?.[0]?.displayName)
                ? 'flex'
                : 'none'
            }
            goldenUploadLabel={t('tracked_event.add_file')}
            flex={1}
            cursor="pointer"
            disabled={note?.media?.length > 0}
            currentImageProps={{
              onClick: () => {
                dispatch(
                  setViewPhotoDialog({
                    item: { ...note?.media?.[0], type: 'image' },
                  }),
                );
              },
            }}
            currentImage={
              isImageName(note?.media?.[0]?.displayName)
                ? note?.media?.[0]?.url
                : undefined
            }
            onUploadCompleted={handleUploadCompleted}
            dropzoneOptions={{
              accept: [
                'image/*',
                'video/*',
                'audio/*',
                'application/*',
                'application/pdf',
              ],
              multiple: false,
            }}
            uploadBarStyle="line"
            uploadKey={'attachment'}
            access="restricted"
            target={'trackedEvent'}
            targetId={dialog?.item?.trackedEventId}
            // onUploadCompleted={handleUploadCompleted}
          />
          <HStack
            flex={1}
            w="100%"
            gap={2}
            display={note?.media?.length > 0 ? 'flex' : 'none'}>
            <Text
              alignSelf="center"
              fontWeight={'bold'}
              noOfLines={3}
              color="brand.900"
              textDecorationLine={'underline'}>
              {note?.media?.[0]?.displayName}
            </Text>
            <IconButton
              icon={<LuX fontSize={'24px'} />}
              variant="ghost"
              opacity={0.5}
              display={note?.media?.length > 0 ? 'flex' : 'none'}
              alignSelf="center"
              aria-label="remove"
              onClick={() => {
                setNote({ ...note, media: [] });
              }}
            />
          </HStack>
        </Flex>

        <HStack justifyContent="flex-end">
          <Button
            variant="h1cta"
            minW="134px"
            onClick={handleCreateTrackerNote}>
            Save
          </Button>
        </HStack>
      </ModalContent>
    </Modal>
  );
}
