import AddClientModal from './components/AddClientModal';
import ClientsView from './components/ClientsView';
import NoClients from './components/NoClients';
import useClients from './hooks/useClients';

export default function Clients() {
  const {
    clients,
    isAddClientOpen,
    onOpenAddClient,
    onAddClientClosed,
    search,
    handleSearch,
    loading,
  } = useClients();

  return (
    <>
      {(clients.length > 0 || search.length > 0) && (
        <ClientsView
          clients={clients}
          onOpenAddClient={onOpenAddClient}
          search={search}
          handleSearch={handleSearch}
          loading={loading}
        />
      )}
      {clients.length === 0 && search.length === 0 && (
        <NoClients onOpenAddClient={onOpenAddClient} />
      )}
      <AddClientModal isOpen={isAddClientOpen} onClose={onAddClientClosed} />
    </>
  );
}
