import { useQuery } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { BusinessEventPlan } from 'services/@types';
import _businessEventPlanService from 'services/businessEventPlan.api';

type Props = {
  eventId: string;
};

const useParticipants = ({ eventId }: Props) => {
  const myBusiness = useMyBusiness();
  const businessId = myBusiness?.id;
  const { data, isLoading } = useQuery<BusinessEventPlan | null>({
    queryKey: [`getEventParticipants-${eventId}-${businessId}`],
    queryFn: async () => {
      const result = await _businessEventPlanService.getBusinessEventPlans({
        eventId,
        populate: 'businessId',
      });

      const data = result.results[0];
      return data;
    },
    enabled: !!eventId && !!businessId,
  });

  return { data, isLoading };
};

export default useParticipants;
