import { Divider, Flex, VStack } from '@chakra-ui/react';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { createInvite } from 'contexts/redux/invite/inviteSlice';
import { setCurrentSupplier } from 'contexts/redux/supplier/supplierSlice';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Supplier } from 'services/@types';
import SupplierHeader from './SupplierHeader';
interface SupplierItemComponentProps {
  supplier: Supplier;
  currentSupplier?: Supplier;
  onOpen: () => void;
}
export const SupplierItemComponent: React.FC<SupplierItemComponentProps> = ({
  supplier,
  currentSupplier,
  onOpen,
}) => {
  const dispatch = useDispatch<any>();
  const myBusiness = useMyBusiness();

  const memoSupplier = useMemo(() => supplier, [supplier]);

  const handleSendInvite = useCallback(() => {
    if (supplier.email) {
      dispatch(
        createInvite({
          businessID: myBusiness?.id,
          email: supplier.email,
          fullName: supplier.name,
          type: 'supplier',
        }),
      );
    }
  }, [dispatch, myBusiness?.id, supplier.email, supplier.name]);
  return (
    <Flex w="100%" px={5}>
      <VStack
      w="100%"
        key={supplier.id}
        gap={0}
        bg={{base: 'unset', lg: supplier.id === currentSupplier?.id ? '#F5F5F5' : 'transparent'}}>
        <SupplierHeader
          supplier={memoSupplier}
          onSendInvite={handleSendInvite}
          onClick={() => {
            dispatch(setCurrentSupplier(memoSupplier));
            onOpen();
          }}
        />
        <Divider
          sx={{
            marginTop: '0px !important',
            w: '100%',
            borderColor: '#D9D9D9',
          }}
        />
      </VStack>
    </Flex>
  );
};
