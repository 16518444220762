import { Button, Flex, Text, useToast, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { openAlertDialog } from 'contexts/redux/dialog/dialogsSlice';
import { deleteMe } from 'contexts/redux/user/userSlice';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export default function DeletingCard() {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const user = useMyUser();
  const handleDeleteUserClicked = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('the_office.delete_account'),
        content: t('the_office.delete_account_confirmation'),
        onConfirm: () => {
          dispatch(deleteMe({ user }));
          toast({
            title: t('the_office.delete_account_success'),
            variant: 'main',
            status: 'success',
          });
          navigate('/');
        },
      }),
    );
  }, [dispatch, t, user, toast, navigate]);
  return (
    <Card
      flexDirection="column"
      p="0"
      ps={'30px'}
      py={8}
      pe={'20px'}
      width={{ base: '100%', md: '100%' }}
      borderRadius={{ base: 0, md: '30px' }}>
      <Flex justifyContent="space-between" align="center">
        <VStack align={'start'}>
          <Text variant="cardTitle">{t('the_office.delete_this_account')}</Text>
          <Text variant="cardSubTitle">
            {t('the_office.here_you_can_permanently_delete_this_account')}
          </Text>
        </VStack>
        <Button variant="h3outlinedRed" onClick={handleDeleteUserClicked}>
          {t('the_office.delete_account')}
        </Button>
      </Flex>
    </Card>
  );
}
